import { graphConfig } from "../lib/authConfig";

/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */

function reload() {
    window.location.reload()
}
export async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => {
            response.json().then(res => {
                var data = res;
                data.token = accessToken;
                data = JSON.stringify(data);
                localStorage.setItem("user_data", data);
                reload()
            })
        })
        .catch(error => {
            console.log("error", error)
        });
}

export const getProfilePhoto = (token) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
  
    headers.append("Authorization", bearer);
  
    const options = {
      method: "GET",
      headers: headers,
    };
  
    console.log("request made to Graph API at: " + new Date().toString());
    let endpoint = graphConfig.profilePhoto;
    return fetch(endpoint, options);
  };