export const attributeProperty = {
  gender: {
    dimensionsKey: "gender",
    mappingKey: "mappings",
  },
  age_group: {
    dimensionsKey: "upper_limit_mapping",
    mappingKey: "age_group",
  },
  specialty: {},
  payer: {},
  stage: {},
  custom: {},
  lot: {},
  compliance: {},
  persistence: {},
  sob: {},
  adjuvant: {},
  surgery: {},
  surgery_group:{},
  therapy: {},
  provider_name: {},
  site_of_care: {},
  place_of_service: {},
  specialty_pattern: {},
  "earliest/latest": {},
};

export const getAttibuteArray = (attribute) => {
  const { mappingKey, dimensionsKey } = attributeProperty[attribute.type];
  const dimensions = [];
  const mappings = [];
 if (["gender", "age_group"].includes(attribute.type)) {
    attribute.data.forEach((el) => {
      dimensions.push(
        attribute.type === "gender"
          ? el[dimensionsKey]
          : [parseInt(el["lower_limit_mapping"]),parseInt(el[dimensionsKey])]
      );
      mappings.push(el[mappingKey]);
    });
  } else if (attribute.type === "specialty") {
    mappings.push([...attribute.data.Provider_Details]);
    // attribute.data.specArray.map((spec) => {
    //   dimensions.push(spec.name);
    //   mappings.push(spec.codes.map(code => code.taxonomy_code));
    //   mappings.push(spec.codes.map((code) => code.provider));
    //   return true;
    // });
  } else if (attribute.type === "custom") {
    mappings.push([...attribute.data.Details]);
    // attribute.data.map((drug) => {
    //   dimensions.push(drug.name);
    //   mappings.push(drug.codes.map((code) => code.actual_code));
    //   return true;
    // });
  } else if (attribute.type === "payer") {
    attribute.data.payerArray.map((payer) => {
      dimensions.push(payer.name);
      mappings.push(payer.codes.map((code) => code.mappings));
      return true;
    });
  } else if (attribute.type === "stage") {
    attribute.data.stageArray.map((stages) => {
      dimensions.push(stages.name);
      mappings.push(stages.codes.map((code) => code.mappings));
      return true;
    });
  } else if (attribute.type === "provider_name") {
    mappings.push([...attribute.data.Provider_Details]);
  } else if (attribute.type === "lot") {
    // first regimen
    let firstReg = [];
    if (attribute.data.firstRegimen.tritmentLine.length > 0) {
      attribute.data.firstRegimen.tritmentLine.forEach((value, key) => {
        let temp = {};
        temp["Drug_Class_Values"] = value.codes;
        firstReg.push(temp);
      });
    }
    // addition
    let addSub = [];
    if (attribute.data.addition.tritmentLine.length > 0) {
      attribute.data.addition.tritmentLine.forEach((value, key) => {
        let temp = {};
        temp["Drug_Class_Values"] = value.Drug_Class_Values;
        temp["Drug_Class_Therapy"] = value.Drug_Class_Therapy;
        temp["Progress_Line"] = value.Progress_Line;
        temp["Grace_Period"] = value.Grace_Period;
        temp["OP"] = value.OP;
        addSub.push(temp);
      });
    }
    // dropOff
    let dropOff = [];
    if (attribute.data.dropOff.tritmentLine.length > 0) {
      attribute.data.dropOff.tritmentLine.forEach((value, key) => {
        let temp = {};
        temp["Drug_Class_Values"] = value.codes;
        temp["Progress_Line"] = value.Progress_Line;
        // temp['Containment'] = value.Containment;
        // temp['Grace_Period'] = value.Grace_Period;
        temp["Drug_Class_Attribute"] = value.Drug_Class_Attribute;
        temp["Drug_Class_Name"] = value.drug.name;
        dropOff.push(temp);
      });
    }

    const lot_json = {
      "Reg/Line": {
        OW_Period: attribute.data.firstRegimen.OW_Period,
        // "Grace_Period": attribute.data.firstRegimen.Grace_Period,
        Exception_List: firstReg,
      },
      "Add/Sub": {
        Progress_Line: !attribute.data.addition.hasOwnProperty("Progress_Line")
          ? true
          : attribute.data.addition.Progress_Line,
        Exception_List: addSub,
      },
      Subset_Regimen: {
        // "Drop_Off_Period": !attribute.data.dropOff.hasOwnProperty("OW_Period") ? "90" : attribute.data.dropOff.OW_Period,
        Progress_Line: !attribute.data.dropOff.hasOwnProperty("Progress_Line")
          ? false
          : !attribute.data.dropOff.Progress_Line,
        Change_Regimen: !attribute.data.dropOff.hasOwnProperty("Change_Regimen")
          ? true
          : attribute.data.dropOff.Change_Regimen,
        Exception_List: dropOff,
      },
      Grace_Period: {
        Drop_Off_Period:
          attribute.data.gracePeriod.tritmentLine[0].Grace_Period,
      },
      Restart: {
        Restart_Period: !attribute.data.restart.tritmentLine[0].hasOwnProperty(
          "Grace_Period"
        )
          ? "180"
          : attribute.data.restart.tritmentLine[0].Grace_Period,
        Progress_Line: !attribute.data.restart.tritmentLine[0].hasOwnProperty(
          "Progress_Line"
        )
          ? true
          : attribute.data.restart.tritmentLine[0].Progress_Line,
        Regimen_Type:
          attribute.data.restart.tritmentLine[0].Same_Subset_Regimen,
      },
      drug_group: attribute.data.drugSelection.drug_select,
    };
    if (attribute.data.firstRegimen.Reg_creation === "ow") {
      lot_json["Reg/Line"].OW_Period = attribute.data.firstRegimen.OW_Period;
    }
    return { lot_json };
  }
  return { dimensions, mappings };
};
