import React from "react";

import Spin from "antd/es/spin/index";

const Spiner = () => {
  return (
    <div className="spin-center">
      <Spin />
    </div>
  );
};

export default Spiner;
