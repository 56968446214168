import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import axios from "axios";
import { notification, Button } from "antd";
import { Link } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import store from "./store";
import { MsalProvider } from "@azure/msal-react";
import { getUserData } from "./utils/utils"
import { msalInstance } from './lib/authConfig'
import { signIn, getToken } from "./lib/authConfig";
import "./index.css";

/**
 * Interceptor for handle 401 unauthorized error
 * coming from backend
 */

const data = getUserData("user_data");
if (data) {
  axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
}

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    const key = `open${Date.now()}`;
    if (err.response?.status && err.response.status === 400) {
      if (err?.response?.data?.message === "Cluster is starting") {
        let args = {
          message: "Bringing up the Cluster",
          description: "this will take up to 15 mins. Please wait...",
          key,
          placement: "bottomLeft",
          duration: 0,
        };
        notification.info(args);
      }
      if (err?.response?.data?.message?.includes("No such note")) {
        let args = {
          message: "Session got terminated",
          description: "Please refresh the page to bring it up",
          key,
          placement: "bottomLeft",
          duration: 0,
        };
        notification.error(args);
      }
      if (
        err?.response?.data?.message?.includes(
          "Cluster auto scaling has been initiated"
        )
      ) {
        const btn = (
          <Button
            type="primary"
            size="small"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            OK
          </Button>
        );
        let args = {
          message:
            "Resources reached maximum capacity, resources allocation will take up to 5 minutes.",
          btn,
          key,
          placement: "bottomLeft",
          duration: 0,
        };
        notification.error(args);
      }
    }
    if (err.response?.status && err.response.status === 401) {
      // window.setTimeout(function () {
      //   window.location.reload(1); 
      // }, 1000);
      // let args = {
      //   message: "Session Expired",
      //   description: "Session got expired",
      //   key,
      //   placement: "bottomLeft",
      //   duration: 0,
      // };
      // notification.error(args);
      delete axios.defaults.headers.common.Authorization;
      getToken()
      // localStorage.clear()
      // signIn()
    }
    return Promise.reject(err);
  }
);

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <App />
    </Provider>
  </MsalProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
