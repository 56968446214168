import Unauthorized from "component/Unauthorized";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

const ProtectedRoute = (props) => {
  const isAuthenticated = useIsAuthenticated();

  const { component: Component, ...rest } = props;

  return (
      <>
        <Route
          {...rest}
          render={(props) =>
            isAuthenticated ? (
              <>
                <Component {...props} />
              </>
            ) : (
              <div>Redirecting</div>
            )
          }
        />
      </>
  );
};

export default ProtectedRoute;
