import React, { lazy, Suspense, useStat, useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

import ProtectedRoute from "./ProtectedRoute";
import { logout } from "../actions/authAction";
import Spiner from "./Spiner";
import { getLocalStorageData } from '../utils/utils'

const Navbar = lazy(() => import("../component/Navbar"));
const Home = lazy(() => import("../component/home/Home"));
const About = lazy(() => import("../component/About"));
const CreateProject = lazy(() => import("../component/createProject"));
const Login = lazy(() => import("../component/Login"));
const NotFound = lazy(() => import("../component/NotFound"));

const Routers = (props) => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Spiner />}>
        {getLocalStorageData("appHeaderVisibility") ? (
          <Navbar
            logout={props.logout}
            username={props.username}
            name={props.name}
          />
        ) : (
          <></>
        )}
        <Switch>
          {/* <Route exact path="/login" component={Login} /> */}
          <ProtectedRoute
            exact
            path="/"
            component={() => <Home key={Math.floor(Math.random() * 100)} />}
          />
          <ProtectedRoute
            exact
            path="/project/create"
            component={CreateProject}
          />
          <ProtectedRoute
            path="/project/:action/:id"
            component={CreateProject}
          />
          <ProtectedRoute exact path="/about" component={About} />
          <Route exact path="*" component={NotFound} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => ({
  // is_authenticated: state.is_authenticated,
  username: state.mail,
  name: state.displayName,
});

const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(Routers);
