export const ColorsPalette = [
  "#00857C",
  "#6ECEB2",
  "#BFED33",
  "#FFF063",
  "#5450E4",
  "#688CE8",
  "#69B8F7",
  "#BBCCCF",
  "#FBCDBC",
  "#BBDCBC",
  "#CCCDDF",
  "#BDBFDC",
  "#FFDCFD",
  "#CECEFF",
  "#EBEDDE",
  "#EEDBCF",
  "#EFBCDD",
  "#EDEDEC",
  "#DECBBC",
  "#0C2340",
  "#FBDEDF",
  "#BEBFBD",
  "#BBFBCD",
  "#DFECDB",
  "#EDCCDE",
  "#FCEBFC",
  "#CEEEBB",
  "#BEFEEB",
  "#BECECB",
  "#FCBFEF",
  "#DCFEFB",
  "#DBBCCC",
  "#CBDDEF",
  "#CBCEBD",
  "#DEDEEC",
  "#EFDEBB",
  "#BBDEEF",
  "#EFECBB",
  "#EEBBFD",
  "#FFFCCD",
  "#CBBBBC",
  "#EEDBBE",
  "#CCCFDB",
  "#EECDCE",
  "#DDDFDD",
  "#FCCEBC",
  "#FCCDBD",
  "#DEFCCF",
  "#DCDCDF",
  "#EFDEDD",
  "#EDFCBC",
  "#DEDEEE",
  "#DFCDBB",
  "#EBCCBC",
  "#CEDBCF",
  "#BCCDDE",
  "#BFBEFE",
  "#CEEDDC",
  "#EDFFDB",
  "#CCEFFF",
  "#CEDFBE",
  "#BCCEDC",
  "#DEEDCE",
  "#EEBBCD",
  "#FFEBFE",
  "#EFECFB",
  "#FFEBEE",
  "#EBEEFF",
  "#EBFEEE",
  "#BEFDDB",
  "#ECDBDC",
  "#BDBCDD",
  "#EECEED",
  "#BECDDF",
  "#DEFEBE",
  "#CCDEEF",
  "#DCECEC",
  "#FDEBDB",
  "#BCDDCF",
  "#EDBBCE",
  "#ECFDBC",
  "#DFECBF",
  "#DBDBED",
  "#FDEEDD",
  "#FCFFCD",
  "#DDCCEB",
  "#BCDBCF",
  "#FEBFBE",
  "#EFBBFD",
  "#FBFDBE",
  "#CBDDCB",
  "#DBFFCB",
  "#BDBCFD",
  "#DEEBDE",
  "#DBEFCF",
  "#BEEFFE",
  "#FDDBFB",
  "#EDFFFC",
  "#FCCEEC",
  "#BBECFD",
  "#FDCBDB",
  "#DCBECC",
  "#DCFBDC",
  "#ECBDBC",
  "#DDCBEF",
  "#FBDEEB",
  "#BEBBFD",
  "#FCCBDD",
  "#BFEBEC",
  "#FEFFDB",
  "#CDCFFD",
  "#FFCDFC",
  "#FCFCFC",
  "#EEBECF",
  "#FEEFCB",
  "#BCECFE",
  "#FEBCFE",
  "#DBDCFB",
  "#EFFBFD",
  "#CECDED",
  "#BBDEFF",
  "#EDDFFE",
  "#CCFDBF",
  "#BBBDDF",
  "#BBCCCE",
  "#EECEBF",
  "#BEBCEC",
  "#EBFEBB",
  "#DBEEBB",
  "#FEBCBD",
  "#DEDDFD",
  "#EEBCFD",
  "#EDCCCD",
  "#FFBDFB",
  "#FFFFCB",
  "#EFDCBC",
  "#BFEBCD",
  "#FCFFFC",
  "#CEDCCB",
  "#DCBBDD",
  "#DFFFEB",
  "#CEDBDE",
  "#BBEEFD",
  "#FBDDED",
  "#CBFDBE",
  "#EDFFBF",
  "#EBCCEF",
  "#CDEEBB",
  "#BFEBFF",
  "#EBDBFC",
  "#ECDBCD",
  "#BEEFCD",
  "#BECFEB",
  "#CCFEEE",
  "#FEFECD",
  "#DDEEBF",
  "#FDFBDC",
  "#DFDBBF",
  "#DDCBDB",
  "#DEEEBE",
  "#FBCEBB",
  "#FBCBFE",
  "#BDEECC",
  "#BCCFDE",
  "#BBCEFB",
  "#BDBBEF",
  "#CEBBEB",
  "#CFFDBD",
  "#EBDBBF",
  "#BDCCDD",
  "#EBBFED",
  "#CEBBDF",
  "#BBBDCF",
  "#ECBDFD",
  "#BBBCEB",
  "#BFFEED",
  "#CFEDCB",
  "#BCECDC",
  "#ECEDFF",
  "#DFDEFC",
  "#EEEEBD",
  "#DEFDBC",
  "#CBFFCD",
  "#FDBEEC",
  "#BFFFCF",
  "#BFFBCD",
  "#DBCBDF",
  "#CBDEDE",
  "#DCEEEE",
  "#CFECED",
  "#CFBFDB",
  "#DFFDFE",
  "#DEBCBD",
  "#DCBBFC",
  "#DCCFBE",
  "#CFEFBD",
  "#DEFBBF",
  "#BDDECE",
  "#BDBECD",
  "#DDBCEE",
  "#FFFBED",
  "#FEBDDC",
  "#CDDFBB",
  "#FFECDB",
  "#CBCDED",
  "#EEEDFC",
  "#EEFCBC",
  "#CDCBCC",
  "#DCBCEC",
  "#EFBEEB",
  "#ECBEFB",
  "#DECFFB",
  "#FBECEC",
  "#EDDEFC",
  "#DFCCCF",
  "#DFCDCD",
  "#BECCCF",
  "#DEDCDE",
  "#CDDFEC",
  "#BFDFDF",
  "#ECDDBE",
  "#ECFBEF",
  "#CEFFDB",
  "#BFDFCE",
  "#CFCFEC",
  "#CFDCEF",
  "#BFBDEF",
  "#DEFBCE",
  "#FFCEFF",
  "#FCDCBB",
  "#FEFDCF",
  "#ECEFDE",
  "#ECCCBE",
  "#DDBDFB",
  "#FFECFD",
  "#EEFCCD",
  "#FDFDBC",
  "#BDEFBF",
  "#BEDCFF",
  "#DEEEDF",
  "#FDBFBF",
  "#FBEEDD",
  "#CDDBDB",
  "#FDCCCE",
  "#ECEEEE",
  "#CDCEBF",
  "#DFCBFB",
  "#CBCDFC",
  "#BDBEBE",
  "#FFBEBE",
  "#DBBDFE",
  "#DCEECE",
  "#CFBDDE",
  "#BEFCDF",
  "#BCEEEC",
  "#CECFBC",
  "#DEFEDC",
  "#FDFCCB",
  "#EDFDCF",
  "#EBFEDB",
  "#EFCCCF",
  "#BCEEBB",
  "#CDFCEE",
  "#FCCFFC",
  "#FEECEF",
  "#FFCFED",
  "#BEDBFD",
  "#ECFBFF",
  "#CDDEDF",
  "#ECFBFB",
  "#BEFFEC",
  "#EDDFBB",
  "#EFDEBD",
  "#CCCBEC",
  "#EEBCBD",
  "#EDCCFD",
  "#BBEDEF",
  "#ECDEBE",
  "#EEFDBF",
  "#EFFEBE",
  "#FDDBDC",
  "#FFFCFE",
  "#DDFFEB",
  "#EEEFBC",
  "#DCEFFB",
  "#CCEEDE",
  "#EFEDDC",
  "#CBFCFF",
  "#FDEECB",
  "#DDFEDE",
  "#DBFFFD",
  "#CECFDF",
  "#FFBFBC",
  "#EFDFFE",
  "#FDBBEC",
  "#DBBCBE",
  "#BBBCCD",
  "#CEBEEC",
  "#BBDEEB",
  "#BFEFEB",
  "#EFCECB",
  "#BBBBFB",
  "#EFFEFF",
  "#DCFFFE",
  "#EEECBC",
  "#ECECDF",
  "#BE8C9B",
  "#9CE813",
  "#E8A6BE",
  "#4129D3",
  "#1104DD",
  "#492243",
  "#58F36F",
  "#DCE2DE",
  "#5E2D79",
  "#5F755E",
  "#603311",
  "#608341",
  "#616161",
  "#629632",
  "#636F57",
  "#63D1F4",
  "#65909A",
  "#6600FF",
  "#6666FF",
  "#668E86",
  "#66CDAA",
  "#67E6EC",
  "#687C97",
  "#688571",
  "#6959CD",
  "#698B22",
  "#699864",
  "#6B238E",
  "#6B8E23",
  "#6D9BF1",
  "#6E8B3D",
  "#6F7285",
  "#708090",
  "#70DBDB",
  "#71C671",
  "#734A12",
  "#73B1B7",
  "#754C78",
  "#75A1D0",
  "#777733",
  "#77896C",
  "#78AB46",
  "#79CDCD",
  "#7A7A7A",
  "#7AC5CD",
  "#7B7922",
  "#7CCD7C",
  "#7D7D7D",
  "#7EB6FF",
  "#7F7F7F",
  "#7FFF00",
  "#800080",
  "#808069",
  "#808A87",
  "#828282",
  "#838B83",
  "#83F52C",
  "#855E42",
  "#859C27",
  "#871F78",
  "#878787",
  "#87CEFF",
  "#8A2BE2",
  "#8A8A8A",
  "#8B008B",
  "#8B1C62",
  "#8B2500",
  "#8B3A62",
  "#8B4513",
  "#8B4789",
  "#8B5A00",
  "#8B636C",
  "#8B6914",
  "#8B7500",
  "#8B7B8B",
  "#8B7E66",
  "#8B8386",
  "#8B8878",
  "#8B8B00",
  "#8BA446",
  "#8C7853",
  "#8DB6CD",
  "#8E236B",
  "#8E8E38",
  "#8FBC8F",
  "#90FEFB",
  "#919191",
  "#9370DB",
  "#949494",
  "#96C8A2",
  "#97FFFF",
  "#98FB98",
  "#9932CC",
  "#999999",
  "#9A32CD",
  "#9AFF9A",
  "#9BCD9B",
  "#9C9C9C",
  "#9CCB19",
  "#9D8851",
  "#9E9E9E",
  "#9F79EE",
  "#A020F0",
  "#A1A1A1",
  "#A2BC13",
  "#A2CD5A",
  "#A46582",
  "#A52A2A",
  "#A67D3D",
  "#A6D785",
  "#A8A8A8",
  "#A9ACB6",
  "#AA5303",
  "#AAAAFF",
  "#ABABAB",
  "#ADD8E6",
  "#AEBB51",
  "#AF4035",
  "#B03060",
  "#B0C4DE",
  "#B13E0F",
  "#B272A6",
  "#B2DFEE",
  "#B3C95A",
  "#B4CDCD",
  "#B5509C",
  "#B62084",
  "#B6AFA9",
  "#B7C8B6",
  "#B8860B",
  "#BA55D3",
  "#BB2A3C",
  "#BC8F8F",
  "#BCED91",
  "#BDB76B",
  "#BE2625",
  "#BF3EFF",
  "#BFEFFF",
  "#C0D9D9",
  "#C1CDCD",
  "#C2C2C2",
  "#C4C4C4",
  "#C65D57",
  "#C6E2FF",
  "#C75D4D",
  "#C77826",
  "#C8F526",
  "#CAE1FF",
  "#CC00FF",
  "#CC3299",
  "#CC7F32",
  "#CCCCCC",
  "#CD0000",
  "#CD2626",
  "#CD3333",
  "#CD5555",
  "#CD6090",
  "#CD6839",
  "#CD7054",
  "#CD8500",
  "#CD919E",
  "#CD9B1D",
  "#CDAB2D",
  "#CDB38B",
  "#CDB7B5",
  "#CDC0B0",
  "#CDC673",
  "#CDC9C9",
  "#CDCDC1",
  "#CDE472",
  "#CFCFCF",
  "#D02090",
  "#D0FAEE",
  "#D1D1D1",
  "#D2691E",
  "#D3D3D3",
  "#D4318C",
  "#D4D4D4",
  "#D66F62",
  "#D8BFD8",
  "#D9D919",
  "#DA70D6",
  "#DB2645",
  "#DB70DB",
  "#DBDB70",
  "#DBFEF8",
  "#DCA2CD",
  "#DDA0DD",
  "#DEDEDE",
  "#E04006",
  "#E0D873",
  "#E0EEE0",
  "#E18E2E",
  "#E5E5E5",
  "#E6E8FA",
  "#E8C782",
  "#E9967A",
  "#EAB5C5",
  "#EBC79E",
  "#ECC3BF",
  "#EDC393",
  "#EE0000",
  "#EE2C2C",
  "#EE3B3B",
  "#EE6363",
  "#EE7600",
  "#EE799F",
  "#EE82EE",
  "#EE9A00",
  "#EEA9B8",
  "#EEB422",
  "#EEC900",
  "#EED2EE",
  "#EED6AF",
  "#EEDD82",
  "#EEE5DE",
  "#EEE8CD",
  "#EEEB8D",
  "#EEEEE0",
  "#F0E68C",
  "#F0FFF0",
  "#F2F2F2",
  "#F4F776",
  "#F5DEB3",
  "#F5FFFA",
  "#F6A8B6",
  "#F7B3DA",
  "#F8F8FF",
  "#FA9A50",
  "#FAFAD2",
  "#FBDB0C",
  "#FCB514",
  "#FCDC3B",
  "#FCFFF0",
  "#FEE5AC",
  "#FEF1B5",
  "#FF0066",
  "#FF00CC",
  "#FF030D",
  "#FF2400",
  "#FF3333",
  "#FF3E96",
  "#FF5333",
  "#FF6347",
  "#FF69B4",
  "#FF6EC7",
  "#FF7722",
  "#FF7F24",
  "#FF8247",
  "#FF8600",
  "#FF92BB",
  "#FFA07A",
  "#FFA812",
  "#FFADB9",
  "#FFB5C5",
  "#FFBBFF",
  "#FFC1C1",
  "#FFCC99",
  "#FFD700",
  "#FFE1FF",
  "#FFE4C4",
  "#FFE7BA",
  "#FFEFD5",
  "#FFF5EE",
  "#FFFACD",
  "#FFFCCF",
  "#FFFFAA",
  "#FFFFF0",
  "#00009C",
  "#0000FF",
  "#003F87",
  "#006400",
  "#006B54",
  "#008080",
  "#008B45",
  "#0099CC",
  "#00B2EE",
  "#00C78C",
  "#00CD66",
  "#00E5EE",
  "#00EEEE",
  "#00FF00",
  "#00FF7F",
  "#00FFFF",
  "#0198E1",
  "#030303",
  "#05B8CC",
  "#068481",
  "#0AC92B",
  "#0D4F8B",
  "#0F0F0F",
  "#108070",
  "#138F6A",
  "#162252",
  "#1874CD",
  "#1B3F8B",
  "#1C86EE",
  "#1E90FF",
  "#20BF9F",
  "#215E21",
  "#228B22",
  "#238E68",
  "#262626",
  "#283A90",
  "#292421",
  "#2B2B2B",
  "#2C5D3F",
  "#2E37FE",
  "#2E6444",
  "#2F4F2F",
  "#2FAA96",
  "#3063A5",
  "#3232CC",
  "#325C74",
  "#3299CC",
  "#32CD99",
  "#333333",
  "#33A1C9",
  "#344152",
  "#35586C",
  "#363636",
  "#37BC61",
  "#383838",
  "#38B0DE",
  "#39B7CD",
  "#3A5FCD",
  "#3B3178",
  "#3B5323",
  "#3B8471",
  "#3D5229",
  "#3D8B37",
  "#3E766D",
  "#3EA055",
  "#3F9E4D",
  "#40E0D0",
  "#42526C",
  "#426F42",
  "#436EEE",
  "#43D58C",
  "#458B00",
  "#46523C",
  "#474747",
  "#484D46",
  "#488214",
  "#4981CE",
  "#49E9BD",
  "#4A708B",
  "#4AC948",
  "#4C7064",
  "#4D4D4D",
  "#4D6FAC",
  "#4DBD33",
  "#4F2F4F",
  "#4F8E83",
  "#50729F",
  "#517693",
  "#525252",
  "#527F76",
  "#539DC2",
  "#548B54",
  "#551033",
  "#555555",
  "#567E3A",
  "#584E56",
  "#5959AB",
  "#597368",
  "#5B59BA",
  "#5C246E",
  "#5C5C5C",
  "#5D7B93",
  "#5E2605",
  "#5E5E5E",
  "#5F9EA0",
  "#607B8B",
  "#60AFFE",
  "#6183A6",
  "#62B1F6",
  "#63AB62",
  "#646F5E",
  "#659D32",
  "#660198",
  "#668014",
  "#66CCCC",
  "#66FF66",
  "#68228B",
  "#687E5A",
  "#691F01",
  "#696969",
  "#698B69",
  "#6A5ACD",
  "#6B4226",
  "#6C7B8B",
  "#6E6E6E",
  "#6EFF70",
  "#707070",
  "#7093DB",
  "#71637D",
  "#72587F",
  "#737373",
  "#748269",
  "#757575",
  "#778899",
  "#787878",
  "#79973F",
  "#7A378B",
  "#7A8B8B",
  "#7B3F00",
  "#7BBF6A",
  "#7CFC00",
  "#7D7F94",
  "#7EC0EE",
  "#7F8778",
  "#7FFFD4",
  "#802A2A",
  "#808080",
  "#816687",
  "#82CFFD",
  "#838B8B",
  "#8470FF",
  "#856363",
  "#862A51",
  "#872657",
  "#87CEEB",
  "#88ACE0",
  "#8A3324",
  "#8AA37B",
  "#8B0A50",
  "#8B2252",
  "#8B3626",
  "#8B3E2F",
  "#8B4726",
  "#8B4C39",
  "#8B5A2B",
  "#8B6508",
  "#8B6969",
  "#8B7765",
  "#8B7D6B",
  "#8B814C",
  "#8B864E",
  "#8B8970",
  "#8B8B7A",
  "#8BA870",
  "#8C8C8C",
  "#8DEEEE",
  "#8E388E",
  "#8EE5EE",
  "#8F8FBC",
  "#8FD8D8",
  "#91219E",
  "#91B49C",
  "#93DB70",
  "#964514",
  "#96CDCD",
  "#98A148",
  "#990099",
  "#9932CD",
  "#99CC32",
  "#9AC0CD",
  "#9B30FF",
  "#9C661F",
  "#9CA998",
  "#9D1309",
  "#9DB68C",
  "#9F5F9F",
  "#9F9F5F",
  "#A02422",
  "#A2627A",
  "#A2C257",
  "#A39480",
  "#A4D3EE",
  "#A5435C",
  "#A68064",
  "#A74CAB",
  "#A9A9A9",
  "#A9C9A4",
  "#AA6600",
  "#AC7F24",
  "#ADEAEA",
  "#AEEEEE",
  "#AFEEEE",
  "#B0A6A4",
  "#B0E0E6",
  "#B22222",
  "#B28647",
  "#B3432B",
  "#B3EE3A",
  "#B4D7BF",
  "#B5A642",
  "#B6316C",
  "#B6C5BE",
  "#B81324",
  "#B8B8B8",
  "#BAAF07",
  "#BBFFFF",
  "#BCD2EE",
  "#BCEE68",
  "#BDBDBD",
  "#BEBEBE",
  "#BF5FFF",
  "#C0C0C0",
  "#C0FF3E",
  "#C1F0F6",
  "#C3E4ED",
  "#C5C1AA",
  "#C67171",
  "#C71585",
  "#C76114",
  "#C7C7C7",
  "#C9AF94",
  "#CAFF70",
  "#CC1100",
  "#CC4E5C",
  "#CC99CC",
  "#CCCCFF",
  "#CD00CD",
  "#CD2990",
  "#CD3700",
  "#CD5B45",
  "#CD6600",
  "#CD6889",
  "#CD7F32",
  "#CD853F",
  "#CD950C",
  "#CD9B9B",
  "#CDAD00",
  "#CDB5CD",
  "#CDBA96",
  "#CDC1C5",
  "#CDC8B1",
  "#CDCD00",
  "#CDCDCD",
  "#CECC15",
  "#CFD784",
  "#D0A9AA",
  "#D15FEE",
  "#D1E231",
  "#D2B48C",
  "#D4ED91",
  "#D6C537",
  "#D8D8BF",
  "#D9D9D9",
  "#DAA520",
  "#DB2929",
  "#DB9370",
  "#DBDBDB",
  "#DC143C",
  "#DCDCDC",
  "#DE85B1",
  "#DFAE74",
  "#E0427F",
  "#E0DFDB",
  "#E0EEEE",
  "#E2DDB5",
  "#E47833",
  "#E6B426",
  "#E79EA9",
  "#E8E8E8",
  "#E9C2A6",
  "#EAEAAE",
  "#EBCEAC",
  "#ECC8EC",
  "#EDCB62",
  "#EEB4B4",
  "#EECBAD",
  "#EED5B7",
  "#EED8AE",
  "#EEDFCC",
  "#EEE685",
  "#EEE9BF",
  "#EEEE00",
  "#F08080",
  "#F0F0F0",
  "#F0FFFF",
  "#F3E88E",
  "#F5F5DC",
  "#F64D54",
  "#F6C9CC",
  "#F7F7F7",
  "#FA1D2F",
  "#FAEBD7",
  "#FAFAFA",
  "#FBEC5D",
  "#FCD116",
  "#FCE6C9",
  "#FDF5E6",
  "#FEE8D6",
  "#FF0000",
  "#FF007F",
  "#FF00FF",
  "#FF1493",
  "#FF34B3",
  "#FF4040",
  "#FF6600",
  "#FF6A6A",
  "#FF7D40",
  "#FF7F50",
  "#FF82AB",
  "#FF8C00",
  "#FF9912",
  "#FFA500",
  "#FFA824",
  "#FFAEB9",
  "#FFB6C1",
  "#FFC0CB",
  "#FFC469",
  "#FFCCCC",
  "#FFDAB9",
  "#FFE303",
  "#FFE4E1",
  "#FFEBCD",
  "#FFEFDB",
  "#FFF68F",
  "#FFFAF0",
  "#FFFF00",
  "#FFFFCC",
  "#FFFFFF",
  "#0000CD",
  "#003300",
  "#004F00",
  "#006633",
  "#007FFF",
  "#00868B",
  "#008B8B",
  "#009ACD",
  "#00BFFF",
  "#00C957",
  "#00CDCD",
  "#00EE00",
  "#00F5FF",
  "#00FF33",
  "#00FFAA",
  "#01C5BB",
  "#03A89E",
  "#05E9FF",
  "#080808",
  "#0BB5FF",
  "#0E8C3A",
  "#0FDDAF",
  "#120A8F",
  "#141414",
  "#171717",
  "#191970",
  "#1B6453",
  "#1D7CF2",
  "#1F1F1F",
  "#212121",
  "#218868",
  "#23238E",
  "#242424",
  "#26466D",
  "#284942",
  "#292929",
  "#2B4F81",
  "#2E0854",
  "#2E473B",
  "#2E8B57",
  "#302B54",
  "#308014",
  "#3232CD",
  "#327556",
  "#32CC99",
  "#330000",
  "#3333FF",
  "#33A1DE",
  "#34925E",
  "#3579DC",
  "#36648B",
  "#37FDFC",
  "#385E0F",
  "#395D33",
  "#3A3A38",
  "#3A6629",
  "#3B3B3B",
  "#3B5E2B",
  "#3CB371",
  "#3D59AB",
  "#3D9140",
  "#3F602B",
  "#404040",
  "#414F12",
  "#424242",
  "#426352",
  "#42C0FB",
  "#4372AA",
  "#454545",
  "#458B74",
  "#4682B4",
  "#476A34",
  "#487153",
  "#48D1CC",
  "#499DF5",
  "#4A4A4A",
  "#4A766E",
  "#4B0082",
  "#4CB7A5",
  "#4D4DFF",
  "#4D71A3",
  "#4E78A0",
  "#4F4F2F",
  "#4F94CD",
  "#507786",
  "#517B58",
  "#525C65",
  "#528B8B",
  "#543948",
  "#54FF9F",
  "#55141C",
  "#556B2F",
  "#575757",
  "#586949",
  "#596C56",
  "#5993E5",
  "#5B90F6",
  "#5C3317",
  "#5CACEE",
  "#5D92B1",
  "#5E2612",
  "#5EDA9E",
  "#5F9F9F",
  "#607C6E",
  "#615E3F",
  "#61B329",
  "#636363",
  "#63B8FF",
  "#6495ED",
  "#660000",
  "#666666",
  "#668B8B",
  "#66CD00",
  "#67C8FF",
  "#683A5E",
  "#68838B",
  "#694489",
  "#6996AD",
  "#6A8455",
  "#6B6B6B",
  "#6CA6CD",
  "#6E7B8B",
  "#6F4242",
  "#70DB93",
  "#7171C6",
  "#733D1A",
  "#739AC5",
  "#74BBFB",
  "#759B84",
  "#76EEC6",
  "#78A489",
  "#79A888",
  "#7A67EE",
  "#7AA9DD",
  "#7B68EE",
  "#7BCC70",
  "#7D26CD",
  "#7D9EC0",
  "#7F00FF",
  "#7F9A65",
  "#800000",
  "#808000",
  "#820BBB",
  "#836FFF",
  "#838EDE",
  "#84BE6A",
  "#858585",
  "#86C67C",
  "#87421F",
  "#87CEFA",
  "#8968CD",
  "#8A360F",
  "#8B0000",
  "#8B1A1A",
  "#8B2323",
  "#8B3A3A",
  "#8B4500",
  "#8B475D",
  "#8B5742",
  "#8B5F65",
  "#8B668B",
  "#8B7355",
  "#8B795E",
  "#8B7D7B",
  "#8B8378",
  "#8B8682",
  "#8B8989",
  "#8B8B83",
  "#8C1717",
  "#8CDD81",
  "#8E2323",
  "#8E6B23",
  "#8F5E99",
  "#8FA880",
  "#90EE90",
  "#912CEE",
  "#92CCA6",
  "#9400D3",
  "#969696",
  "#97694F",
  "#98F5FF",
  "#99182C",
  "#993300",
  "#99CDC9",
  "#9ACD32",
  "#9BC4E2",
  "#9C6B98",
  "#9CBA7F",
  "#9D6B84",
  "#9E0508",
  "#9F703A",
  "#9FB6CD",
  "#A0522D",
  "#A2B5CD",
  "#A2C93A",
  "#A3A3A3",
  "#A4DCD1",
  "#A62A2A",
  "#A6A6A6",
  "#A78D84",
  "#AA00FF",
  "#AAAAAA",
  "#AB82FF",
  "#ADADAD",
  "#ADFF2F",
  "#AF1E2D",
  "#B0171F",
  "#B0B0B0",
  "#B0E2FF",
  "#B23AEE",
  "#B2D0B4",
  "#B3B3B3",
  "#B452CD",
  "#B4EEB4",
  "#B5B5B5",
  "#B67C3D",
  "#B7C3D0",
  "#B87333",
  "#B9D3EE",
  "#BABABA",
  "#BC7642",
  "#BCE937",
  "#BDA0CB",
  "#BDFCC9",
  "#BEE554",
  "#BFBFBF",
  "#C0D9AF",
  "#C1CDC1",
  "#C1FFC1",
  "#C48E48",
  "#C5E3BF",
  "#C6C3B5",
  "#C73F17",
  "#C76E06",
  "#C82536",
  "#C9C9C9",
  "#CBCAB6",
  "#CC3232",
  "#CC7722",
  "#CCCC00",
  "#CCFFCC",
  "#CD1076",
  "#CD3278",
  "#CD4F39",
  "#CD5C5C",
  "#CD661D",
  "#CD69C9",
  "#CD8162",
  "#CD8C95",
  "#CD96CD",
  "#CDAA7D",
  "#CDAF95",
  "#CDB79E",
  "#CDBE70",
  "#CDC5BF",
  "#CDC9A5",
  "#CDCDB4",
  "#CDD704",
  "#CFB53B",
  "#CFDBC5",
  "#D0D2C4",
  "#D19275",
  "#D1EEEE",
  "#D3BECF",
  "#D41A1F",
  "#D44942",
  "#D5B77A",
  "#D6D6D6",
  "#D98719",
  "#D9D9F3",
  "#DAF4F0",
  "#DB7093",
  "#DB9EA6",
  "#DBE6E0",
  "#DC8909",
  "#DD7500",
  "#DEB887",
  "#DFFFA5",
  "#E066FF",
  "#E0E0E0",
  "#E0FFFF",
  "#E5BC3B",
  "#E6E6FA",
  "#E7C6A5",
  "#E8F1D4",
  "#EAADEA",
  "#EB5E66",
  "#EBEBEB",
  "#ED9121",
  "#EDEDED",
  "#EE1289",
  "#EEA2AD",
  "#EEAEEE",
  "#EEC591",
  "#EECFA1",
  "#EED5D2",
  "#EEDC82",
  "#EEE0E5",
  "#EEE8AA",
  "#EEE9E9",
  "#EEEED1",
  "#F0A804",
  "#F0F8FF",
  "#F2473F",
  "#F4A460",
  "#F5785A",
  "#F5F5F5",
  "#F6A4D5",
  "#F6CCDA",
  "#F87531",
  "#FA8072",
  "#FAF0E6",
  "#FBA16C",
  "#FC1501",
  "#FCD59C",
  "#FDF8FF",
  "#FEF0DB",
  "#FF0033",
  "#FF00AA",
  "#FF1CAE",
  "#FF3300",
  "#FF3D0D",
  "#FF4500",
  "#FF6103",
  "#FF6666",
  "#FF6EB4",
  "#FF7256",
  "#FF7F00",
  "#FF8000",
  "#FF83FA",
  "#FF8C69",
  "#FF9955",
  "#FFA54F",
  "#FFAA00",
  "#FFB00F",
  "#FFB90F",
  "#FFC125",
  "#FFCC11",
  "#FFD39B",
  "#FFDEAD",
  "#FFE4B5",
  "#FFE600",
  "#FFEC8B",
  "#FFF0F5",
  "#FFF8DC",
  "#FFFAFA",
  "#FFFF7E",
  "#FFFFE0",
  "#EA899A",
  "#231A24",
];

export const DarkColorPalette = [
  "#00857C",
  "#6ECEB2",
  "#82AAE3",
  "#88A47C",
  "#227C70",
  "#6D9886",
  "#624F82",
  "#256D85",
  "#4C6793",
  "#395B64",
  "#495C83",
  "#839AA8",
  "#5B4B8A",
  "#7858A6",
  "#646FD4",
  "#069A8E",
  "#7C99AC",
  "#7267CB",

  // "#0C2340",
  "#5450E4",
  "#688CE8",
  "#69B8F7",
  "#A8566E",
  "#6D5276",
  "#4F909D",
  "#67AC66",
  "#AAAB6A",
  "#D01983",
  "#1D177D",
  "#EAEDA1",
  "#40D0BC",
  "#F45600",
  "#D0E516",
  "#6300AC",
  "#93304D",
  "#8884d8",
  "#dd7876",

  "#BFED33",
  "#FFF063",
];
export const LABEL_COLORS = ["#5450E4", "#9f9ded", "#EBECF0"];

export const BRAND_COLORS = [
  "#00857C",
  "#6ECEB2",
  "#69B8F7",
  "#688CE8",
  "#5450E4",
  "#0C2340",
  "#BFED33",
  "#FFF063",
  "#BBCCCF",
  "#FBCDBC",
  "#BBDCBC",
  "#CCCDDF",
  "#BDBFDC",
  "#FFDCFD",
  "#CECEFF",
  "#EBEDDE",
  "#EEDBCF",
  "#EFBCDD",
  "#EDEDEC",
  "#DECBBC",
  "#FBDEDF",
  "#BEBFBD",
  "#BBFBCD",
  "#DFECDB",
  "#EDCCDE",
  "#FCEBFC",
  "#CEEEBB",
  "#BEFEEB",
  "#BECECB",
  "#FCBFEF",
  "#DCFEFB",
  "#DBBCCC",
  "#CBDDEF",
  "#CBCEBD",
  "#DEDEEC",
  "#EFDEBB",
  "#BBDEEF",
  "#EFECBB",
  "#EEBBFD",
  "#FFFCCD",
  "#CBBBBC",
  "#EEDBBE",
  "#CCCFDB",
  "#EECDCE",
  "#DDDFDD",
  "#FCCEBC",
  "#FCCDBD",
  "#DEFCCF",
  "#DCDCDF",
  "#EFDEDD",
  "#EDFCBC",
  "#DEDEEE",
  "#DFCDBB",
  "#EBCCBC",
  "#CEDBCF",
  "#BCCDDE",
  "#BFBEFE",
  "#CEEDDC",
  "#EDFFDB",
  "#CCEFFF",
  "#CEDFBE",
  "#BCCEDC",
  "#DEEDCE",
  "#EEBBCD",
  "#FFEBFE",
  "#EFECFB",
  "#FFEBEE",
  "#EBEEFF",
  "#EBFEEE",
  "#BEFDDB",
  "#ECDBDC",
  "#BDBCDD",
  "#EECEED",
  "#BECDDF",
  "#DEFEBE",
  "#CCDEEF",
  "#DCECEC",
  "#FDEBDB",
  "#BCDDCF",
  "#EDBBCE",
  "#ECFDBC",
  "#DFECBF",
  "#DBDBED",
  "#FDEEDD",
  "#FCFFCD",
  "#DDCCEB",
  "#BCDBCF",
  "#FEBFBE",
  "#EFBBFD",
  "#FBFDBE",
  "#CBDDCB",
  "#DBFFCB",
  "#BDBCFD",
  "#DEEBDE",
  "#DBEFCF",
  "#BEEFFE",
  "#FDDBFB",
  "#EDFFFC",
  "#FCCEEC",
  "#BBECFD",
  "#FDCBDB",
  "#DCBECC",
  "#DCFBDC",
  "#ECBDBC",
  "#DDCBEF",
  "#FBDEEB",
  "#BEBBFD",
  "#FCCBDD",
  "#BFEBEC",
  "#FEFFDB",
  "#CDCFFD",
  "#FFCDFC",
  "#FCFCFC",
  "#FBFBDF",
  "#EEBECF",
  "#FEEFCB",
  "#BCECFE",
  "#FEBCFE",
  "#DBDCFB",
  "#EFFBFD",
  "#CECDED",
  "#BBDEFF",
  "#EDDFFE",
  "#CCFDBF",
  "#BBBDDF",
  "#BBCCCE",
  "#EECEBF",
  "#BEBCEC",
  "#EBFEBB",
  "#DBEEBB",
  "#FEBCBD",
  "#DEDDFD",
  "#EEBCFD",
  "#EDCCCD",
  "#FFBDFB",
  "#FFFFCB",
  "#EFDCBC",
  "#BFEBCD",
  "#FCFFFC",
  "#CEDCCB",
  "#DCBBDD",
  "#DFFFEB",
  "#CEDBDE",
  "#BBEEFD",
  "#FBDDED",
  "#CBFDBE",
  "#EDFFBF",
  "#EBCCEF",
  "#CDEEBB",
  "#BFEBFF",
  "#EBDBFC",
  "#ECDBCD",
  "#BEEFCD",
  "#BECFEB",
  "#CCFEEE",
  "#FEFECD",
  "#DDEEBF",
  "#FDFBDC",
  "#DFDBBF",
  "#DDCBDB",
  "#DEEEBE",
  "#FBCEBB",
  "#FBCBFE",
  "#BDEECC",
  "#BCCFDE",
  "#BBCEFB",
  "#BDBBEF",
  "#CEBBEB",
  "#CFFDBD",
  "#EBDBBF",
  "#BDCCDD",
  "#EBBFED",
  "#CEBBDF",
  "#BBBDCF",
  "#ECBDFD",
  "#BBBCEB",
  "#BFFEED",
  "#CFEDCB",
  "#BCECDC",
  "#ECEDFF",
  "#CFCCBB",
  "#DFDEFC",
  "#EEEEBD",
  "#DEFDBC",
  "#CBFFCD",
  "#FDBEEC",
  "#BFFFCF",
  "#BFFBCD",
  "#DBCBDF",
  "#CBDEDE",
  "#DCEEEE",
  "#CFECED",
  "#CFBFDB",
  "#DFFDFE",
  "#DEBCBD",
  "#DCBBFC",
  "#DCCFBE",
  "#CFEFBD",
  "#DEFBBF",
  "#BDDECE",
  "#BDBECD",
  "#DDBCEE",
  "#FFFBED",
  "#FEBDDC",
  "#CDDFBB",
  "#FFECDB",
  "#CBCDED",
  "#EEEDFC",
  "#EEFCBC",
  "#CDCBCC",
  "#DCBCEC",
  "#EFBEEB",
  "#ECBEFB",
  "#DECFFB",
  "#FBECEC",
  "#EDDEFC",
  "#DFCCCF",
  "#DFCDCD",
  "#BECCCF",
  "#DEDCDE",
  "#CDDFEC",
  "#BFDFDF",
  "#ECDDBE",
  "#ECFBEF",
  "#CEFFDB",
  "#BFDFCE",
  "#CFCFEC",
  "#CFDCEF",
  "#BFBDEF",
  "#DEFBCE",
  "#FFCEFF",
  "#FCDCBB",
  "#FEFDCF",
  "#ECEFDE",
  "#ECCCBE",
  "#DDBDFB",
  "#FFECFD",
  "#EEFCCD",
  "#FDFDBC",
  "#BDEFBF",
  "#BEDCFF",
  "#DEEEDF",
  "#FDBFBF",
  "#FBEEDD",
  "#CDDBDB",
  "#FDCCCE",
  "#ECEEEE",
  "#CDCEBF",
  "#DFCBFB",
  "#CBCDFC",
  "#BDBEBE",
  "#FFBEBE",
  "#DBBDFE",
  "#DCEECE",
  "#CFBDDE",
  "#BEFCDF",
  "#BCEEEC",
  "#CECFBC",
  "#DEFEDC",
  "#FDFCCB",
  "#EDFDCF",
  "#EBFEDB",
  "#EFCCCF",
  "#BCEEBB",
  "#CDFCEE",
  "#FCCFFC",
  "#FEECEF",
  "#FFCFED",
  "#BEDBFD",
  "#ECFBFF",
  "#CDDEDF",
  "#ECFBFB",
  "#BEFFEC",
  "#EDDFBB",
  "#EFDEBD",
  "#CCCBEC",
  "#EEBCBD",
  "#EDCCFD",
  "#BBEDEF",
  "#ECDEBE",
  "#EEFDBF",
  "#EFFEBE",
  "#FDDBDC",
  "#FFFCFE",
  "#DDFFEB",
  "#EEEFBC",
  "#DCEFFB",
  "#CCEEDE",
  "#EFEDDC",
  "#CBFCFF",
  "#FDEECB",
  "#DDFEDE",
  "#DBFFFD",
  "#CECFDF",
  "#FFBFBC",
  "#EFDFFE",
  "#FDBBEC",
  "#DBBCBE",
  "#BBBCCD",
  "#CEBEEC",
  "#BBDEEB",
  "#BFEFEB",
  "#EFCECB",
  "#BBBBFB",
  "#EFFEFF",
  "#DCFFFE",
  "#EEECBC",
  "#ECECDF",
  "#BE8C9B",
  "#9CE813",
  "#E8A6BE",
  "#4129D3",
  "#1104DD",
  "#492243",
  "#58F36F",
  "#DCE2DE",
  "#5E2D79",
  "#5F755E",
  "#603311",
  "#608341",
  "#616161",
  "#629632",
  "#636F57",
  "#63D1F4",
  "#65909A",
  "#6600FF",
  "#6666FF",
  "#668E86",
  "#66CDAA",
  "#67E6EC",
  "#687C97",
  "#688571",
  "#6959CD",
  "#698B22",
  "#699864",
  "#6B238E",
  "#6B8E23",
  "#6D9BF1",
  "#6E8B3D",
  "#6F7285",
  "#708090",
  "#70DBDB",
  "#71C671",
  "#734A12",
  "#73B1B7",
  "#754C78",
  "#75A1D0",
  "#777733",
  "#77896C",
  "#78AB46",
  "#79CDCD",
  "#7A7A7A",
  "#7AC5CD",
  "#7B7922",
  "#7CCD7C",
  "#7D7D7D",
  "#7EB6FF",
  "#7F7F7F",
  "#7FFF00",
  "#800080",
  "#808069",
  "#808A87",
  "#828282",
  "#838B83",
  "#83F52C",
  "#855E42",
  "#859C27",
  "#871F78",
  "#878787",
  "#87CEFF",
  "#8A2BE2",
  "#8A8A8A",
  "#8B008B",
  "#8B1C62",
  "#8B2500",
  "#8B3A62",
  "#8B4513",
  "#8B4789",
  "#8B5A00",
  "#8B636C",
  "#8B6914",
  "#8B7500",
  "#8B7B8B",
  "#8B7E66",
  "#8B8386",
  "#8B8878",
  "#8B8B00",
  "#8BA446",
  "#8C7853",
  "#8DB6CD",
  "#8E236B",
  "#8E8E38",
  "#8FBC8F",
  "#90FEFB",
  "#919191",
  "#9370DB",
  "#949494",
  "#96C8A2",
  "#97FFFF",
  "#98FB98",
  "#9932CC",
  "#999999",
  "#9A32CD",
  "#9AFF9A",
  "#9BCD9B",
  "#9C9C9C",
  "#9CCB19",
  "#9D8851",
  "#9E9E9E",
  "#9F79EE",
  "#A020F0",
  "#A1A1A1",
  "#A2BC13",
  "#A2CD5A",
  "#A46582",
  "#A52A2A",
  "#A67D3D",
  "#A6D785",
  "#A8A8A8",
  "#A9ACB6",
  "#AA5303",
  "#AAAAFF",
  "#ABABAB",
  "#ADD8E6",
  "#AEBB51",
  "#AF4035",
  "#B03060",
  "#B0C4DE",
  "#B13E0F",
  "#B272A6",
  "#B2DFEE",
  "#B3C95A",
  "#B4CDCD",
  "#B5509C",
  "#B62084",
  "#B6AFA9",
  "#B7C8B6",
  "#B8860B",
  "#BA55D3",
  "#BB2A3C",
  "#BC8F8F",
  "#BCED91",
  "#BDB76B",
  "#BE2625",
  "#BF3EFF",
  "#BFEFFF",
  "#C0D9D9",
  "#C1CDCD",
  "#C2C2C2",
  "#C4C4C4",
  "#C65D57",
  "#C6E2FF",
  "#C75D4D",
  "#C77826",
  "#C8F526",
  "#CAE1FF",
  "#CC00FF",
  "#CC3299",
  "#CC7F32",
  "#CCCCCC",
  "#CD0000",
  "#CD2626",
  "#CD3333",
  "#CD5555",
  "#CD6090",
  "#CD6839",
  "#CD7054",
  "#CD8500",
  "#CD919E",
  "#CD9B1D",
  "#CDAB2D",
  "#CDB38B",
  "#CDB7B5",
  "#CDC0B0",
  "#CDC673",
  "#CDC9C9",
  "#CDCDC1",
  "#CDE472",
  "#CFCFCF",
  "#D02090",
  "#D0FAEE",
  "#D1D1D1",
  "#D2691E",
  "#D3D3D3",
  "#D4318C",
  "#D4D4D4",
  "#D66F62",
  "#D8BFD8",
  "#D9D919",
  "#DA70D6",
  "#DB2645",
  "#DB70DB",
  "#DBDB70",
  "#DBFEF8",
  "#DCA2CD",
  "#DDA0DD",
  "#DEDEDE",
  "#E04006",
  "#E0D873",
  "#E0EEE0",
  "#E18E2E",
  "#E3170D",
  "#E33638",
  "#E38217",
  "#E3E3E3",
  "#E5E5E5",
  "#E6E8FA",
  "#E8C782",
  "#E9967A",
  "#EAB5C5",
  "#EBC79E",
  "#ECC3BF",
  "#EDC393",
  "#EE0000",
  "#EE2C2C",
  "#EE3B3B",
  "#EE6363",
  "#EE7600",
  "#EE799F",
  "#EE82EE",
  "#EE9A00",
  "#EEA9B8",
  "#EEB422",
  "#EEC900",
  "#EED2EE",
  "#EED6AF",
  "#EEDD82",
  "#EEE5DE",
  "#EEE8CD",
  "#EEEB8D",
  "#EEEEE0",
  "#F0E68C",
  "#F0FFF0",
  "#F2F2F2",
  "#F4F776",
  "#F5DEB3",
  "#F5FFFA",
  "#F6A8B6",
  "#F7B3DA",
  "#F8F8FF",
  "#FA9A50",
  "#FAFAD2",
  "#FBDB0C",
  "#FCB514",
  "#FCDC3B",
  "#FCFFF0",
  "#FEE5AC",
  "#FEF1B5",
  "#FF0066",
  "#FF00CC",
  "#FF030D",
  "#FF2400",
  "#FF3333",
  "#FF3E96",
  "#FF5333",
  "#FF6347",
  "#FF69B4",
  "#FF6EC7",
  "#FF7722",
  "#FF7F24",
  "#FF8247",
  "#FF8600",
  "#FF92BB",
  "#FFA07A",
  "#FFA812",
  "#FFADB9",
  "#FFB5C5",
  "#FFBBFF",
  "#FFC1C1",
  "#FFCC99",
  "#FFD700",
  "#FFE1FF",
  "#FFE4C4",
  "#FFE7BA",
  "#FFEFD5",
  "#FFF5EE",
  "#FFFACD",
  "#FFFCCF",
  "#FFFFAA",
  "#FFFFF0",
  "#00009C",
  "#0000FF",
  "#003F87",
  "#006400",
  "#006B54",
  "#008080",
  "#008B45",
  "#0099CC",
  "#00B2EE",
  "#00C78C",
  "#00CD66",
  "#00E5EE",
  "#00EEEE",
  "#00FF00",
  "#00FF7F",
  "#00FFFF",
  "#0198E1",
  "#030303",
  "#05B8CC",
  "#068481",
  "#0AC92B",
  "#0D4F8B",
  "#0F0F0F",
  "#108070",
  "#138F6A",
  "#162252",
  "#1874CD",
  "#1B3F8B",
  "#1C86EE",
  "#1E90FF",
  "#20BF9F",
  "#215E21",
  "#228B22",
  "#238E68",
  "#262626",
  "#283A90",
  "#292421",
  "#2B2B2B",
  "#2C5D3F",
  "#2E37FE",
  "#2E6444",
  "#2F4F2F",
  "#2FAA96",
  "#3063A5",
  "#3232CC",
  "#325C74",
  "#3299CC",
  "#32CD99",
  "#333333",
  "#33A1C9",
  "#344152",
  "#35586C",
  "#363636",
  "#37BC61",
  "#383838",
  "#38B0DE",
  "#39B7CD",
  "#3A5FCD",
  "#3B3178",
  "#3B5323",
  "#3B8471",
  "#3D5229",
  "#3D8B37",
  "#3E766D",
  "#3EA055",
  "#3F9E4D",
  "#40E0D0",
  "#422C2F",
  "#42526C",
  "#426F42",
  "#436EEE",
  "#43D58C",
  "#458B00",
  "#46523C",
  "#474747",
  "#484D46",
  "#488214",
  "#4981CE",
  "#49E9BD",
  "#4A708B",
  "#4AC948",
  "#4C7064",
  "#4D4D4D",
  "#4D6FAC",
  "#4DBD33",
  "#4F2F4F",
  "#4F8E83",
  "#50729F",
  "#517693",
  "#525252",
  "#527F76",
  "#539DC2",
  "#548B54",
  "#551033",
  "#555555",
  "#567E3A",
  "#584E56",
  "#5959AB",
  "#597368",
  "#5B59BA",
  "#5C246E",
  "#5C5C5C",
  "#5D7B93",
  "#5E2605",
  "#5E5E5E",
  "#5F9EA0",
  "#607B8B",
  "#60AFFE",
  "#6183A6",
  "#62B1F6",
  "#63AB62",
  "#646F5E",
  "#659D32",
  "#660198",
  "#668014",
  "#66CCCC",
  "#66FF66",
  "#68228B",
  "#687E5A",
  "#691F01",
  "#696969",
  "#698B69",
  "#6A5ACD",
  "#6B4226",
  "#6C7B8B",
  "#6E6E6E",
  "#6EFF70",
  "#707070",
  "#7093DB",
  "#71637D",
  "#72587F",
  "#737373",
  "#748269",
  "#757575",
  "#76EE00",
  "#778899",
  "#787878",
  "#79973F",
  "#7A378B",
  "#7A8B8B",
  "#7B3F00",
  "#7BBF6A",
  "#7CFC00",
  "#7D7F94",
  "#7EC0EE",
  "#7F8778",
  "#7FFFD4",
  "#802A2A",
  "#808080",
  "#816687",
  "#82CFFD",
  "#838B8B",
  "#8470FF",
  "#856363",
  "#862A51",
  "#872657",
  "#87CEEB",
  "#88ACE0",
  "#8A3324",
  "#8AA37B",
  "#8B0A50",
  "#8B2252",
  "#8B3626",
  "#8B3E2F",
  "#8B4726",
  "#8B4C39",
  "#8B5A2B",
  "#8B6508",
  "#8B6969",
  "#8B7765",
  "#8B7D6B",
  "#8B814C",
  "#8B864E",
  "#8B8970",
  "#8B8B7A",
  "#8BA870",
  "#8C8C8C",
  "#8DEEEE",
  "#8E388E",
  "#8EE5EE",
  "#8F8FBC",
  "#8FD8D8",
  "#91219E",
  "#91B49C",
  "#93DB70",
  "#964514",
  "#96CDCD",
  "#98A148",
  "#990099",
  "#9932CD",
  "#99CC32",
  "#9AC0CD",
  "#9B30FF",
  "#9C661F",
  "#9CA998",
  "#9D1309",
  "#9DB68C",
  "#9F5F9F",
  "#9F9F5F",
  "#A02422",
  "#A2627A",
  "#A2C257",
  "#A39480",
  "#A4D3EE",
  "#A5435C",
  "#A68064",
  "#A74CAB",
  "#A9A9A9",
  "#A9C9A4",
  "#AA6600",
  "#AADD00",
  "#AC7F24",
  "#ADEAEA",
  "#AEEEEE",
  "#AFEEEE",
  "#B0A6A4",
  "#B0E0E6",
  "#B22222",
  "#B28647",
  "#B3432B",
  "#B3EE3A",
  "#B4D7BF",
  "#B5A642",
  "#B6316C",
  "#B6C5BE",
  "#B81324",
  "#B8B8B8",
  "#BAAF07",
  "#BBFFFF",
  "#BCD2EE",
  "#BCEE68",
  "#BDBDBD",
  "#BEBEBE",
  "#BF5FFF",
  "#C0C0C0",
  "#C0FF3E",
  "#C1F0F6",
  "#C3E4ED",
  "#C5C1AA",
  "#C67171",
  "#C71585",
  "#C76114",
  "#C7C7C7",
  "#C9AF94",
  "#CAFF70",
  "#CC1100",
  "#CC4E5C",
  "#CC99CC",
  "#CCCCFF",
  "#CD00CD",
  "#CD2990",
  "#CD3700",
  "#CD5B45",
  "#CD6600",
  "#CD6889",
  "#CD7F32",
  "#CD853F",
  "#CD950C",
  "#CD9B9B",
  "#CDAD00",
  "#CDB5CD",
  "#CDBA96",
  "#CDC1C5",
  "#CDC8B1",
  "#CDCD00",
  "#CDCDCD",
  "#CECC15",
  "#CFD784",
  "#D0A9AA",
  "#D15FEE",
  "#D1E231",
  "#D2B48C",
  "#D43D1A",
  "#D4ED91",
  "#D6C537",
  "#D8D8BF",
  "#D9D9D9",
  "#DAA520",
  "#DB2929",
  "#DB9370",
  "#DBDBDB",
  "#DC143C",
  "#DCDCDC",
  "#DE85B1",
  "#DFAE74",
  "#E0427F",
  "#E0DFDB",
  "#E0EEEE",
  "#E2DDB5",
  "#E32636",
  "#E35152",
  "#E3A869",
  "#E47833",
  "#E6B426",
  "#E79EA9",
  "#E8E8E8",
  "#E9C2A6",
  "#EAEAAE",
  "#EBCEAC",
  "#ECC8EC",
  "#EDCB62",
  "#EE00EE",
  "#EE30A7",
  "#EE4000",
  "#EE6A50",
  "#EE7621",
  "#EE7AE9",
  "#EE8833",
  "#EE9A49",
  "#EEAD0E",
  "#EEB4B4",
  "#EECBAD",
  "#EED5B7",
  "#EED8AE",
  "#EEDFCC",
  "#EEE685",
  "#EEE9BF",
  "#EEEE00",
  "#F08080",
  "#F0F0F0",
  "#F0FFFF",
  "#F3E88E",
  "#F54D70",
  "#F5F5DC",
  "#F64D54",
  "#F6C9CC",
  "#F7F7F7",
  "#FA1D2F",
  "#FAEBD7",
  "#FAFAFA",
  "#FBEC5D",
  "#FCD116",
  "#FCE6C9",
  "#FDF5E6",
  "#FEE8D6",
  "#FF0000",
  "#FF007F",
  "#FF00FF",
  "#FF1493",
  "#FF3030",
  "#FF34B3",
  "#FF4040",
  "#FF5721",
  "#FF6600",
  "#FF6A6A",
  "#FF7216",
  "#FF7D40",
  "#FF7F50",
  "#FF82AB",
  "#FF8C00",
  "#FF9912",
  "#FFA500",
  "#FFA824",
  "#FFAEB9",
  "#FFB6C1",
  "#FFC0CB",
  "#FFC469",
  "#FFCCCC",
  "#FFDAB9",
  "#FFE303",
  "#FFE4E1",
  "#FFEBCD",
  "#FFEFDB",
  "#FFF68F",
  "#FFFAF0",
  "#FFFF00",
  "#FFFFCC",
  "#FFFFFF",
  "#0000CD",
  "#003300",
  "#004F00",
  "#006633",
  "#007FFF",
  "#00868B",
  "#008B8B",
  "#009ACD",
  "#00BFFF",
  "#00C957",
  "#00CDCD",
  "#00EE00",
  "#00F5FF",
  "#00FF33",
  "#00FFAA",
  "#01C5BB",
  "#03A89E",
  "#05E9FF",
  "#080808",
  "#0BB5FF",
  "#0E8C3A",
  "#0FDDAF",
  "#120A8F",
  "#141414",
  "#171717",
  "#191970",
  "#1B6453",
  "#1D7CF2",
  "#1F1F1F",
  "#212121",
  "#218868",
  "#23238E",
  "#242424",
  "#26466D",
  "#284942",
  "#292929",
  "#2B4F81",
  "#2E0854",
  "#2E473B",
  "#2E8B57",
  "#302B54",
  "#308014",
  "#3232CD",
  "#327556",
  "#32CC99",
  "#330000",
  "#3333FF",
  "#33A1DE",
  "#34925E",
  "#3579DC",
  "#36648B",
  "#37FDFC",
  "#385E0F",
  "#395D33",
  "#3A3A38",
  "#3A6629",
  "#3B3B3B",
  "#3B5E2B",
  "#3CB371",
  "#3D59AB",
  "#3D9140",
  "#3F602B",
  "#404040",
  "#414F12",
  "#424242",
  "#426352",
  "#42C0FB",
  "#4372AA",
  "#454545",
  "#458B74",
  "#4682B4",
  "#476A34",
  "#487153",
  "#48D1CC",
  "#499DF5",
  "#4A4A4A",
  "#4A766E",
  "#4B0082",
  "#4CB7A5",
  "#4D4DFF",
  "#4D71A3",
  "#4E78A0",
  "#4F4F2F",
  "#4F94CD",
  "#507786",
  "#517B58",
  "#525C65",
  "#528B8B",
  "#543948",
  "#54FF9F",
  "#55141C",
  "#556B2F",
  "#575757",
  "#586949",
  "#596C56",
  "#5993E5",
  "#5B90F6",
  "#5C3317",
  "#5CACEE",
  "#5D92B1",
  "#5E2612",
  "#5EDA9E",
  "#5F9F9F",
  "#607C6E",
  "#615E3F",
  "#61B329",
  "#636363",
  "#63B8FF",
  "#6495ED",
  "#660000",
  "#666666",
  "#668B8B",
  "#66CD00",
  "#67C8FF",
  "#683A5E",
  "#68838B",
  "#694489",
  "#6996AD",
  "#6A8455",
  "#6B6B6B",
  "#6CA6CD",
  "#6E7B8B",
  "#6F4242",
  "#70DB93",
  "#7171C6",
  "#733D1A",
  "#739AC5",
  "#74BBFB",
  "#759B84",
  "#76EEC6",
  "#78A489",
  "#79A888",
  "#7A67EE",
  "#7AA9DD",
  "#7B68EE",
  "#7BCC70",
  "#7D26CD",
  "#7D9EC0",
  "#7F00FF",
  "#7F9A65",
  "#800000",
  "#808000",
  "#820BBB",
  "#836FFF",
  "#838EDE",
  "#84BE6A",
  "#858585",
  "#86C67C",
  "#87421F",
  "#87CEFA",
  "#8968CD",
  "#8A360F",
  "#8B0000",
  "#8B1A1A",
  "#8B2323",
  "#8B3A3A",
  "#8B4500",
  "#8B475D",
  "#8B5742",
  "#8B5F65",
  "#8B668B",
  "#8B7355",
  "#8B795E",
  "#8B7D7B",
  "#8B8378",
  "#8B8682",
  "#8B8989",
  "#8B8B83",
  "#8C1717",
  "#8CDD81",
  "#8E2323",
  "#8E6B23",
  "#8F5E99",
  "#8FA880",
  "#90EE90",
  "#912CEE",
  "#92CCA6",
  "#9400D3",
  "#969696",
  "#97694F",
  "#98F5FF",
  "#99182C",
  "#993300",
  "#99CDC9",
  "#9ACD32",
  "#9BC4E2",
  "#9C6B98",
  "#9CBA7F",
  "#9D6B84",
  "#9E0508",
  "#9F703A",
  "#9FB6CD",
  "#A0522D",
  "#A2B5CD",
  "#A2C93A",
  "#A3A3A3",
  "#A4DCD1",
  "#A62A2A",
  "#A6A6A6",
  "#A78D84",
  "#AA00FF",
  "#AAAAAA",
  "#AB82FF",
  "#ADADAD",
  "#ADFF2F",
  "#AF1E2D",
  "#B0171F",
  "#B0B0B0",
  "#B0E2FF",
  "#B23AEE",
  "#B2D0B4",
  "#B3B3B3",
  "#B452CD",
  "#B4EEB4",
  "#B5B5B5",
  "#B67C3D",
  "#B7C3D0",
  "#B87333",
  "#B9D3EE",
  "#BABABA",
  "#BC7642",
  "#BCE937",
  "#BDA0CB",
  "#BDFCC9",
  "#BEE554",
  "#BFBFBF",
  "#C0D9AF",
  "#C1CDC1",
  "#C1FFC1",
  "#C48E48",
  "#C5E3BF",
  "#C6C3B5",
  "#C73F17",
  "#C76E06",
  "#C82536",
  "#C9C9C9",
  "#CBCAB6",
  "#CC3232",
  "#CC7722",
  "#CCCC00",
  "#CCFFCC",
  "#CD1076",
  "#CD3278",
  "#CD4F39",
  "#CD5C5C",
  "#CD661D",
  "#CD69C9",
  "#CD8162",
  "#CD8C95",
  "#CD96CD",
  "#CDAA7D",
  "#CDAF95",
  "#CDB79E",
  "#CDBE70",
  "#CDC5BF",
  "#CDC9A5",
  "#CDCDB4",
  "#CDD704",
  "#CFB53B",
  "#CFDBC5",
  "#D0D2C4",
  "#D19275",
  "#D1EEEE",
  "#D3BECF",
  "#D41A1F",
  "#D44942",
  "#D5B77A",
  "#D6D6D6",
  "#D98719",
  "#D9D9F3",
  "#DAF4F0",
  "#DB7093",
  "#DB9EA6",
  "#DBE6E0",
  "#DC8909",
  "#DD7500",
  "#DEB887",
  "#DFFFA5",
  "#E066FF",
  "#E0E0E0",
  "#E0FFFF",
  "#E31230",
  "#E32E30",
  "#E3701A",
  "#E3CF57",
  "#E5BC3B",
  "#E6E6FA",
  "#E7C6A5",
  "#E8F1D4",
  "#EAADEA",
  "#EB5E66",
  "#EBEBEB",
  "#ED9121",
  "#EDEDED",
  "#EE1289",
  "#EE3A8C",
  "#EE5C42",
  "#EE6AA7",
  "#EE7942",
  "#EE8262",
  "#EE9572",
  "#EEA2AD",
  "#EEAEEE",
  "#EEC591",
  "#EECFA1",
  "#EED5D2",
  "#EEDC82",
  "#EEE0E5",
  "#EEE8AA",
  "#EEE9E9",
  "#EEEED1",
  "#F0A804",
  "#F0F8FF",
  "#F2473F",
  "#F4A460",
  "#F5785A",
  "#F5F5F5",
  "#F6A4D5",
  "#F6CCDA",
  "#F87531",
  "#FA8072",
  "#FAF0E6",
  "#FBA16C",
  "#FC1501",
  "#FCD59C",
  "#FDF8FF",
  "#FEF0DB",
  "#FF0033",
  "#FF00AA",
  "#FF1CAE",
  "#FF3300",
  "#FF3D0D",
  "#FF4500",
  "#FF6103",
  "#FF6666",
  "#FF6EB4",
  "#FF7256",
  "#FF7F00",
  "#FF8000",
  "#FF83FA",
  "#FF8C69",
  "#FF9955",
  "#FFA54F",
  "#FFAA00",
  "#FFB00F",
  "#FFB90F",
  "#FFC125",
  "#FFCC11",
  "#FFD39B",
  "#FFDEAD",
  "#FFE4B5",
  "#FFE600",
  "#FFEC8B",
  "#FFF0F5",
  "#FFF8DC",
  "#FFFAFA",
  "#FFFF7E",
  "#FFFFE0",
  "#EA899A",
  "#231A24",
  "#00857C",
  "#6ECEB2",
  "#69B8F7",
  "#688CE8",
  "#5450E4",
  "#0C2340",
  "#BFED33",
  "#FFF063",
  "#BBCCCF",
  "#FBCDBC",
  "#BBDCBC",
  "#CCCDDF",
  "#BDBFDC",
  "#FFDCFD",
  "#CECEFF",
  "#EBEDDE",
  "#EEDBCF",
  "#EFBCDD",
  "#EDEDEC",
  "#DECBBC",
  "#FBDEDF",
  "#BEBFBD",
  "#BBFBCD",
  "#DFECDB",
  "#EDCCDE",
  "#FCEBFC",
  "#CEEEBB",
  "#BEFEEB",
  "#BECECB",
  "#FCBFEF",
  "#DCFEFB",
  "#DBBCCC",
  "#CBDDEF",
  "#CBCEBD",
  "#DEDEEC",
  "#EFDEBB",
  "#BBDEEF",
  "#EFECBB",
  "#EEBBFD",
  "#FFFCCD",
  "#CBBBBC",
  "#EEDBBE",
  "#CCCFDB",
  "#EECDCE",
  "#DDDFDD",
  "#FCCEBC",
  "#FCCDBD",
  "#DEFCCF",
  "#DCDCDF",
  "#EFDEDD",
  "#EDFCBC",
  "#DEDEEE",
  "#DFCDBB",
  "#EBCCBC",
  "#CEDBCF",
  "#BCCDDE",
  "#BFBEFE",
  "#CEEDDC",
  "#EDFFDB",
  "#CCEFFF",
  "#CEDFBE",
  "#BCCEDC",
  "#DEEDCE",
  "#EEBBCD",
  "#FFEBFE",
  "#EFECFB",
  "#FFEBEE",
  "#EBEEFF",
  "#EBFEEE",
  "#BEFDDB",
  "#ECDBDC",
  "#BDBCDD",
  "#EECEED",
  "#BECDDF",
  "#DEFEBE",
  "#CCDEEF",
  "#DCECEC",
  "#FDEBDB",
  "#BCDDCF",
  "#EDBBCE",
  "#ECFDBC",
  "#DFECBF",
  "#DBDBED",
  "#FDEEDD",
  "#FCFFCD",
  "#DDCCEB",
  "#BCDBCF",
  "#FEBFBE",
  "#EFBBFD",
  "#FBFDBE",
  "#CBDDCB",
  "#DBFFCB",
  "#BDBCFD",
  "#DEEBDE",
  "#DBEFCF",
  "#BEEFFE",
  "#FDDBFB",
  "#EDFFFC",
  "#FCCEEC",
  "#BBECFD",
  "#FDCBDB",
  "#DCBECC",
  "#DCFBDC",
  "#ECBDBC",
  "#DDCBEF",
  "#FBDEEB",
  "#BEBBFD",
  "#FCCBDD",
  "#BFEBEC",
  "#FEFFDB",
  "#CDCFFD",
  "#FFCDFC",
  "#FCFCFC",
  "#FBFBDF",
  "#EEBECF",
  "#FEEFCB",
  "#BCECFE",
  "#FEBCFE",
  "#DBDCFB",
  "#EFFBFD",
  "#CECDED",
  "#BBDEFF",
  "#EDDFFE",
  "#CCFDBF",
  "#BBBDDF",
  "#BBCCCE",
  "#EECEBF",
  "#BEBCEC",
  "#EBFEBB",
  "#DBEEBB",
  "#FEBCBD",
  "#DEDDFD",
  "#EEBCFD",
  "#EDCCCD",
  "#FFBDFB",
  "#FFFFCB",
  "#EFDCBC",
  "#BFEBCD",
  "#FCFFFC",
  "#CEDCCB",
  "#DCBBDD",
  "#DFFFEB",
  "#CEDBDE",
  "#BBEEFD",
  "#FBDDED",
  "#CBFDBE",
  "#EDFFBF",
  "#EBCCEF",
  "#CDEEBB",
  "#BFEBFF",
  "#EBDBFC",
  "#ECDBCD",
  "#BEEFCD",
  "#BECFEB",
  "#CCFEEE",
  "#FEFECD",
  "#DDEEBF",
  "#FDFBDC",
  "#DFDBBF",
  "#DDCBDB",
  "#DEEEBE",
  "#FBCEBB",
  "#FBCBFE",
  "#BDEECC",
  "#BCCFDE",
  "#BBCEFB",
  "#BDBBEF",
  "#CEBBEB",
  "#CFFDBD",
  "#EBDBBF",
  "#BDCCDD",
  "#EBBFED",
  "#CEBBDF",
  "#BBBDCF",
  "#ECBDFD",
  "#BBBCEB",
  "#BFFEED",
  "#CFEDCB",
  "#BCECDC",
  "#ECEDFF",
  "#CFCCBB",
  "#DFDEFC",
  "#EEEEBD",
  "#DEFDBC",
  "#CBFFCD",
  "#FDBEEC",
  "#BFFFCF",
  "#BFFBCD",
  "#DBCBDF",
  "#CBDEDE",
  "#DCEEEE",
  "#CFECED",
  "#CFBFDB",
  "#DFFDFE",
  "#DEBCBD",
  "#DCBBFC",
  "#DCCFBE",
  "#CFEFBD",
  "#DEFBBF",
  "#BDDECE",
  "#BDBECD",
  "#DDBCEE",
  "#FFFBED",
  "#FEBDDC",
  "#CDDFBB",
  "#FFECDB",
  "#CBCDED",
  "#EEEDFC",
  "#EEFCBC",
  "#CDCBCC",
  "#DCBCEC",
  "#EFBEEB",
  "#ECBEFB",
  "#DECFFB",
  "#FBECEC",
  "#EDDEFC",
  "#DFCCCF",
  "#DFCDCD",
  "#BECCCF",
  "#DEDCDE",
  "#CDDFEC",
  "#BFDFDF",
  "#ECDDBE",
  "#ECFBEF",
  "#CEFFDB",
  "#BFDFCE",
  "#CFCFEC",
  "#CFDCEF",
  "#BFBDEF",
  "#DEFBCE",
  "#FFCEFF",
  "#FCDCBB",
  "#FEFDCF",
  "#ECEFDE",
  "#ECCCBE",
  "#DDBDFB",
  "#FFECFD",
  "#EEFCCD",
  "#FDFDBC",
  "#BDEFBF",
  "#BEDCFF",
  "#DEEEDF",
  "#FDBFBF",
  "#FBEEDD",
  "#CDDBDB",
  "#FDCCCE",
  "#ECEEEE",
  "#CDCEBF",
  "#DFCBFB",
  "#CBCDFC",
  "#BDBEBE",
  "#FFBEBE",
  "#DBBDFE",
  "#DCEECE",
  "#CFBDDE",
  "#BEFCDF",
  "#BCEEEC",
  "#CECFBC",
  "#DEFEDC",
  "#FDFCCB",
  "#EDFDCF",
  "#EBFEDB",
  "#EFCCCF",
  "#BCEEBB",
  "#CDFCEE",
  "#FCCFFC",
  "#FEECEF",
  "#FFCFED",
  "#BEDBFD",
  "#ECFBFF",
  "#CDDEDF",
  "#ECFBFB",
  "#BEFFEC",
  "#EDDFBB",
  "#EFDEBD",
  "#CCCBEC",
  "#EEBCBD",
  "#EDCCFD",
  "#BBEDEF",
  "#ECDEBE",
  "#EEFDBF",
  "#EFFEBE",
  "#FDDBDC",
  "#FFFCFE",
  "#DDFFEB",
  "#EEEFBC",
  "#DCEFFB",
  "#CCEEDE",
  "#EFEDDC",
  "#CBFCFF",
  "#FDEECB",
  "#DDFEDE",
  "#DBFFFD",
  "#CECFDF",
  "#FFBFBC",
  "#EFDFFE",
  "#FDBBEC",
  "#DBBCBE",
  "#BBBCCD",
  "#CEBEEC",
  "#BBDEEB",
  "#BFEFEB",
  "#EFCECB",
  "#BBBBFB",
  "#EFFEFF",
  "#DCFFFE",
  "#EEECBC",
  "#ECECDF",
  "#BE8C9B",
  "#9CE813",
  "#E8A6BE",
  "#4129D3",
  "#1104DD",
  "#492243",
  "#58F36F",
  "#DCE2DE",
  "#5E2D79",
  "#5F755E",
  "#603311",
  "#608341",
  "#616161",
  "#629632",
  "#636F57",
  "#63D1F4",
  "#65909A",
  "#6600FF",
  "#6666FF",
  "#668E86",
  "#66CDAA",
  "#67E6EC",
  "#687C97",
  "#688571",
  "#6959CD",
  "#698B22",
  "#699864",
  "#6B238E",
  "#6B8E23",
  "#6D9BF1",
  "#6E8B3D",
  "#6F7285",
  "#708090",
  "#70DBDB",
  "#71C671",
  "#734A12",
  "#73B1B7",
  "#754C78",
  "#75A1D0",
  "#777733",
  "#77896C",
  "#78AB46",
  "#79CDCD",
  "#7A7A7A",
  "#7AC5CD",
  "#7B7922",
  "#7CCD7C",
  "#7D7D7D",
  "#7EB6FF",
  "#7F7F7F",
  "#7FFF00",
  "#800080",
  "#808069",
  "#808A87",
  "#828282",
  "#838B83",
  "#83F52C",
  "#855E42",
  "#859C27",
  "#871F78",
  "#878787",
  "#87CEFF",
  "#8A2BE2",
  "#8A8A8A",
  "#8B008B",
  "#8B1C62",
  "#8B2500",
  "#8B3A62",
  "#8B4513",
  "#8B4789",
  "#8B5A00",
  "#8B636C",
  "#8B6914",
  "#8B7500",
  "#8B7B8B",
  "#8B7E66",
  "#8B8386",
  "#8B8878",
  "#8B8B00",
  "#8BA446",
  "#8C7853",
  "#8DB6CD",
  "#8E236B",
  "#8E8E38",
  "#8FBC8F",
  "#90FEFB",
  "#919191",
  "#9370DB",
  "#949494",
  "#96C8A2",
  "#97FFFF",
  "#98FB98",
  "#9932CC",
  "#999999",
  "#9A32CD",
  "#9AFF9A",
  "#9BCD9B",
  "#9C9C9C",
  "#9CCB19",
  "#9D8851",
  "#9E9E9E",
  "#9F79EE",
  "#A020F0",
  "#A1A1A1",
  "#A2BC13",
  "#A2CD5A",
  "#A46582",
  "#A52A2A",
  "#A67D3D",
  "#A6D785",
  "#A8A8A8",
  "#A9ACB6",
  "#AA5303",
  "#AAAAFF",
  "#ABABAB",
  "#ADD8E6",
  "#AEBB51",
  "#AF4035",
  "#B03060",
  "#B0C4DE",
  "#B13E0F",
  "#B272A6",
  "#B2DFEE",
  "#B3C95A",
  "#B4CDCD",
  "#B5509C",
  "#B62084",
  "#B6AFA9",
  "#B7C8B6",
  "#B8860B",
  "#BA55D3",
  "#BB2A3C",
  "#BC8F8F",
  "#BCED91",
  "#BDB76B",
  "#BE2625",
  "#BF3EFF",
  "#BFEFFF",
  "#C0D9D9",
  "#C1CDCD",
  "#C2C2C2",
  "#C4C4C4",
  "#C65D57",
  "#C6E2FF",
  "#C75D4D",
  "#C77826",
  "#C8F526",
  "#CAE1FF",
  "#CC00FF",
  "#CC3299",
  "#CC7F32",
  "#CCCCCC",
  "#CD0000",
  "#CD2626",
  "#CD3333",
  "#CD5555",
  "#CD6090",
  "#CD6839",
  "#CD7054",
  "#CD8500",
  "#CD919E",
  "#CD9B1D",
  "#CDAB2D",
  "#CDB38B",
  "#CDB7B5",
  "#CDC0B0",
  "#CDC673",
  "#CDC9C9",
  "#CDCDC1",
  "#CDE472",
  "#CFCFCF",
  "#D02090",
  "#D0FAEE",
  "#D1D1D1",
  "#D2691E",
  "#D3D3D3",
  "#D4318C",
  "#D4D4D4",
  "#D66F62",
  "#D8BFD8",
  "#D9D919",
  "#DA70D6",
  "#DB2645",
  "#DB70DB",
  "#DBDB70",
  "#DBFEF8",
  "#DCA2CD",
  "#DDA0DD",
  "#DEDEDE",
  "#E04006",
  "#E0D873",
  "#E0EEE0",
  "#E18E2E",
  "#E3170D",
  "#E33638",
  "#E38217",
  "#E3E3E3",
  "#E5E5E5",
  "#E6E8FA",
  "#E8C782",
  "#E9967A",
  "#EAB5C5",
  "#EBC79E",
  "#ECC3BF",
  "#EDC393",
  "#EE0000",
  "#EE2C2C",
  "#EE3B3B",
  "#EE6363",
  "#EE7600",
  "#EE799F",
  "#EE82EE",
  "#EE9A00",
  "#EEA9B8",
  "#EEB422",
  "#EEC900",
  "#EED2EE",
  "#EED6AF",
  "#EEDD82",
  "#EEE5DE",
  "#EEE8CD",
  "#EEEB8D",
  "#EEEEE0",
  "#F0E68C",
  "#F0FFF0",
  "#F2F2F2",
  "#F4F776",
  "#F5DEB3",
  "#F5FFFA",
  "#F6A8B6",
  "#F7B3DA",
  "#F8F8FF",
  "#FA9A50",
  "#FAFAD2",
  "#FBDB0C",
  "#FCB514",
  "#FCDC3B",
  "#FCFFF0",
  "#FEE5AC",
  "#FEF1B5",
  "#FF0066",
  "#FF00CC",
  "#FF030D",
  "#FF2400",
  "#FF3333",
  "#FF3E96",
  "#FF5333",
  "#FF6347",
  "#FF69B4",
  "#FF6EC7",
  "#FF7722",
  "#FF7F24",
  "#FF8247",
  "#FF8600",
  "#FF92BB",
  "#FFA07A",
  "#FFA812",
  "#FFADB9",
  "#FFB5C5",
  "#FFBBFF",
  "#FFC1C1",
  "#FFCC99",
  "#FFD700",
  "#FFE1FF",
  "#FFE4C4",
  "#FFE7BA",
  "#FFEFD5",
  "#FFF5EE",
  "#FFFACD",
  "#FFFCCF",
  "#FFFFAA",
  "#FFFFF0",
  "#00009C",
  "#0000FF",
  "#003F87",
  "#006400",
  "#006B54",
  "#008080",
  "#008B45",
  "#0099CC",
  "#00B2EE",
  "#00C78C",
  "#00CD66",
  "#00E5EE",
  "#00EEEE",
  "#00FF00",
  "#00FF7F",
  "#00FFFF",
  "#0198E1",
  "#030303",
  "#05B8CC",
  "#068481",
  "#0AC92B",
  "#0D4F8B",
  "#0F0F0F",
  "#108070",
  "#138F6A",
  "#162252",
  "#1874CD",
  "#1B3F8B",
  "#1C86EE",
  "#1E90FF",
  "#20BF9F",
  "#215E21",
  "#228B22",
  "#238E68",
  "#262626",
  "#283A90",
  "#292421",
  "#2B2B2B",
  "#2C5D3F",
  "#2E37FE",
  "#2E6444",
  "#2F4F2F",
  "#2FAA96",
  "#3063A5",
  "#3232CC",
  "#325C74",
  "#3299CC",
  "#32CD99",
  "#333333",
  "#33A1C9",
  "#344152",
  "#35586C",
  "#363636",
  "#37BC61",
  "#383838",
  "#38B0DE",
  "#39B7CD",
  "#3A5FCD",
  "#3B3178",
  "#3B5323",
  "#3B8471",
  "#3D5229",
  "#3D8B37",
  "#3E766D",
  "#3EA055",
  "#3F9E4D",
  "#40E0D0",
  "#422C2F",
  "#42526C",
  "#426F42",
  "#436EEE",
  "#43D58C",
  "#458B00",
  "#46523C",
  "#474747",
  "#484D46",
  "#488214",
  "#4981CE",
  "#49E9BD",
  "#4A708B",
  "#4AC948",
  "#4C7064",
  "#4D4D4D",
  "#4D6FAC",
  "#4DBD33",
  "#4F2F4F",
  "#4F8E83",
  "#50729F",
  "#517693",
  "#525252",
  "#527F76",
  "#539DC2",
  "#548B54",
  "#551033",
  "#555555",
  "#567E3A",
  "#584E56",
  "#5959AB",
  "#597368",
  "#5B59BA",
  "#5C246E",
  "#5C5C5C",
  "#5D7B93",
  "#5E2605",
  "#5E5E5E",
  "#5F9EA0",
  "#607B8B",
  "#60AFFE",
  "#6183A6",
  "#62B1F6",
  "#63AB62",
  "#646F5E",
  "#659D32",
  "#660198",
  "#668014",
  "#66CCCC",
  "#66FF66",
  "#68228B",
  "#687E5A",
  "#691F01",
  "#696969",
  "#698B69",
  "#6A5ACD",
  "#6B4226",
  "#6C7B8B",
  "#6E6E6E",
  "#6EFF70",
  "#707070",
  "#7093DB",
  "#71637D",
  "#72587F",
  "#737373",
  "#748269",
  "#757575",
  "#76EE00",
  "#778899",
  "#787878",
  "#79973F",
  "#7A378B",
  "#7A8B8B",
  "#7B3F00",
  "#7BBF6A",
  "#7CFC00",
  "#7D7F94",
  "#7EC0EE",
  "#7F8778",
  "#7FFFD4",
  "#802A2A",
  "#808080",
  "#816687",
  "#82CFFD",
  "#838B8B",
  "#8470FF",
  "#856363",
  "#862A51",
  "#872657",
  "#87CEEB",
  "#88ACE0",
  "#8A3324",
  "#8AA37B",
  "#8B0A50",
  "#8B2252",
  "#8B3626",
  "#8B3E2F",
  "#8B4726",
  "#8B4C39",
  "#8B5A2B",
  "#8B6508",
  "#8B6969",
  "#8B7765",
  "#8B7D6B",
  "#8B814C",
  "#8B864E",
  "#8B8970",
  "#8B8B7A",
  "#8BA870",
  "#8C8C8C",
  "#8DEEEE",
  "#8E388E",
  "#8EE5EE",
  "#8F8FBC",
  "#8FD8D8",
  "#91219E",
  "#91B49C",
  "#93DB70",
  "#964514",
  "#96CDCD",
  "#98A148",
  "#990099",
  "#9932CD",
  "#99CC32",
  "#9AC0CD",
  "#9B30FF",
  "#9C661F",
  "#9CA998",
  "#9D1309",
  "#9DB68C",
  "#9F5F9F",
  "#9F9F5F",
  "#A02422",
  "#A2627A",
  "#A2C257",
  "#A39480",
  "#A4D3EE",
  "#A5435C",
  "#A68064",
  "#A74CAB",
  "#A9A9A9",
  "#A9C9A4",
  "#AA6600",
  "#AADD00",
  "#AC7F24",
  "#ADEAEA",
  "#AEEEEE",
  "#AFEEEE",
  "#B0A6A4",
  "#B0E0E6",
  "#B22222",
  "#B28647",
  "#B3432B",
  "#B3EE3A",
  "#B4D7BF",
  "#B5A642",
  "#B6316C",
  "#B6C5BE",
  "#B81324",
  "#B8B8B8",
  "#BAAF07",
  "#BBFFFF",
  "#BCD2EE",
  "#BCEE68",
  "#BDBDBD",
  "#BEBEBE",
  "#BF5FFF",
  "#C0C0C0",
  "#C0FF3E",
  "#C1F0F6",
  "#C3E4ED",
  "#C5C1AA",
  "#C67171",
  "#C71585",
  "#C76114",
  "#C7C7C7",
  "#C9AF94",
  "#CAFF70",
  "#CC1100",
  "#CC4E5C",
  "#CC99CC",
  "#CCCCFF",
  "#CD00CD",
  "#CD2990",
  "#CD3700",
  "#CD5B45",
  "#CD6600",
  "#CD6889",
  "#CD7F32",
  "#CD853F",
  "#CD950C",
  "#CD9B9B",
  "#CDAD00",
  "#CDB5CD",
  "#CDBA96",
  "#CDC1C5",
  "#CDC8B1",
  "#CDCD00",
  "#CDCDCD",
  "#CECC15",
  "#CFD784",
  "#D0A9AA",
  "#D15FEE",
  "#D1E231",
  "#D2B48C",
  "#D43D1A",
  "#D4ED91",
  "#D6C537",
  "#D8D8BF",
  "#D9D9D9",
  "#DAA520",
  "#DB2929",
  "#DB9370",
  "#DBDBDB",
  "#DC143C",
  "#DCDCDC",
  "#DE85B1",
  "#DFAE74",
  "#E0427F",
  "#E0DFDB",
  "#E0EEEE",
  "#E2DDB5",
  "#E32636",
  "#E35152",
  "#E3A869",
  "#E47833",
  "#E6B426",
  "#E79EA9",
  "#E8E8E8",
  "#E9C2A6",
  "#EAEAAE",
  "#EBCEAC",
  "#ECC8EC",
  "#EDCB62",
  "#EE00EE",
  "#EE30A7",
  "#EE4000",
  "#EE6A50",
  "#EE7621",
  "#EE7AE9",
  "#EE8833",
  "#EE9A49",
  "#EEAD0E",
  "#EEB4B4",
  "#EECBAD",
  "#EED5B7",
  "#EED8AE",
  "#EEDFCC",
  "#EEE685",
  "#EEE9BF",
  "#EEEE00",
  "#F08080",
  "#F0F0F0",
  "#F0FFFF",
  "#F3E88E",
  "#F54D70",
  "#F5F5DC",
  "#F64D54",
  "#F6C9CC",
  "#F7F7F7",
  "#FA1D2F",
  "#FAEBD7",
  "#FAFAFA",
  "#FBEC5D",
  "#FCD116",
  "#FCE6C9",
  "#FDF5E6",
  "#FEE8D6",
  "#FF0000",
  "#FF007F",
  "#FF00FF",
  "#FF1493",
  "#FF3030",
  "#FF34B3",
  "#FF4040",
  "#FF5721",
  "#FF6600",
  "#FF6A6A",
  "#FF7216",
  "#FF7D40",
  "#FF7F50",
  "#FF82AB",
  "#FF8C00",
  "#FF9912",
  "#FFA500",
  "#FFA824",
  "#FFAEB9",
  "#FFB6C1",
  "#FFC0CB",
  "#FFC469",
  "#FFCCCC",
  "#FFDAB9",
  "#FFE303",
  "#FFE4E1",
  "#FFEBCD",
  "#FFEFDB",
  "#FFF68F",
  "#FFFAF0",
  "#FFFF00",
  "#FFFFCC",
  "#FFFFFF",
  "#0000CD",
  "#003300",
  "#004F00",
  "#006633",
  "#007FFF",
  "#00868B",
  "#008B8B",
  "#009ACD",
  "#00BFFF",
  "#00C957",
  "#00CDCD",
  "#00EE00",
  "#00F5FF",
  "#00FF33",
  "#00FFAA",
  "#01C5BB",
  "#03A89E",
  "#05E9FF",
  "#080808",
  "#0BB5FF",
  "#0E8C3A",
  "#0FDDAF",
  "#120A8F",
  "#141414",
  "#171717",
  "#191970",
  "#1B6453",
  "#1D7CF2",
  "#1F1F1F",
  "#212121",
  "#218868",
  "#23238E",
  "#242424",
  "#26466D",
  "#284942",
  "#292929",
  "#2B4F81",
  "#2E0854",
  "#2E473B",
  "#2E8B57",
  "#302B54",
  "#308014",
  "#3232CD",
  "#327556",
  "#32CC99",
  "#330000",
  "#3333FF",
  "#33A1DE",
  "#34925E",
  "#3579DC",
  "#36648B",
  "#37FDFC",
  "#385E0F",
  "#395D33",
  "#3A3A38",
  "#3A6629",
  "#3B3B3B",
  "#3B5E2B",
  "#3CB371",
  "#3D59AB",
  "#3D9140",
  "#3F602B",
  "#404040",
  "#414F12",
  "#424242",
  "#426352",
  "#42C0FB",
  "#4372AA",
  "#454545",
  "#458B74",
  "#4682B4",
  "#476A34",
  "#487153",
  "#48D1CC",
  "#499DF5",
  "#4A4A4A",
  "#4A766E",
  "#4B0082",
  "#4CB7A5",
  "#4D4DFF",
  "#4D71A3",
  "#4E78A0",
  "#4F4F2F",
  "#4F94CD",
  "#507786",
  "#517B58",
  "#525C65",
  "#528B8B",
  "#543948",
  "#54FF9F",
  "#55141C",
  "#556B2F",
  "#575757",
  "#586949",
  "#596C56",
  "#5993E5",
  "#5B90F6",
  "#5C3317",
  "#5CACEE",
  "#5D92B1",
  "#5E2612",
  "#5EDA9E",
  "#5F9F9F",
  "#607C6E",
  "#615E3F",
  "#61B329",
  "#636363",
  "#63B8FF",
  "#6495ED",
  "#660000",
  "#666666",
  "#668B8B",
  "#66CD00",
  "#67C8FF",
  "#683A5E",
  "#68838B",
  "#694489",
  "#6996AD",
  "#6A8455",
  "#6B6B6B",
  "#6CA6CD",
  "#6E7B8B",
  "#6F4242",
  "#70DB93",
  "#7171C6",
  "#733D1A",
  "#739AC5",
  "#74BBFB",
  "#759B84",
  "#76EEC6",
  "#78A489",
  "#79A888",
  "#7A67EE",
  "#7AA9DD",
  "#7B68EE",
  "#7BCC70",
  "#7D26CD",
  "#7D9EC0",
  "#7F00FF",
  "#7F9A65",
  "#800000",
  "#808000",
  "#820BBB",
  "#836FFF",
  "#838EDE",
  "#84BE6A",
  "#858585",
  "#86C67C",
  "#87421F",
  "#87CEFA",
  "#8968CD",
  "#8A360F",
  "#8B0000",
  "#8B1A1A",
  "#8B2323",
  "#8B3A3A",
  "#8B4500",
  "#8B475D",
  "#8B5742",
  "#8B5F65",
  "#8B668B",
  "#8B7355",
  "#8B795E",
  "#8B7D7B",
  "#8B8378",
  "#8B8682",
  "#8B8989",
  "#8B8B83",
  "#8C1717",
  "#8CDD81",
  "#8E2323",
  "#8E6B23",
  "#8F5E99",
  "#8FA880",
  "#90EE90",
  "#912CEE",
  "#92CCA6",
  "#9400D3",
  "#969696",
  "#97694F",
  "#98F5FF",
  "#99182C",
  "#993300",
  "#99CDC9",
  "#9ACD32",
  "#9BC4E2",
  "#9C6B98",
  "#9CBA7F",
  "#9D6B84",
  "#9E0508",
  "#9F703A",
  "#9FB6CD",
  "#A0522D",
  "#A2B5CD",
  "#A2C93A",
  "#A3A3A3",
  "#A4DCD1",
  "#A62A2A",
  "#A6A6A6",
  "#A78D84",
  "#AA00FF",
  "#AAAAAA",
  "#AB82FF",
  "#ADADAD",
  "#ADFF2F",
  "#AF1E2D",
  "#B0171F",
  "#B0B0B0",
  "#B0E2FF",
  "#B23AEE",
  "#B2D0B4",
  "#B3B3B3",
  "#B452CD",
  "#B4EEB4",
  "#B5B5B5",
  "#B67C3D",
  "#B7C3D0",
  "#B87333",
  "#B9D3EE",
  "#BABABA",
  "#BC7642",
  "#BCE937",
  "#BDA0CB",
  "#BDFCC9",
  "#BEE554",
  "#BFBFBF",
  "#C0D9AF",
  "#C1CDC1",
  "#C1FFC1",
  "#C48E48",
  "#C5E3BF",
  "#C6C3B5",
  "#C73F17",
  "#C76E06",
  "#C82536",
  "#C9C9C9",
  "#CBCAB6",
  "#CC3232",
  "#CC7722",
  "#CCCC00",
  "#CCFFCC",
  "#CD1076",
  "#CD3278",
  "#CD4F39",
  "#CD5C5C",
  "#CD661D",
  "#CD69C9",
  "#CD8162",
  "#CD8C95",
  "#CD96CD",
  "#CDAA7D",
  "#CDAF95",
  "#CDB79E",
  "#CDBE70",
  "#CDC5BF",
  "#CDC9A5",
  "#CDCDB4",
  "#CDD704",
  "#CFB53B",
  "#CFDBC5",
  "#D0D2C4",
  "#D19275",
  "#D1EEEE",
  "#D3BECF",
  "#D41A1F",
  "#D44942",
  "#D5B77A",
  "#D6D6D6",
  "#D98719",
  "#D9D9F3",
  "#DAF4F0",
  "#DB7093",
  "#DB9EA6",
  "#DBE6E0",
  "#DC8909",
  "#DD7500",
  "#DEB887",
  "#DFFFA5",
  "#E066FF",
  "#E0E0E0",
  "#E0FFFF",
  "#E31230",
  "#E32E30",
  "#E3701A",
  "#E3CF57",
  "#E5BC3B",
  "#E6E6FA",
  "#E7C6A5",
  "#E8F1D4",
  "#EAADEA",
  "#EB5E66",
  "#EBEBEB",
  "#ED9121",
  "#EDEDED",
  "#EE1289",
  "#EE3A8C",
  "#EE5C42",
  "#EE6AA7",
  "#EE7942",
  "#EE8262",
  "#EE9572",
  "#EEA2AD",
  "#EEAEEE",
  "#EEC591",
  "#EECFA1",
  "#EED5D2",
  "#EEDC82",
  "#EEE0E5",
  "#EEE8AA",
  "#EEE9E9",
  "#EEEED1",
  "#F0A804",
  "#F0F8FF",
  "#F2473F",
  "#F4A460",
  "#F5785A",
  "#F5F5F5",
  "#F6A4D5",
  "#F6CCDA",
  "#F87531",
  "#FA8072",
  "#FAF0E6",
  "#FBA16C",
  "#FC1501",
  "#FCD59C",
  "#FDF8FF",
  "#FEF0DB",
  "#FF0033",
  "#FF00AA",
  "#FF1CAE",
  "#FF3300",
  "#FF3D0D",
  "#FF4500",
  "#FF6103",
  "#FF6666",
  "#FF6EB4",
  "#FF7256",
  "#FF7F00",
  "#FF8000",
  "#FF83FA",
  "#FF8C69",
  "#FF9955",
  "#FFA54F",
  "#FFAA00",
  "#FFB00F",
  "#FFB90F",
  "#FFC125",
  "#FFCC11",
  "#FFD39B",
  "#FFDEAD",
  "#FFE4B5",
  "#FFE600",
  "#FFEC8B",
  "#FFF0F5",
  "#FFF8DC",
  "#FFFAFA",
  "#FFFF7E",
  "#FFFFE0",
  "#EA899A",
  "#231A24",
];
export const REGIMEN_COLORS = [
  "#00857C",
  "#6ECEB2",
  "#69B8F7",
  "#688CE8",
  "#5450E4",
  "#0C2340",
  "#BFED33",
  "#FFF063",
  "#BBCCCF",
  "#FBCDBC",
  "#BBDCBC",
  "#CCCDDF",
  "#BDBFDC",
  "#FFDCFD",
  "#CECEFF",
  "#EBEDDE",
  "#EEDBCF",
  "#EFBCDD",
  "#EDEDEC",
  "#DECBBC",
  "#FBDEDF",
  "#BEBFBD",
  "#BBFBCD",
  "#DFECDB",
  "#EDCCDE",
  "#FCEBFC",
  "#CEEEBB",
  "#BEFEEB",
  "#BECECB",
  "#FCBFEF",
  "#DCFEFB",
  "#DBBCCC",
  "#CBDDEF",
  "#CBCEBD",
  "#DEDEEC",
  "#EFDEBB",
  "#BBDEEF",
  "#EFECBB",
  "#EEBBFD",
  "#FFFCCD",
  "#CBBBBC",
  "#EEDBBE",
  "#CCCFDB",
  "#EECDCE",
  "#DDDFDD",
  "#FCCEBC",
  "#FCCDBD",
  "#DEFCCF",
  "#DCDCDF",
  "#EFDEDD",
  "#EDFCBC",
  "#DEDEEE",
  "#DFCDBB",
  "#EBCCBC",
  "#CEDBCF",
  "#BCCDDE",
  "#BFBEFE",
  "#CEEDDC",
  "#EDFFDB",
  "#CCEFFF",
  "#CEDFBE",
  "#BCCEDC",
  "#DEEDCE",
  "#EEBBCD",
  "#FFEBFE",
  "#EFECFB",
  "#FFEBEE",
  "#EBEEFF",
  "#EBFEEE",
  "#BEFDDB",
  "#ECDBDC",
  "#BDBCDD",
  "#EECEED",
  "#BECDDF",
  "#DEFEBE",
  "#CCDEEF",
  "#DCECEC",
  "#FDEBDB",
  "#BCDDCF",
  "#EDBBCE",
  "#ECFDBC",
  "#DFECBF",
  "#DBDBED",
  "#FDEEDD",
  "#FCFFCD",
  "#DDCCEB",
  "#BCDBCF",
  "#FEBFBE",
  "#EFBBFD",
  "#FBFDBE",
  "#CBDDCB",
  "#DBFFCB",
  "#BDBCFD",
  "#DEEBDE",
  "#DBEFCF",
  "#BEEFFE",
  "#FDDBFB",
  "#EDFFFC",
  "#FCCEEC",
  "#BBECFD",
  "#FDCBDB",
  "#DCBECC",
  "#DCFBDC",
  "#ECBDBC",
  "#DDCBEF",
  "#FBDEEB",
  "#BEBBFD",
  "#FCCBDD",
  "#BFEBEC",
  "#FEFFDB",
  "#CDCFFD",
  "#FFCDFC",
  "#FCFCFC",
  "#FBFBDF",
  "#EEBECF",
  "#FEEFCB",
  "#BCECFE",
  "#FEBCFE",
  "#DBDCFB",
  "#EFFBFD",
  "#CECDED",
  "#BBDEFF",
  "#EDDFFE",
  "#CCFDBF",
  "#BBBDDF",
  "#BBCCCE",
  "#EECEBF",
  "#BEBCEC",
  "#EBFEBB",
  "#DBEEBB",
  "#FEBCBD",
  "#DEDDFD",
  "#EEBCFD",
  "#EDCCCD",
  "#FFBDFB",
  "#FFFFCB",
  "#EFDCBC",
  "#BFEBCD",
  "#FCFFFC",
  "#CEDCCB",
  "#DCBBDD",
  "#DFFFEB",
  "#CEDBDE",
  "#BBEEFD",
  "#FBDDED",
  "#CBFDBE",
  "#EDFFBF",
  "#EBCCEF",
  "#CDEEBB",
  "#BFEBFF",
  "#EBDBFC",
  "#ECDBCD",
  "#BEEFCD",
  "#BECFEB",
  "#CCFEEE",
  "#FEFECD",
  "#DDEEBF",
  "#FDFBDC",
  "#DFDBBF",
  "#DDCBDB",
  "#DEEEBE",
  "#FBCEBB",
  "#FBCBFE",
  "#BDEECC",
  "#BCCFDE",
  "#BBCEFB",
  "#BDBBEF",
  "#CEBBEB",
  "#CFFDBD",
  "#EBDBBF",
  "#BDCCDD",
  "#EBBFED",
  "#CEBBDF",
  "#BBBDCF",
  "#ECBDFD",
  "#BBBCEB",
  "#BFFEED",
  "#CFEDCB",
  "#BCECDC",
  "#ECEDFF",
  "#CFCCBB",
  "#DFDEFC",
  "#EEEEBD",
  "#DEFDBC",
  "#CBFFCD",
  "#FDBEEC",
  "#BFFFCF",
  "#BFFBCD",
  "#DBCBDF",
  "#CBDEDE",
  "#DCEEEE",
  "#CFECED",
  "#CFBFDB",
  "#DFFDFE",
  "#DEBCBD",
  "#DCBBFC",
  "#DCCFBE",
  "#CFEFBD",
  "#DEFBBF",
  "#BDDECE",
  "#BDBECD",
  "#DDBCEE",
  "#FFFBED",
  "#FEBDDC",
  "#CDDFBB",
  "#FFECDB",
  "#CBCDED",
  "#EEEDFC",
  "#EEFCBC",
  "#CDCBCC",
  "#DCBCEC",
  "#EFBEEB",
  "#ECBEFB",
  "#DECFFB",
  "#FBECEC",
  "#EDDEFC",
  "#DFCCCF",
  "#DFCDCD",
  "#BECCCF",
  "#DEDCDE",
  "#CDDFEC",
  "#BFDFDF",
  "#ECDDBE",
  "#ECFBEF",
  "#CEFFDB",
  "#BFDFCE",
  "#CFCFEC",
  "#CFDCEF",
  "#BFBDEF",
  "#DEFBCE",
  "#FFCEFF",
  "#FCDCBB",
  "#FEFDCF",
  "#ECEFDE",
  "#ECCCBE",
  "#DDBDFB",
  "#FFECFD",
  "#EEFCCD",
  "#FDFDBC",
  "#BDEFBF",
  "#BEDCFF",
  "#DEEEDF",
  "#FDBFBF",
  "#FBEEDD",
  "#CDDBDB",
  "#FDCCCE",
  "#ECEEEE",
  "#CDCEBF",
  "#DFCBFB",
  "#CBCDFC",
  "#BDBEBE",
  "#FFBEBE",
  "#DBBDFE",
  "#DCEECE",
  "#CFBDDE",
  "#BEFCDF",
  "#BCEEEC",
  "#CECFBC",
  "#DEFEDC",
  "#FDFCCB",
  "#EDFDCF",
  "#EBFEDB",
  "#EFCCCF",
  "#BCEEBB",
  "#CDFCEE",
  "#FCCFFC",
  "#FEECEF",
  "#FFCFED",
  "#BEDBFD",
  "#ECFBFF",
  "#CDDEDF",
  "#ECFBFB",
  "#BEFFEC",
  "#EDDFBB",
  "#EFDEBD",
  "#CCCBEC",
  "#EEBCBD",
  "#EDCCFD",
  "#BBEDEF",
  "#ECDEBE",
  "#EEFDBF",
  "#EFFEBE",
  "#FDDBDC",
  "#FFFCFE",
  "#DDFFEB",
  "#EEEFBC",
  "#DCEFFB",
  "#CCEEDE",
  "#EFEDDC",
  "#CBFCFF",
  "#FDEECB",
  "#DDFEDE",
  "#DBFFFD",
  "#CECFDF",
  "#FFBFBC",
  "#EFDFFE",
  "#FDBBEC",
  "#DBBCBE",
  "#BBBCCD",
  "#CEBEEC",
  "#BBDEEB",
  "#BFEFEB",
  "#EFCECB",
  "#BBBBFB",
  "#EFFEFF",
  "#DCFFFE",
  "#EEECBC",
  "#ECECDF",
  "#BE8C9B",
  "#9CE813",
  "#E8A6BE",
  "#4129D3",
  "#1104DD",
  "#492243",
  "#58F36F",
  "#DCE2DE",
  "#5E2D79",
  "#5F755E",
  "#603311",
  "#608341",
  "#616161",
  "#629632",
  "#636F57",
  "#63D1F4",
  "#65909A",
  "#6600FF",
  "#6666FF",
  "#668E86",
  "#66CDAA",
  "#67E6EC",
  "#687C97",
  "#688571",
  "#6959CD",
  "#698B22",
  "#699864",
  "#6B238E",
  "#6B8E23",
  "#6D9BF1",
  "#6E8B3D",
  "#6F7285",
  "#708090",
  "#70DBDB",
  "#71C671",
  "#734A12",
  "#73B1B7",
  "#754C78",
  "#75A1D0",
  "#777733",
  "#77896C",
  "#78AB46",
  "#79CDCD",
  "#7A7A7A",
  "#7AC5CD",
  "#7B7922",
  "#7CCD7C",
  "#7D7D7D",
  "#7EB6FF",
  "#7F7F7F",
  "#7FFF00",
  "#800080",
  "#808069",
  "#808A87",
  "#828282",
  "#838B83",
  "#83F52C",
  "#855E42",
  "#859C27",
  "#871F78",
  "#878787",
  "#87CEFF",
  "#8A2BE2",
  "#8A8A8A",
  "#8B008B",
  "#8B1C62",
  "#8B2500",
  "#8B3A62",
  "#8B4513",
  "#8B4789",
  "#8B5A00",
  "#8B636C",
  "#8B6914",
  "#8B7500",
  "#8B7B8B",
  "#8B7E66",
  "#8B8386",
  "#8B8878",
  "#8B8B00",
  "#8BA446",
  "#8C7853",
  "#8DB6CD",
  "#8E236B",
  "#8E8E38",
  "#8FBC8F",
  "#90FEFB",
  "#919191",
  "#9370DB",
  "#949494",
  "#96C8A2",
  "#97FFFF",
  "#98FB98",
  "#9932CC",
  "#999999",
  "#9A32CD",
  "#9AFF9A",
  "#9BCD9B",
  "#9C9C9C",
  "#9CCB19",
  "#9D8851",
  "#9E9E9E",
  "#9F79EE",
  "#A020F0",
  "#A1A1A1",
  "#A2BC13",
  "#A2CD5A",
  "#A46582",
  "#A52A2A",
  "#A67D3D",
  "#A6D785",
  "#A8A8A8",
  "#A9ACB6",
  "#AA5303",
  "#AAAAFF",
  "#ABABAB",
  "#ADD8E6",
  "#AEBB51",
  "#AF4035",
  "#B03060",
  "#B0C4DE",
  "#B13E0F",
  "#B272A6",
  "#B2DFEE",
  "#B3C95A",
  "#B4CDCD",
  "#B5509C",
  "#B62084",
  "#B6AFA9",
  "#B7C8B6",
  "#B8860B",
  "#BA55D3",
  "#BB2A3C",
  "#BC8F8F",
  "#BCED91",
  "#BDB76B",
  "#BE2625",
  "#BF3EFF",
  "#BFEFFF",
  "#C0D9D9",
  "#C1CDCD",
  "#C2C2C2",
  "#C4C4C4",
  "#C65D57",
  "#C6E2FF",
  "#C75D4D",
  "#C77826",
  "#C8F526",
  "#CAE1FF",
  "#CC00FF",
  "#CC3299",
  "#CC7F32",
  "#CCCCCC",
  "#CD0000",
  "#CD2626",
  "#CD3333",
  "#CD5555",
  "#CD6090",
  "#CD6839",
  "#CD7054",
  "#CD8500",
  "#CD919E",
  "#CD9B1D",
  "#CDAB2D",
  "#CDB38B",
  "#CDB7B5",
  "#CDC0B0",
  "#CDC673",
  "#CDC9C9",
  "#CDCDC1",
  "#CDE472",
  "#CFCFCF",
  "#D02090",
  "#D0FAEE",
  "#D1D1D1",
  "#D2691E",
  "#D3D3D3",
  "#D4318C",
  "#D4D4D4",
  "#D66F62",
  "#D8BFD8",
  "#D9D919",
  "#DA70D6",
  "#DB2645",
  "#DB70DB",
  "#DBDB70",
  "#DBFEF8",
  "#DCA2CD",
  "#DDA0DD",
  "#DEDEDE",
  "#E04006",
  "#E0D873",
  "#E0EEE0",
  "#E18E2E",
  "#E3170D",
  "#E33638",
  "#E38217",
  "#E3E3E3",
  "#E5E5E5",
  "#E6E8FA",
  "#E8C782",
  "#E9967A",
  "#EAB5C5",
  "#EBC79E",
  "#ECC3BF",
  "#EDC393",
  "#EE0000",
  "#EE2C2C",
  "#EE3B3B",
  "#EE6363",
  "#EE7600",
  "#EE799F",
  "#EE82EE",
  "#EE9A00",
  "#EEA9B8",
  "#EEB422",
  "#EEC900",
  "#EED2EE",
  "#EED6AF",
  "#EEDD82",
  "#EEE5DE",
  "#EEE8CD",
  "#EEEB8D",
  "#EEEEE0",
  "#F0E68C",
  "#F0FFF0",
  "#F2F2F2",
  "#F4F776",
  "#F5DEB3",
  "#F5FFFA",
  "#F6A8B6",
  "#F7B3DA",
  "#F8F8FF",
  "#FA9A50",
  "#FAFAD2",
  "#FBDB0C",
  "#FCB514",
  "#FCDC3B",
  "#FCFFF0",
  "#FEE5AC",
  "#FEF1B5",
  "#FF0066",
  "#FF00CC",
  "#FF030D",
  "#FF2400",
  "#FF3333",
  "#FF3E96",
  "#FF5333",
  "#FF6347",
  "#FF69B4",
  "#FF6EC7",
  "#FF7722",
  "#FF7F24",
  "#FF8247",
  "#FF8600",
  "#FF92BB",
  "#FFA07A",
  "#FFA812",
  "#FFADB9",
  "#FFB5C5",
  "#FFBBFF",
  "#FFC1C1",
  "#FFCC99",
  "#FFD700",
  "#FFE1FF",
  "#FFE4C4",
  "#FFE7BA",
  "#FFEFD5",
  "#FFF5EE",
  "#FFFACD",
  "#FFFCCF",
  "#FFFFAA",
  "#FFFFF0",
  "#00009C",
  "#0000FF",
  "#003F87",
  "#006400",
  "#006B54",
  "#008080",
  "#008B45",
  "#0099CC",
  "#00B2EE",
  "#00C78C",
  "#00CD66",
  "#00E5EE",
  "#00EEEE",
  "#00FF00",
  "#00FF7F",
  "#00FFFF",
  "#0198E1",
  "#030303",
  "#05B8CC",
  "#068481",
  "#0AC92B",
  "#0D4F8B",
  "#0F0F0F",
  "#108070",
  "#138F6A",
  "#162252",
  "#1874CD",
  "#1B3F8B",
  "#1C86EE",
  "#1E90FF",
  "#20BF9F",
  "#215E21",
  "#228B22",
  "#238E68",
  "#262626",
  "#283A90",
  "#292421",
  "#2B2B2B",
  "#2C5D3F",
  "#2E37FE",
  "#2E6444",
  "#2F4F2F",
  "#2FAA96",
  "#3063A5",
  "#3232CC",
  "#325C74",
  "#3299CC",
  "#32CD99",
  "#333333",
  "#33A1C9",
  "#344152",
  "#35586C",
  "#363636",
  "#37BC61",
  "#383838",
  "#38B0DE",
  "#39B7CD",
  "#3A5FCD",
  "#3B3178",
  "#3B5323",
  "#3B8471",
  "#3D5229",
  "#3D8B37",
  "#3E766D",
  "#3EA055",
  "#3F9E4D",
  "#40E0D0",
  "#422C2F",
  "#42526C",
  "#426F42",
  "#436EEE",
  "#43D58C",
  "#458B00",
  "#46523C",
  "#474747",
  "#484D46",
  "#488214",
  "#4981CE",
  "#49E9BD",
  "#4A708B",
  "#4AC948",
  "#4C7064",
  "#4D4D4D",
  "#4D6FAC",
  "#4DBD33",
  "#4F2F4F",
  "#4F8E83",
  "#50729F",
  "#517693",
  "#525252",
  "#527F76",
  "#539DC2",
  "#548B54",
  "#551033",
  "#555555",
  "#567E3A",
  "#584E56",
  "#5959AB",
  "#597368",
  "#5B59BA",
  "#5C246E",
  "#5C5C5C",
  "#5D7B93",
  "#5E2605",
  "#5E5E5E",
  "#5F9EA0",
  "#607B8B",
  "#60AFFE",
  "#6183A6",
  "#62B1F6",
  "#63AB62",
  "#646F5E",
  "#659D32",
  "#660198",
  "#668014",
  "#66CCCC",
  "#66FF66",
  "#68228B",
  "#687E5A",
  "#691F01",
  "#696969",
  "#698B69",
  "#6A5ACD",
  "#6B4226",
  "#6C7B8B",
  "#6E6E6E",
  "#6EFF70",
  "#707070",
  "#7093DB",
  "#71637D",
  "#72587F",
  "#737373",
  "#748269",
  "#757575",
  "#76EE00",
  "#778899",
  "#787878",
  "#79973F",
  "#7A378B",
  "#7A8B8B",
  "#7B3F00",
  "#7BBF6A",
  "#7CFC00",
  "#7D7F94",
  "#7EC0EE",
  "#7F8778",
  "#7FFFD4",
  "#802A2A",
  "#808080",
  "#816687",
  "#82CFFD",
  "#838B8B",
  "#8470FF",
  "#856363",
  "#862A51",
  "#872657",
  "#87CEEB",
  "#88ACE0",
  "#8A3324",
  "#8AA37B",
  "#8B0A50",
  "#8B2252",
  "#8B3626",
  "#8B3E2F",
  "#8B4726",
  "#8B4C39",
  "#8B5A2B",
  "#8B6508",
  "#8B6969",
  "#8B7765",
  "#8B7D6B",
  "#8B814C",
  "#8B864E",
  "#8B8970",
  "#8B8B7A",
  "#8BA870",
  "#8C8C8C",
  "#8DEEEE",
  "#8E388E",
  "#8EE5EE",
  "#8F8FBC",
  "#8FD8D8",
  "#91219E",
  "#91B49C",
  "#93DB70",
  "#964514",
  "#96CDCD",
  "#98A148",
  "#990099",
  "#9932CD",
  "#99CC32",
  "#9AC0CD",
  "#9B30FF",
  "#9C661F",
  "#9CA998",
  "#9D1309",
  "#9DB68C",
  "#9F5F9F",
  "#9F9F5F",
  "#A02422",
  "#A2627A",
  "#A2C257",
  "#A39480",
  "#A4D3EE",
  "#A5435C",
  "#A68064",
  "#A74CAB",
  "#A9A9A9",
  "#A9C9A4",
  "#AA6600",
  "#AADD00",
  "#AC7F24",
  "#ADEAEA",
  "#AEEEEE",
  "#AFEEEE",
  "#B0A6A4",
  "#B0E0E6",
  "#B22222",
  "#B28647",
  "#B3432B",
  "#B3EE3A",
  "#B4D7BF",
  "#B5A642",
  "#B6316C",
  "#B6C5BE",
  "#B81324",
  "#B8B8B8",
  "#BAAF07",
  "#BBFFFF",
  "#BCD2EE",
  "#BCEE68",
  "#BDBDBD",
  "#BEBEBE",
  "#BF5FFF",
  "#C0C0C0",
  "#C0FF3E",
  "#C1F0F6",
  "#C3E4ED",
  "#C5C1AA",
  "#C67171",
  "#C71585",
  "#C76114",
  "#C7C7C7",
  "#C9AF94",
  "#CAFF70",
  "#CC1100",
  "#CC4E5C",
  "#CC99CC",
  "#CCCCFF",
  "#CD00CD",
  "#CD2990",
  "#CD3700",
  "#CD5B45",
  "#CD6600",
  "#CD6889",
  "#CD7F32",
  "#CD853F",
  "#CD950C",
  "#CD9B9B",
  "#CDAD00",
  "#CDB5CD",
  "#CDBA96",
  "#CDC1C5",
  "#CDC8B1",
  "#CDCD00",
  "#CDCDCD",
  "#CECC15",
  "#CFD784",
  "#D0A9AA",
  "#D15FEE",
  "#D1E231",
  "#D2B48C",
  "#D43D1A",
  "#D4ED91",
  "#D6C537",
  "#D8D8BF",
  "#D9D9D9",
  "#DAA520",
  "#DB2929",
  "#DB9370",
  "#DBDBDB",
  "#DC143C",
  "#DCDCDC",
  "#DE85B1",
  "#DFAE74",
  "#E0427F",
  "#E0DFDB",
  "#E0EEEE",
  "#E2DDB5",
  "#E32636",
  "#E35152",
  "#E3A869",
  "#E47833",
  "#E6B426",
  "#E79EA9",
  "#E8E8E8",
  "#E9C2A6",
  "#EAEAAE",
  "#EBCEAC",
  "#ECC8EC",
  "#EDCB62",
  "#EE00EE",
  "#EE30A7",
  "#EE4000",
  "#EE6A50",
  "#EE7621",
  "#EE7AE9",
  "#EE8833",
  "#EE9A49",
  "#EEAD0E",
  "#EEB4B4",
  "#EECBAD",
  "#EED5B7",
  "#EED8AE",
  "#EEDFCC",
  "#EEE685",
  "#EEE9BF",
  "#EEEE00",
  "#F08080",
  "#F0F0F0",
  "#F0FFFF",
  "#F3E88E",
  "#F54D70",
  "#F5F5DC",
  "#F64D54",
  "#F6C9CC",
  "#F7F7F7",
  "#FA1D2F",
  "#FAEBD7",
  "#FAFAFA",
  "#FBEC5D",
  "#FCD116",
  "#FCE6C9",
  "#FDF5E6",
  "#FEE8D6",
  "#FF0000",
  "#FF007F",
  "#FF00FF",
  "#FF1493",
  "#FF3030",
  "#FF34B3",
  "#FF4040",
  "#FF5721",
  "#FF6600",
  "#FF6A6A",
  "#FF7216",
  "#FF7D40",
  "#FF7F50",
  "#FF82AB",
  "#FF8C00",
  "#FF9912",
  "#FFA500",
  "#FFA824",
  "#FFAEB9",
  "#FFB6C1",
  "#FFC0CB",
  "#FFC469",
  "#FFCCCC",
  "#FFDAB9",
  "#FFE303",
  "#FFE4E1",
  "#FFEBCD",
  "#FFEFDB",
  "#FFF68F",
  "#FFFAF0",
  "#FFFF00",
  "#FFFFCC",
  "#FFFFFF",
  "#0000CD",
  "#003300",
  "#004F00",
  "#006633",
  "#007FFF",
  "#00868B",
  "#008B8B",
  "#009ACD",
  "#00BFFF",
  "#00C957",
  "#00CDCD",
  "#00EE00",
  "#00F5FF",
  "#00FF33",
  "#00FFAA",
  "#01C5BB",
  "#03A89E",
  "#05E9FF",
  "#080808",
  "#0BB5FF",
  "#0E8C3A",
  "#0FDDAF",
  "#120A8F",
  "#141414",
  "#171717",
  "#191970",
  "#1B6453",
  "#1D7CF2",
  "#1F1F1F",
  "#212121",
  "#218868",
  "#23238E",
  "#242424",
  "#26466D",
  "#284942",
  "#292929",
  "#2B4F81",
  "#2E0854",
  "#2E473B",
  "#2E8B57",
  "#302B54",
  "#308014",
  "#3232CD",
  "#327556",
  "#32CC99",
  "#330000",
  "#3333FF",
  "#33A1DE",
  "#34925E",
  "#3579DC",
  "#36648B",
  "#37FDFC",
  "#385E0F",
  "#395D33",
  "#3A3A38",
  "#3A6629",
  "#3B3B3B",
  "#3B5E2B",
  "#3CB371",
  "#3D59AB",
  "#3D9140",
  "#3F602B",
  "#404040",
  "#414F12",
  "#424242",
  "#426352",
  "#42C0FB",
  "#4372AA",
  "#454545",
  "#458B74",
  "#4682B4",
  "#476A34",
  "#487153",
  "#48D1CC",
  "#499DF5",
  "#4A4A4A",
  "#4A766E",
  "#4B0082",
  "#4CB7A5",
  "#4D4DFF",
  "#4D71A3",
  "#4E78A0",
  "#4F4F2F",
  "#4F94CD",
  "#507786",
  "#517B58",
  "#525C65",
  "#528B8B",
  "#543948",
  "#54FF9F",
  "#55141C",
  "#556B2F",
  "#575757",
  "#586949",
  "#596C56",
  "#5993E5",
  "#5B90F6",
  "#5C3317",
  "#5CACEE",
  "#5D92B1",
  "#5E2612",
  "#5EDA9E",
  "#5F9F9F",
  "#607C6E",
  "#615E3F",
  "#61B329",
  "#636363",
  "#63B8FF",
  "#6495ED",
  "#660000",
  "#666666",
  "#668B8B",
  "#66CD00",
  "#67C8FF",
  "#683A5E",
  "#68838B",
  "#694489",
  "#6996AD",
  "#6A8455",
  "#6B6B6B",
  "#6CA6CD",
  "#6E7B8B",
  "#6F4242",
  "#70DB93",
  "#7171C6",
  "#733D1A",
  "#739AC5",
  "#74BBFB",
  "#759B84",
  "#76EEC6",
  "#78A489",
  "#79A888",
  "#7A67EE",
  "#7AA9DD",
  "#7B68EE",
  "#7BCC70",
  "#7D26CD",
  "#7D9EC0",
  "#7F00FF",
  "#7F9A65",
  "#800000",
  "#808000",
  "#820BBB",
  "#836FFF",
  "#838EDE",
  "#84BE6A",
  "#858585",
  "#86C67C",
  "#87421F",
  "#87CEFA",
  "#8968CD",
  "#8A360F",
  "#8B0000",
  "#8B1A1A",
  "#8B2323",
  "#8B3A3A",
  "#8B4500",
  "#8B475D",
  "#8B5742",
  "#8B5F65",
  "#8B668B",
  "#8B7355",
  "#8B795E",
  "#8B7D7B",
  "#8B8378",
  "#8B8682",
  "#8B8989",
  "#8B8B83",
  "#8C1717",
  "#8CDD81",
  "#8E2323",
  "#8E6B23",
  "#8F5E99",
  "#8FA880",
  "#90EE90",
  "#912CEE",
  "#92CCA6",
  "#9400D3",
  "#969696",
  "#97694F",
  "#98F5FF",
  "#99182C",
  "#993300",
  "#99CDC9",
  "#9ACD32",
  "#9BC4E2",
  "#9C6B98",
  "#9CBA7F",
  "#9D6B84",
  "#9E0508",
  "#9F703A",
  "#9FB6CD",
  "#A0522D",
  "#A2B5CD",
  "#A2C93A",
  "#A3A3A3",
  "#A4DCD1",
  "#A62A2A",
  "#A6A6A6",
  "#A78D84",
  "#AA00FF",
  "#AAAAAA",
  "#AB82FF",
  "#ADADAD",
  "#ADFF2F",
  "#AF1E2D",
  "#B0171F",
  "#B0B0B0",
  "#B0E2FF",
  "#B23AEE",
  "#B2D0B4",
  "#B3B3B3",
  "#B452CD",
  "#B4EEB4",
  "#B5B5B5",
  "#B67C3D",
  "#B7C3D0",
  "#B87333",
  "#B9D3EE",
  "#BABABA",
  "#BC7642",
  "#BCE937",
  "#BDA0CB",
  "#BDFCC9",
  "#BEE554",
  "#BFBFBF",
  "#C0D9AF",
  "#C1CDC1",
  "#C1FFC1",
  "#C48E48",
  "#C5E3BF",
  "#C6C3B5",
  "#C73F17",
  "#C76E06",
  "#C82536",
  "#C9C9C9",
  "#CBCAB6",
  "#CC3232",
  "#CC7722",
  "#CCCC00",
  "#CCFFCC",
  "#CD1076",
  "#CD3278",
  "#CD4F39",
  "#CD5C5C",
  "#CD661D",
  "#CD69C9",
  "#CD8162",
  "#CD8C95",
  "#CD96CD",
  "#CDAA7D",
  "#CDAF95",
  "#CDB79E",
  "#CDBE70",
  "#CDC5BF",
  "#CDC9A5",
  "#CDCDB4",
  "#CDD704",
  "#CFB53B",
  "#CFDBC5",
  "#D0D2C4",
  "#D19275",
  "#D1EEEE",
  "#D3BECF",
  "#D41A1F",
  "#D44942",
  "#D5B77A",
  "#D6D6D6",
  "#D98719",
  "#D9D9F3",
  "#DAF4F0",
  "#DB7093",
  "#DB9EA6",
  "#DBE6E0",
  "#DC8909",
  "#DD7500",
  "#DEB887",
  "#DFFFA5",
  "#E066FF",
  "#E0E0E0",
  "#E0FFFF",
  "#E31230",
  "#E32E30",
  "#E3701A",
  "#E3CF57",
  "#E5BC3B",
  "#E6E6FA",
  "#E7C6A5",
  "#E8F1D4",
  "#EAADEA",
  "#EB5E66",
  "#EBEBEB",
  "#ED9121",
  "#EDEDED",
  "#EE1289",
  "#EE3A8C",
  "#EE5C42",
  "#EE6AA7",
  "#EE7942",
  "#EE8262",
  "#EE9572",
  "#EEA2AD",
  "#EEAEEE",
  "#EEC591",
  "#EECFA1",
  "#EED5D2",
  "#EEDC82",
  "#EEE0E5",
  "#EEE8AA",
  "#EEE9E9",
  "#EEEED1",
  "#F0A804",
  "#F0F8FF",
  "#F2473F",
  "#F4A460",
  "#F5785A",
  "#F5F5F5",
  "#F6A4D5",
  "#F6CCDA",
  "#F87531",
  "#FA8072",
  "#FAF0E6",
  "#FBA16C",
  "#FC1501",
  "#FCD59C",
  "#FDF8FF",
  "#FEF0DB",
  "#FF0033",
  "#FF00AA",
  "#FF1CAE",
  "#FF3300",
  "#FF3D0D",
  "#FF4500",
  "#FF6103",
  "#FF6666",
  "#FF6EB4",
  "#FF7256",
  "#FF7F00",
  "#FF8000",
  "#FF83FA",
  "#FF8C69",
  "#FF9955",
  "#FFA54F",
  "#FFAA00",
  "#FFB00F",
  "#FFB90F",
  "#FFC125",
  "#FFCC11",
  "#FFD39B",
  "#FFDEAD",
  "#FFE4B5",
  "#FFE600",
  "#FFEC8B",
  "#FFF0F5",
  "#FFF8DC",
  "#FFFAFA",
  "#FFFF7E",
  "#FFFFE0",
  "#EA899A",
  "#231A24",
  "#00857C",
  "#6ECEB2",
  "#69B8F7",
  "#688CE8",
  "#5450E4",
  "#0C2340",
  "#BFED33",
  "#FFF063",
  "#BBCCCF",
  "#FBCDBC",
  "#BBDCBC",
  "#CCCDDF",
  "#BDBFDC",
  "#FFDCFD",
  "#CECEFF",
  "#EBEDDE",
  "#EEDBCF",
  "#EFBCDD",
  "#EDEDEC",
  "#DECBBC",
  "#FBDEDF",
  "#BEBFBD",
  "#BBFBCD",
  "#DFECDB",
  "#EDCCDE",
  "#FCEBFC",
  "#CEEEBB",
  "#BEFEEB",
  "#BECECB",
  "#FCBFEF",
  "#DCFEFB",
  "#DBBCCC",
  "#CBDDEF",
  "#CBCEBD",
  "#DEDEEC",
  "#EFDEBB",
  "#BBDEEF",
  "#EFECBB",
  "#EEBBFD",
  "#FFFCCD",
  "#CBBBBC",
  "#EEDBBE",
  "#CCCFDB",
  "#EECDCE",
  "#DDDFDD",
  "#FCCEBC",
  "#FCCDBD",
  "#DEFCCF",
  "#DCDCDF",
  "#EFDEDD",
  "#EDFCBC",
  "#DEDEEE",
  "#DFCDBB",
  "#EBCCBC",
  "#CEDBCF",
  "#BCCDDE",
  "#BFBEFE",
  "#CEEDDC",
  "#EDFFDB",
  "#CCEFFF",
  "#CEDFBE",
  "#BCCEDC",
  "#DEEDCE",
  "#EEBBCD",
  "#FFEBFE",
  "#EFECFB",
  "#FFEBEE",
  "#EBEEFF",
  "#EBFEEE",
  "#BEFDDB",
  "#ECDBDC",
  "#BDBCDD",
  "#EECEED",
  "#BECDDF",
  "#DEFEBE",
  "#CCDEEF",
  "#DCECEC",
  "#FDEBDB",
  "#BCDDCF",
  "#EDBBCE",
  "#ECFDBC",
  "#DFECBF",
  "#DBDBED",
  "#FDEEDD",
  "#FCFFCD",
  "#DDCCEB",
  "#BCDBCF",
  "#FEBFBE",
  "#EFBBFD",
  "#FBFDBE",
  "#CBDDCB",
  "#DBFFCB",
  "#BDBCFD",
  "#DEEBDE",
  "#DBEFCF",
  "#BEEFFE",
  "#FDDBFB",
  "#EDFFFC",
  "#FCCEEC",
  "#BBECFD",
  "#FDCBDB",
  "#DCBECC",
  "#DCFBDC",
  "#ECBDBC",
  "#DDCBEF",
  "#FBDEEB",
  "#BEBBFD",
  "#FCCBDD",
  "#BFEBEC",
  "#FEFFDB",
  "#CDCFFD",
  "#FFCDFC",
  "#FCFCFC",
  "#FBFBDF",
  "#EEBECF",
  "#FEEFCB",
  "#BCECFE",
  "#FEBCFE",
  "#DBDCFB",
  "#EFFBFD",
  "#CECDED",
  "#BBDEFF",
  "#EDDFFE",
  "#CCFDBF",
  "#BBBDDF",
  "#BBCCCE",
  "#EECEBF",
  "#BEBCEC",
  "#EBFEBB",
  "#DBEEBB",
  "#FEBCBD",
  "#DEDDFD",
  "#EEBCFD",
  "#EDCCCD",
  "#FFBDFB",
  "#FFFFCB",
  "#EFDCBC",
  "#BFEBCD",
  "#FCFFFC",
  "#CEDCCB",
  "#DCBBDD",
  "#DFFFEB",
  "#CEDBDE",
  "#BBEEFD",
  "#FBDDED",
  "#CBFDBE",
  "#EDFFBF",
  "#EBCCEF",
  "#CDEEBB",
  "#BFEBFF",
  "#EBDBFC",
  "#ECDBCD",
  "#BEEFCD",
  "#BECFEB",
  "#CCFEEE",
  "#FEFECD",
  "#DDEEBF",
  "#FDFBDC",
  "#DFDBBF",
  "#DDCBDB",
  "#DEEEBE",
  "#FBCEBB",
  "#FBCBFE",
  "#BDEECC",
  "#BCCFDE",
  "#BBCEFB",
  "#BDBBEF",
  "#CEBBEB",
  "#CFFDBD",
  "#EBDBBF",
  "#BDCCDD",
  "#EBBFED",
  "#CEBBDF",
  "#BBBDCF",
  "#ECBDFD",
  "#BBBCEB",
  "#BFFEED",
  "#CFEDCB",
  "#BCECDC",
  "#ECEDFF",
  "#CFCCBB",
  "#DFDEFC",
  "#EEEEBD",
  "#DEFDBC",
  "#CBFFCD",
  "#FDBEEC",
  "#BFFFCF",
  "#BFFBCD",
  "#DBCBDF",
  "#CBDEDE",
  "#DCEEEE",
  "#CFECED",
  "#CFBFDB",
  "#DFFDFE",
  "#DEBCBD",
  "#DCBBFC",
  "#DCCFBE",
  "#CFEFBD",
  "#DEFBBF",
  "#BDDECE",
  "#BDBECD",
  "#DDBCEE",
  "#FFFBED",
  "#FEBDDC",
  "#CDDFBB",
  "#FFECDB",
  "#CBCDED",
  "#EEEDFC",
  "#EEFCBC",
  "#CDCBCC",
  "#DCBCEC",
  "#EFBEEB",
  "#ECBEFB",
  "#DECFFB",
  "#FBECEC",
  "#EDDEFC",
  "#DFCCCF",
  "#DFCDCD",
  "#BECCCF",
  "#DEDCDE",
  "#CDDFEC",
  "#BFDFDF",
  "#ECDDBE",
  "#ECFBEF",
  "#CEFFDB",
  "#BFDFCE",
  "#CFCFEC",
  "#CFDCEF",
  "#BFBDEF",
  "#DEFBCE",
  "#FFCEFF",
  "#FCDCBB",
  "#FEFDCF",
  "#ECEFDE",
  "#ECCCBE",
  "#DDBDFB",
  "#FFECFD",
  "#EEFCCD",
  "#FDFDBC",
  "#BDEFBF",
  "#BEDCFF",
  "#DEEEDF",
  "#FDBFBF",
  "#FBEEDD",
  "#CDDBDB",
  "#FDCCCE",
  "#ECEEEE",
  "#CDCEBF",
  "#DFCBFB",
  "#CBCDFC",
  "#BDBEBE",
  "#FFBEBE",
  "#DBBDFE",
  "#DCEECE",
  "#CFBDDE",
  "#BEFCDF",
  "#BCEEEC",
  "#CECFBC",
  "#DEFEDC",
  "#FDFCCB",
  "#EDFDCF",
  "#EBFEDB",
  "#EFCCCF",
  "#BCEEBB",
  "#CDFCEE",
  "#FCCFFC",
  "#FEECEF",
  "#FFCFED",
  "#BEDBFD",
  "#ECFBFF",
  "#CDDEDF",
  "#ECFBFB",
  "#BEFFEC",
  "#EDDFBB",
  "#EFDEBD",
  "#CCCBEC",
  "#EEBCBD",
  "#EDCCFD",
  "#BBEDEF",
  "#ECDEBE",
  "#EEFDBF",
  "#EFFEBE",
  "#FDDBDC",
  "#FFFCFE",
  "#DDFFEB",
  "#EEEFBC",
  "#DCEFFB",
  "#CCEEDE",
  "#EFEDDC",
  "#CBFCFF",
  "#FDEECB",
  "#DDFEDE",
  "#DBFFFD",
  "#CECFDF",
  "#FFBFBC",
  "#EFDFFE",
  "#FDBBEC",
  "#DBBCBE",
  "#BBBCCD",
  "#CEBEEC",
  "#BBDEEB",
  "#BFEFEB",
  "#EFCECB",
  "#BBBBFB",
  "#EFFEFF",
  "#DCFFFE",
  "#EEECBC",
  "#ECECDF",
  "#BE8C9B",
  "#9CE813",
  "#E8A6BE",
  "#4129D3",
  "#1104DD",
  "#492243",
  "#58F36F",
  "#DCE2DE",
  "#5E2D79",
  "#5F755E",
  "#603311",
  "#608341",
  "#616161",
  "#629632",
  "#636F57",
  "#63D1F4",
  "#65909A",
  "#6600FF",
  "#6666FF",
  "#668E86",
  "#66CDAA",
  "#67E6EC",
  "#687C97",
  "#688571",
  "#6959CD",
  "#698B22",
  "#699864",
  "#6B238E",
  "#6B8E23",
  "#6D9BF1",
  "#6E8B3D",
  "#6F7285",
  "#708090",
  "#70DBDB",
  "#71C671",
  "#734A12",
  "#73B1B7",
  "#754C78",
  "#75A1D0",
  "#777733",
  "#77896C",
  "#78AB46",
  "#79CDCD",
  "#7A7A7A",
  "#7AC5CD",
  "#7B7922",
  "#7CCD7C",
  "#7D7D7D",
  "#7EB6FF",
  "#7F7F7F",
  "#7FFF00",
  "#800080",
  "#808069",
  "#808A87",
  "#828282",
  "#838B83",
  "#83F52C",
  "#855E42",
  "#859C27",
  "#871F78",
  "#878787",
  "#87CEFF",
  "#8A2BE2",
  "#8A8A8A",
  "#8B008B",
  "#8B1C62",
  "#8B2500",
  "#8B3A62",
  "#8B4513",
  "#8B4789",
  "#8B5A00",
  "#8B636C",
  "#8B6914",
  "#8B7500",
  "#8B7B8B",
  "#8B7E66",
  "#8B8386",
  "#8B8878",
  "#8B8B00",
  "#8BA446",
  "#8C7853",
  "#8DB6CD",
  "#8E236B",
  "#8E8E38",
  "#8FBC8F",
  "#90FEFB",
  "#919191",
  "#9370DB",
  "#949494",
  "#96C8A2",
  "#97FFFF",
  "#98FB98",
  "#9932CC",
  "#999999",
  "#9A32CD",
  "#9AFF9A",
  "#9BCD9B",
  "#9C9C9C",
  "#9CCB19",
  "#9D8851",
  "#9E9E9E",
  "#9F79EE",
  "#A020F0",
  "#A1A1A1",
  "#A2BC13",
  "#A2CD5A",
  "#A46582",
  "#A52A2A",
  "#A67D3D",
  "#A6D785",
  "#A8A8A8",
  "#A9ACB6",
  "#AA5303",
  "#AAAAFF",
  "#ABABAB",
  "#ADD8E6",
  "#AEBB51",
  "#AF4035",
  "#B03060",
  "#B0C4DE",
  "#B13E0F",
  "#B272A6",
  "#B2DFEE",
  "#B3C95A",
  "#B4CDCD",
  "#B5509C",
  "#B62084",
  "#B6AFA9",
  "#B7C8B6",
  "#B8860B",
  "#BA55D3",
  "#BB2A3C",
  "#BC8F8F",
  "#BCED91",
  "#BDB76B",
  "#BE2625",
  "#BF3EFF",
  "#BFEFFF",
  "#C0D9D9",
  "#C1CDCD",
  "#C2C2C2",
  "#C4C4C4",
  "#C65D57",
  "#C6E2FF",
  "#C75D4D",
  "#C77826",
  "#C8F526",
  "#CAE1FF",
  "#CC00FF",
  "#CC3299",
  "#CC7F32",
  "#CCCCCC",
  "#CD0000",
  "#CD2626",
  "#CD3333",
  "#CD5555",
  "#CD6090",
  "#CD6839",
  "#CD7054",
  "#CD8500",
  "#CD919E",
  "#CD9B1D",
  "#CDAB2D",
  "#CDB38B",
  "#CDB7B5",
  "#CDC0B0",
  "#CDC673",
  "#CDC9C9",
  "#CDCDC1",
  "#CDE472",
  "#CFCFCF",
  "#D02090",
  "#D0FAEE",
  "#D1D1D1",
  "#D2691E",
  "#D3D3D3",
  "#D4318C",
  "#D4D4D4",
  "#D66F62",
  "#D8BFD8",
  "#D9D919",
  "#DA70D6",
  "#DB2645",
  "#DB70DB",
  "#DBDB70",
  "#DBFEF8",
  "#DCA2CD",
  "#DDA0DD",
  "#DEDEDE",
  "#E04006",
  "#E0D873",
  "#E0EEE0",
  "#E18E2E",
  "#E3170D",
  "#E33638",
  "#E38217",
  "#E3E3E3",
  "#E5E5E5",
  "#E6E8FA",
  "#E8C782",
  "#E9967A",
  "#EAB5C5",
  "#EBC79E",
  "#ECC3BF",
  "#EDC393",
  "#EE0000",
  "#EE2C2C",
  "#EE3B3B",
  "#EE6363",
  "#EE7600",
  "#EE799F",
  "#EE82EE",
  "#EE9A00",
  "#EEA9B8",
  "#EEB422",
  "#EEC900",
  "#EED2EE",
  "#EED6AF",
  "#EEDD82",
  "#EEE5DE",
  "#EEE8CD",
  "#EEEB8D",
  "#EEEEE0",
  "#F0E68C",
  "#F0FFF0",
  "#F2F2F2",
  "#F4F776",
  "#F5DEB3",
  "#F5FFFA",
  "#F6A8B6",
  "#F7B3DA",
  "#F8F8FF",
  "#FA9A50",
  "#FAFAD2",
  "#FBDB0C",
  "#FCB514",
  "#FCDC3B",
  "#FCFFF0",
  "#FEE5AC",
  "#FEF1B5",
  "#FF0066",
  "#FF00CC",
  "#FF030D",
  "#FF2400",
  "#FF3333",
  "#FF3E96",
  "#FF5333",
  "#FF6347",
  "#FF69B4",
  "#FF6EC7",
  "#FF7722",
  "#FF7F24",
  "#FF8247",
  "#FF8600",
  "#FF92BB",
  "#FFA07A",
  "#FFA812",
  "#FFADB9",
  "#FFB5C5",
  "#FFBBFF",
  "#FFC1C1",
  "#FFCC99",
  "#FFD700",
  "#FFE1FF",
  "#FFE4C4",
  "#FFE7BA",
  "#FFEFD5",
  "#FFF5EE",
  "#FFFACD",
  "#FFFCCF",
  "#FFFFAA",
  "#FFFFF0",
  "#00009C",
  "#0000FF",
  "#003F87",
  "#006400",
  "#006B54",
  "#008080",
  "#008B45",
  "#0099CC",
  "#00B2EE",
  "#00C78C",
  "#00CD66",
  "#00E5EE",
  "#00EEEE",
  "#00FF00",
  "#00FF7F",
  "#00FFFF",
  "#0198E1",
  "#030303",
  "#05B8CC",
  "#068481",
  "#0AC92B",
  "#0D4F8B",
  "#0F0F0F",
  "#108070",
  "#138F6A",
  "#162252",
  "#1874CD",
  "#1B3F8B",
  "#1C86EE",
  "#1E90FF",
  "#20BF9F",
  "#215E21",
  "#228B22",
  "#238E68",
  "#262626",
  "#283A90",
  "#292421",
  "#2B2B2B",
  "#2C5D3F",
  "#2E37FE",
  "#2E6444",
  "#2F4F2F",
  "#2FAA96",
  "#3063A5",
  "#3232CC",
  "#325C74",
  "#3299CC",
  "#32CD99",
  "#333333",
  "#33A1C9",
  "#344152",
  "#35586C",
  "#363636",
  "#37BC61",
  "#383838",
  "#38B0DE",
  "#39B7CD",
  "#3A5FCD",
  "#3B3178",
  "#3B5323",
  "#3B8471",
  "#3D5229",
  "#3D8B37",
  "#3E766D",
  "#3EA055",
  "#3F9E4D",
  "#40E0D0",
  "#422C2F",
  "#42526C",
  "#426F42",
  "#436EEE",
  "#43D58C",
  "#458B00",
  "#46523C",
  "#474747",
  "#484D46",
  "#488214",
  "#4981CE",
  "#49E9BD",
  "#4A708B",
  "#4AC948",
  "#4C7064",
  "#4D4D4D",
  "#4D6FAC",
  "#4DBD33",
  "#4F2F4F",
  "#4F8E83",
  "#50729F",
  "#517693",
  "#525252",
  "#527F76",
  "#539DC2",
  "#548B54",
  "#551033",
  "#555555",
  "#567E3A",
  "#584E56",
  "#5959AB",
  "#597368",
  "#5B59BA",
  "#5C246E",
  "#5C5C5C",
  "#5D7B93",
  "#5E2605",
  "#5E5E5E",
  "#5F9EA0",
  "#607B8B",
  "#60AFFE",
  "#6183A6",
  "#62B1F6",
  "#63AB62",
  "#646F5E",
  "#659D32",
  "#660198",
  "#668014",
  "#66CCCC",
  "#66FF66",
  "#68228B",
  "#687E5A",
  "#691F01",
  "#696969",
  "#698B69",
  "#6A5ACD",
  "#6B4226",
  "#6C7B8B",
  "#6E6E6E",
  "#6EFF70",
  "#707070",
  "#7093DB",
  "#71637D",
  "#72587F",
  "#737373",
  "#748269",
  "#757575",
  "#76EE00",
  "#778899",
  "#787878",
  "#79973F",
  "#7A378B",
  "#7A8B8B",
  "#7B3F00",
  "#7BBF6A",
  "#7CFC00",
  "#7D7F94",
  "#7EC0EE",
  "#7F8778",
  "#7FFFD4",
  "#802A2A",
  "#808080",
  "#816687",
  "#82CFFD",
  "#838B8B",
  "#8470FF",
  "#856363",
  "#862A51",
  "#872657",
  "#87CEEB",
  "#88ACE0",
  "#8A3324",
  "#8AA37B",
  "#8B0A50",
  "#8B2252",
  "#8B3626",
  "#8B3E2F",
  "#8B4726",
  "#8B4C39",
  "#8B5A2B",
  "#8B6508",
  "#8B6969",
  "#8B7765",
  "#8B7D6B",
  "#8B814C",
  "#8B864E",
  "#8B8970",
  "#8B8B7A",
  "#8BA870",
  "#8C8C8C",
  "#8DEEEE",
  "#8E388E",
  "#8EE5EE",
  "#8F8FBC",
  "#8FD8D8",
  "#91219E",
  "#91B49C",
  "#93DB70",
  "#964514",
  "#96CDCD",
  "#98A148",
  "#990099",
  "#9932CD",
  "#99CC32",
  "#9AC0CD",
  "#9B30FF",
  "#9C661F",
  "#9CA998",
  "#9D1309",
  "#9DB68C",
  "#9F5F9F",
  "#9F9F5F",
  "#A02422",
  "#A2627A",
  "#A2C257",
  "#A39480",
  "#A4D3EE",
  "#A5435C",
  "#A68064",
  "#A74CAB",
  "#A9A9A9",
  "#A9C9A4",
  "#AA6600",
  "#AADD00",
  "#AC7F24",
  "#ADEAEA",
  "#AEEEEE",
  "#AFEEEE",
  "#B0A6A4",
  "#B0E0E6",
  "#B22222",
  "#B28647",
  "#B3432B",
  "#B3EE3A",
  "#B4D7BF",
  "#B5A642",
  "#B6316C",
  "#B6C5BE",
  "#B81324",
  "#B8B8B8",
  "#BAAF07",
  "#BBFFFF",
  "#BCD2EE",
  "#BCEE68",
  "#BDBDBD",
  "#BEBEBE",
  "#BF5FFF",
  "#C0C0C0",
  "#C0FF3E",
  "#C1F0F6",
  "#C3E4ED",
  "#C5C1AA",
  "#C67171",
  "#C71585",
  "#C76114",
  "#C7C7C7",
  "#C9AF94",
  "#CAFF70",
  "#CC1100",
  "#CC4E5C",
  "#CC99CC",
  "#CCCCFF",
  "#CD00CD",
  "#CD2990",
  "#CD3700",
  "#CD5B45",
  "#CD6600",
  "#CD6889",
  "#CD7F32",
  "#CD853F",
  "#CD950C",
  "#CD9B9B",
  "#CDAD00",
  "#CDB5CD",
  "#CDBA96",
  "#CDC1C5",
  "#CDC8B1",
  "#CDCD00",
  "#CDCDCD",
  "#CECC15",
  "#CFD784",
  "#D0A9AA",
  "#D15FEE",
  "#D1E231",
  "#D2B48C",
  "#D43D1A",
  "#D4ED91",
  "#D6C537",
  "#D8D8BF",
  "#D9D9D9",
  "#DAA520",
  "#DB2929",
  "#DB9370",
  "#DBDBDB",
  "#DC143C",
  "#DCDCDC",
  "#DE85B1",
  "#DFAE74",
  "#E0427F",
  "#E0DFDB",
  "#E0EEEE",
  "#E2DDB5",
  "#E32636",
  "#E35152",
  "#E3A869",
  "#E47833",
  "#E6B426",
  "#E79EA9",
  "#E8E8E8",
  "#E9C2A6",
  "#EAEAAE",
  "#EBCEAC",
  "#ECC8EC",
  "#EDCB62",
  "#EE00EE",
  "#EE30A7",
  "#EE4000",
  "#EE6A50",
  "#EE7621",
  "#EE7AE9",
  "#EE8833",
  "#EE9A49",
  "#EEAD0E",
  "#EEB4B4",
  "#EECBAD",
  "#EED5B7",
  "#EED8AE",
  "#EEDFCC",
  "#EEE685",
  "#EEE9BF",
  "#EEEE00",
  "#F08080",
  "#F0F0F0",
  "#F0FFFF",
  "#F3E88E",
  "#F54D70",
  "#F5F5DC",
  "#F64D54",
  "#F6C9CC",
  "#F7F7F7",
  "#FA1D2F",
  "#FAEBD7",
  "#FAFAFA",
  "#FBEC5D",
  "#FCD116",
  "#FCE6C9",
  "#FDF5E6",
  "#FEE8D6",
  "#FF0000",
  "#FF007F",
  "#FF00FF",
  "#FF1493",
  "#FF3030",
  "#FF34B3",
  "#FF4040",
  "#FF5721",
  "#FF6600",
  "#FF6A6A",
  "#FF7216",
  "#FF7D40",
  "#FF7F50",
  "#FF82AB",
  "#FF8C00",
  "#FF9912",
  "#FFA500",
  "#FFA824",
  "#FFAEB9",
  "#FFB6C1",
  "#FFC0CB",
  "#FFC469",
  "#FFCCCC",
  "#FFDAB9",
  "#FFE303",
  "#FFE4E1",
  "#FFEBCD",
  "#FFEFDB",
  "#FFF68F",
  "#FFFAF0",
  "#FFFF00",
  "#FFFFCC",
  "#FFFFFF",
  "#0000CD",
  "#003300",
  "#004F00",
  "#006633",
  "#007FFF",
  "#00868B",
  "#008B8B",
  "#009ACD",
  "#00BFFF",
  "#00C957",
  "#00CDCD",
  "#00EE00",
  "#00F5FF",
  "#00FF33",
  "#00FFAA",
  "#01C5BB",
  "#03A89E",
  "#05E9FF",
  "#080808",
  "#0BB5FF",
  "#0E8C3A",
  "#0FDDAF",
  "#120A8F",
  "#141414",
  "#171717",
  "#191970",
  "#1B6453",
  "#1D7CF2",
  "#1F1F1F",
  "#212121",
  "#218868",
  "#23238E",
  "#242424",
  "#26466D",
  "#284942",
  "#292929",
  "#2B4F81",
  "#2E0854",
  "#2E473B",
  "#2E8B57",
  "#302B54",
  "#308014",
  "#3232CD",
  "#327556",
  "#32CC99",
  "#330000",
  "#3333FF",
  "#33A1DE",
  "#34925E",
  "#3579DC",
  "#36648B",
  "#37FDFC",
  "#385E0F",
  "#395D33",
  "#3A3A38",
  "#3A6629",
  "#3B3B3B",
  "#3B5E2B",
  "#3CB371",
  "#3D59AB",
  "#3D9140",
  "#3F602B",
  "#404040",
  "#414F12",
  "#424242",
  "#426352",
  "#42C0FB",
  "#4372AA",
  "#454545",
  "#458B74",
  "#4682B4",
  "#476A34",
  "#487153",
  "#48D1CC",
  "#499DF5",
  "#4A4A4A",
  "#4A766E",
  "#4B0082",
  "#4CB7A5",
  "#4D4DFF",
  "#4D71A3",
  "#4E78A0",
  "#4F4F2F",
  "#4F94CD",
  "#507786",
  "#517B58",
  "#525C65",
  "#528B8B",
  "#543948",
  "#54FF9F",
  "#55141C",
  "#556B2F",
  "#575757",
  "#586949",
  "#596C56",
  "#5993E5",
  "#5B90F6",
  "#5C3317",
  "#5CACEE",
  "#5D92B1",
  "#5E2612",
  "#5EDA9E",
  "#5F9F9F",
  "#607C6E",
  "#615E3F",
  "#61B329",
  "#636363",
  "#63B8FF",
  "#6495ED",
  "#660000",
  "#666666",
  "#668B8B",
  "#66CD00",
  "#67C8FF",
  "#683A5E",
  "#68838B",
  "#694489",
  "#6996AD",
  "#6A8455",
  "#6B6B6B",
  "#6CA6CD",
  "#6E7B8B",
  "#6F4242",
  "#70DB93",
  "#7171C6",
  "#733D1A",
  "#739AC5",
  "#74BBFB",
  "#759B84",
  "#76EEC6",
  "#78A489",
  "#79A888",
  "#7A67EE",
  "#7AA9DD",
  "#7B68EE",
  "#7BCC70",
  "#7D26CD",
  "#7D9EC0",
  "#7F00FF",
  "#7F9A65",
  "#800000",
  "#808000",
  "#820BBB",
  "#836FFF",
  "#838EDE",
  "#84BE6A",
  "#858585",
  "#86C67C",
  "#87421F",
  "#87CEFA",
  "#8968CD",
  "#8A360F",
  "#8B0000",
  "#8B1A1A",
  "#8B2323",
  "#8B3A3A",
  "#8B4500",
  "#8B475D",
  "#8B5742",
  "#8B5F65",
  "#8B668B",
  "#8B7355",
  "#8B795E",
  "#8B7D7B",
  "#8B8378",
  "#8B8682",
  "#8B8989",
  "#8B8B83",
  "#8C1717",
  "#8CDD81",
  "#8E2323",
  "#8E6B23",
  "#8F5E99",
  "#8FA880",
  "#90EE90",
  "#912CEE",
  "#92CCA6",
  "#9400D3",
  "#969696",
  "#97694F",
  "#98F5FF",
  "#99182C",
  "#993300",
  "#99CDC9",
  "#9ACD32",
  "#9BC4E2",
  "#9C6B98",
  "#9CBA7F",
  "#9D6B84",
  "#9E0508",
  "#9F703A",
  "#9FB6CD",
  "#A0522D",
  "#A2B5CD",
  "#A2C93A",
  "#A3A3A3",
  "#A4DCD1",
  "#A62A2A",
  "#A6A6A6",
  "#A78D84",
  "#AA00FF",
  "#AAAAAA",
  "#AB82FF",
  "#ADADAD",
  "#ADFF2F",
  "#AF1E2D",
  "#B0171F",
  "#B0B0B0",
  "#B0E2FF",
  "#B23AEE",
  "#B2D0B4",
  "#B3B3B3",
  "#B452CD",
  "#B4EEB4",
  "#B5B5B5",
  "#B67C3D",
  "#B7C3D0",
  "#B87333",
  "#B9D3EE",
  "#BABABA",
  "#BC7642",
  "#BCE937",
  "#BDA0CB",
  "#BDFCC9",
  "#BEE554",
  "#BFBFBF",
  "#C0D9AF",
  "#C1CDC1",
  "#C1FFC1",
  "#C48E48",
  "#C5E3BF",
  "#C6C3B5",
  "#C73F17",
  "#C76E06",
  "#C82536",
  "#C9C9C9",
  "#CBCAB6",
  "#CC3232",
  "#CC7722",
  "#CCCC00",
  "#CCFFCC",
  "#CD1076",
  "#CD3278",
  "#CD4F39",
  "#CD5C5C",
  "#CD661D",
  "#CD69C9",
  "#CD8162",
  "#CD8C95",
  "#CD96CD",
  "#CDAA7D",
  "#CDAF95",
  "#CDB79E",
  "#CDBE70",
  "#CDC5BF",
  "#CDC9A5",
  "#CDCDB4",
  "#CDD704",
  "#CFB53B",
  "#CFDBC5",
  "#D0D2C4",
  "#D19275",
  "#D1EEEE",
  "#D3BECF",
  "#D41A1F",
  "#D44942",
  "#D5B77A",
  "#D6D6D6",
  "#D98719",
  "#D9D9F3",
  "#DAF4F0",
  "#DB7093",
  "#DB9EA6",
  "#DBE6E0",
  "#DC8909",
  "#DD7500",
  "#DEB887",
  "#DFFFA5",
  "#E066FF",
  "#E0E0E0",
  "#E0FFFF",
  "#E31230",
  "#E32E30",
  "#E3701A",
  "#E3CF57",
  "#E5BC3B",
  "#E6E6FA",
  "#E7C6A5",
  "#E8F1D4",
  "#EAADEA",
  "#EB5E66",
  "#EBEBEB",
  "#ED9121",
  "#EDEDED",
  "#EE1289",
  "#EE3A8C",
  "#EE5C42",
  "#EE6AA7",
  "#EE7942",
  "#EE8262",
  "#EE9572",
  "#EEA2AD",
  "#EEAEEE",
  "#EEC591",
  "#EECFA1",
  "#EED5D2",
  "#EEDC82",
  "#EEE0E5",
  "#EEE8AA",
  "#EEE9E9",
  "#EEEED1",
  "#F0A804",
  "#F0F8FF",
  "#F2473F",
  "#F4A460",
  "#F5785A",
  "#F5F5F5",
  "#F6A4D5",
  "#F6CCDA",
  "#F87531",
  "#FA8072",
  "#FAF0E6",
  "#FBA16C",
  "#FC1501",
  "#FCD59C",
  "#FDF8FF",
  "#FEF0DB",
  "#FF0033",
  "#FF00AA",
  "#FF1CAE",
  "#FF3300",
  "#FF3D0D",
  "#FF4500",
  "#FF6103",
  "#FF6666",
  "#FF6EB4",
  "#FF7256",
  "#FF7F00",
  "#FF8000",
  "#FF83FA",
  "#FF8C69",
  "#FF9955",
  "#FFA54F",
  "#FFAA00",
  "#FFB00F",
  "#FFB90F",
  "#FFC125",
  "#FFCC11",
  "#FFD39B",
  "#FFDEAD",
  "#FFE4B5",
  "#FFE600",
  "#FFEC8B",
  "#FFF0F5",
  "#FFF8DC",
  "#FFFAFA",
  "#FFFF7E",
  "#FFFFE0",
  "#EA899A",
  "#231A24",
  "",
  "#00857C",
  "#6ECEB2",
  "#69B8F7",
  "#688CE8",
  "#5450E4",
  "#0C2340",
  "#BFED33",
  "#FFF063",
  "#BBCCCF",
  "#FBCDBC",
  "#BBDCBC",
  "#CCCDDF",
  "#BDBFDC",
  "#FFDCFD",
  "#CECEFF",
  "#EBEDDE",
  "#EEDBCF",
  "#EFBCDD",
  "#EDEDEC",
  "#DECBBC",
  "#FBDEDF",
  "#BEBFBD",
  "#BBFBCD",
  "#DFECDB",
  "#EDCCDE",
  "#FCEBFC",
  "#CEEEBB",
  "#BEFEEB",
  "#BECECB",
  "#FCBFEF",
  "#DCFEFB",
  "#DBBCCC",
  "#CBDDEF",
  "#CBCEBD",
  "#DEDEEC",
  "#EFDEBB",
  "#BBDEEF",
  "#EFECBB",
  "#EEBBFD",
  "#FFFCCD",
  "#CBBBBC",
  "#EEDBBE",
  "#CCCFDB",
  "#EECDCE",
  "#DDDFDD",
  "#FCCEBC",
  "#FCCDBD",
  "#DEFCCF",
  "#DCDCDF",
  "#EFDEDD",
  "#EDFCBC",
  "#DEDEEE",
  "#DFCDBB",
  "#EBCCBC",
  "#CEDBCF",
  "#BCCDDE",
  "#BFBEFE",
  "#CEEDDC",
  "#EDFFDB",
  "#CCEFFF",
  "#CEDFBE",
  "#BCCEDC",
  "#DEEDCE",
  "#EEBBCD",
  "#FFEBFE",
  "#EFECFB",
  "#FFEBEE",
  "#EBEEFF",
  "#EBFEEE",
  "#BEFDDB",
  "#ECDBDC",
  "#BDBCDD",
  "#EECEED",
  "#BECDDF",
  "#DEFEBE",
  "#CCDEEF",
  "#DCECEC",
  "#FDEBDB",
  "#BCDDCF",
  "#EDBBCE",
  "#ECFDBC",
  "#DFECBF",
  "#DBDBED",
  "#FDEEDD",
  "#FCFFCD",
  "#DDCCEB",
  "#BCDBCF",
  "#FEBFBE",
  "#EFBBFD",
  "#FBFDBE",
  "#CBDDCB",
  "#DBFFCB",
  "#BDBCFD",
  "#DEEBDE",
  "#DBEFCF",
  "#BEEFFE",
  "#FDDBFB",
  "#EDFFFC",
  "#FCCEEC",
  "#BBECFD",
  "#FDCBDB",
  "#DCBECC",
  "#DCFBDC",
  "#ECBDBC",
  "#DDCBEF",
  "#FBDEEB",
  "#BEBBFD",
  "#FCCBDD",
  "#BFEBEC",
  "#FEFFDB",
  "#CDCFFD",
  "#FFCDFC",
  "#FCFCFC",
  "#FBFBDF",
  "#EEBECF",
  "#FEEFCB",
  "#BCECFE",
  "#FEBCFE",
  "#DBDCFB",
  "#EFFBFD",
  "#CECDED",
  "#BBDEFF",
  "#EDDFFE",
  "#CCFDBF",
  "#BBBDDF",
  "#BBCCCE",
  "#EECEBF",
  "#BEBCEC",
  "#EBFEBB",
  "#DBEEBB",
  "#FEBCBD",
  "#DEDDFD",
  "#EEBCFD",
  "#EDCCCD",
  "#FFBDFB",
  "#FFFFCB",
  "#EFDCBC",
  "#BFEBCD",
  "#FCFFFC",
  "#CEDCCB",
  "#DCBBDD",
  "#DFFFEB",
  "#CEDBDE",
  "#BBEEFD",
  "#FBDDED",
  "#CBFDBE",
  "#EDFFBF",
  "#EBCCEF",
  "#CDEEBB",
  "#BFEBFF",
  "#EBDBFC",
  "#ECDBCD",
  "#BEEFCD",
  "#BECFEB",
  "#CCFEEE",
  "#FEFECD",
  "#DDEEBF",
  "#FDFBDC",
  "#DFDBBF",
  "#DDCBDB",
  "#DEEEBE",
  "#FBCEBB",
  "#FBCBFE",
  "#BDEECC",
  "#BCCFDE",
  "#BBCEFB",
  "#BDBBEF",
  "#CEBBEB",
  "#CFFDBD",
  "#EBDBBF",
  "#BDCCDD",
  "#EBBFED",
  "#CEBBDF",
  "#BBBDCF",
  "#ECBDFD",
  "#BBBCEB",
  "#BFFEED",
  "#CFEDCB",
  "#BCECDC",
  "#ECEDFF",
  "#CFCCBB",
  "#DFDEFC",
  "#EEEEBD",
  "#DEFDBC",
  "#CBFFCD",
  "#FDBEEC",
  "#BFFFCF",
  "#BFFBCD",
  "#DBCBDF",
  "#CBDEDE",
  "#DCEEEE",
  "#CFECED",
  "#CFBFDB",
  "#DFFDFE",
  "#DEBCBD",
  "#DCBBFC",
  "#DCCFBE",
  "#CFEFBD",
  "#DEFBBF",
  "#BDDECE",
  "#BDBECD",
  "#DDBCEE",
  "#FFFBED",
  "#FEBDDC",
  "#CDDFBB",
  "#FFECDB",
  "#CBCDED",
  "#EEEDFC",
  "#EEFCBC",
  "#CDCBCC",
  "#DCBCEC",
  "#EFBEEB",
  "#ECBEFB",
  "#DECFFB",
  "#FBECEC",
  "#EDDEFC",
  "#DFCCCF",
  "#DFCDCD",
  "#BECCCF",
  "#DEDCDE",
  "#CDDFEC",
  "#BFDFDF",
  "#ECDDBE",
  "#ECFBEF",
  "#CEFFDB",
  "#BFDFCE",
  "#CFCFEC",
  "#CFDCEF",
  "#BFBDEF",
  "#DEFBCE",
  "#FFCEFF",
  "#FCDCBB",
  "#FEFDCF",
  "#ECEFDE",
  "#ECCCBE",
  "#DDBDFB",
  "#FFECFD",
  "#EEFCCD",
  "#FDFDBC",
  "#BDEFBF",
  "#BEDCFF",
  "#DEEEDF",
  "#FDBFBF",
  "#FBEEDD",
  "#CDDBDB",
  "#FDCCCE",
  "#ECEEEE",
  "#CDCEBF",
  "#DFCBFB",
  "#CBCDFC",
  "#BDBEBE",
  "#FFBEBE",
  "#DBBDFE",
  "#DCEECE",
  "#CFBDDE",
  "#BEFCDF",
  "#BCEEEC",
  "#CECFBC",
  "#DEFEDC",
  "#FDFCCB",
  "#EDFDCF",
  "#EBFEDB",
  "#EFCCCF",
  "#BCEEBB",
  "#CDFCEE",
  "#FCCFFC",
  "#FEECEF",
  "#FFCFED",
  "#BEDBFD",
  "#ECFBFF",
  "#CDDEDF",
  "#ECFBFB",
  "#BEFFEC",
  "#EDDFBB",
  "#EFDEBD",
  "#CCCBEC",
  "#EEBCBD",
  "#EDCCFD",
  "#BBEDEF",
  "#ECDEBE",
  "#EEFDBF",
  "#EFFEBE",
  "#FDDBDC",
  "#FFFCFE",
  "#DDFFEB",
  "#EEEFBC",
  "#DCEFFB",
  "#CCEEDE",
  "#EFEDDC",
  "#CBFCFF",
  "#FDEECB",
  "#DDFEDE",
  "#DBFFFD",
  "#CECFDF",
  "#FFBFBC",
  "#EFDFFE",
  "#FDBBEC",
  "#DBBCBE",
  "#BBBCCD",
  "#CEBEEC",
  "#BBDEEB",
  "#BFEFEB",
  "#EFCECB",
  "#BBBBFB",
  "#EFFEFF",
  "#DCFFFE",
  "#EEECBC",
  "#ECECDF",
  "#BE8C9B",
  "#9CE813",
  "#E8A6BE",
  "#4129D3",
  "#1104DD",
  "#492243",
  "#58F36F",
  "#DCE2DE",
  "#5E2D79",
  "#5F755E",
  "#603311",
  "#608341",
  "#616161",
  "#629632",
  "#636F57",
  "#63D1F4",
  "#65909A",
  "#6600FF",
  "#6666FF",
  "#668E86",
  "#66CDAA",
  "#67E6EC",
  "#687C97",
  "#688571",
  "#6959CD",
  "#698B22",
  "#699864",
  "#6B238E",
  "#6B8E23",
  "#6D9BF1",
  "#6E8B3D",
  "#6F7285",
  "#708090",
  "#70DBDB",
  "#71C671",
  "#734A12",
  "#73B1B7",
  "#754C78",
  "#75A1D0",
  "#777733",
  "#77896C",
  "#78AB46",
  "#79CDCD",
  "#7A7A7A",
  "#7AC5CD",
  "#7B7922",
  "#7CCD7C",
  "#7D7D7D",
  "#7EB6FF",
  "#7F7F7F",
  "#7FFF00",
  "#800080",
  "#808069",
  "#808A87",
  "#828282",
  "#838B83",
  "#83F52C",
  "#855E42",
  "#859C27",
  "#871F78",
  "#878787",
  "#87CEFF",
  "#8A2BE2",
  "#8A8A8A",
  "#8B008B",
  "#8B1C62",
  "#8B2500",
  "#8B3A62",
  "#8B4513",
  "#8B4789",
  "#8B5A00",
  "#8B636C",
  "#8B6914",
  "#8B7500",
  "#8B7B8B",
  "#8B7E66",
  "#8B8386",
  "#8B8878",
  "#8B8B00",
  "#8BA446",
  "#8C7853",
  "#8DB6CD",
  "#8E236B",
  "#8E8E38",
  "#8FBC8F",
  "#90FEFB",
  "#919191",
  "#9370DB",
  "#949494",
  "#96C8A2",
  "#97FFFF",
  "#98FB98",
  "#9932CC",
  "#999999",
  "#9A32CD",
  "#9AFF9A",
  "#9BCD9B",
  "#9C9C9C",
  "#9CCB19",
  "#9D8851",
  "#9E9E9E",
  "#9F79EE",
  "#A020F0",
  "#A1A1A1",
  "#A2BC13",
  "#A2CD5A",
  "#A46582",
  "#A52A2A",
  "#A67D3D",
  "#A6D785",
  "#A8A8A8",
  "#A9ACB6",
  "#AA5303",
  "#AAAAFF",
  "#ABABAB",
  "#ADD8E6",
  "#AEBB51",
  "#AF4035",
  "#B03060",
  "#B0C4DE",
  "#B13E0F",
  "#B272A6",
  "#B2DFEE",
  "#B3C95A",
  "#B4CDCD",
  "#B5509C",
  "#B62084",
  "#B6AFA9",
  "#B7C8B6",
  "#B8860B",
  "#BA55D3",
  "#BB2A3C",
  "#BC8F8F",
  "#BCED91",
  "#BDB76B",
  "#BE2625",
  "#BF3EFF",
  "#BFEFFF",
  "#C0D9D9",
  "#C1CDCD",
  "#C2C2C2",
  "#C4C4C4",
  "#C65D57",
  "#C6E2FF",
  "#C75D4D",
  "#C77826",
  "#C8F526",
  "#CAE1FF",
  "#CC00FF",
  "#CC3299",
  "#CC7F32",
  "#CCCCCC",
  "#CD0000",
  "#CD2626",
  "#CD3333",
  "#CD5555",
  "#CD6090",
  "#CD6839",
  "#CD7054",
  "#CD8500",
  "#CD919E",
  "#CD9B1D",
  "#CDAB2D",
  "#CDB38B",
  "#CDB7B5",
  "#CDC0B0",
  "#CDC673",
  "#CDC9C9",
  "#CDCDC1",
  "#CDE472",
  "#CFCFCF",
  "#D02090",
  "#D0FAEE",
  "#D1D1D1",
  "#D2691E",
  "#D3D3D3",
  "#D4318C",
  "#D4D4D4",
  "#D66F62",
  "#D8BFD8",
  "#D9D919",
  "#DA70D6",
  "#DB2645",
  "#DB70DB",
  "#DBDB70",
  "#DBFEF8",
  "#DCA2CD",
  "#DDA0DD",
  "#DEDEDE",
  "#E04006",
  "#E0D873",
  "#E0EEE0",
  "#E18E2E",
  "#E3170D",
  "#E33638",
  "#E38217",
  "#E3E3E3",
  "#E5E5E5",
  "#E6E8FA",
  "#E8C782",
  "#E9967A",
  "#EAB5C5",
  "#EBC79E",
  "#ECC3BF",
  "#EDC393",
  "#EE0000",
  "#EE2C2C",
  "#EE3B3B",
  "#EE6363",
  "#EE7600",
  "#EE799F",
  "#EE82EE",
  "#EE9A00",
  "#EEA9B8",
  "#EEB422",
  "#EEC900",
  "#EED2EE",
  "#EED6AF",
  "#EEDD82",
  "#EEE5DE",
  "#EEE8CD",
  "#EEEB8D",
  "#EEEEE0",
  "#F0E68C",
  "#F0FFF0",
  "#F2F2F2",
  "#F4F776",
  "#F5DEB3",
  "#F5FFFA",
  "#F6A8B6",
  "#F7B3DA",
  "#F8F8FF",
  "#FA9A50",
  "#FAFAD2",
  "#FBDB0C",
  "#FCB514",
  "#FCDC3B",
  "#FCFFF0",
  "#FEE5AC",
  "#FEF1B5",
  "#FF0066",
  "#FF00CC",
  "#FF030D",
  "#FF2400",
  "#FF3333",
  "#FF3E96",
  "#FF5333",
  "#FF6347",
  "#FF69B4",
  "#FF6EC7",
  "#FF7722",
  "#FF7F24",
  "#FF8247",
  "#FF8600",
  "#FF92BB",
  "#FFA07A",
  "#FFA812",
  "#FFADB9",
  "#FFB5C5",
  "#FFBBFF",
  "#FFC1C1",
  "#FFCC99",
  "#FFD700",
  "#FFE1FF",
  "#FFE4C4",
  "#FFE7BA",
  "#FFEFD5",
  "#FFF5EE",
  "#FFFACD",
  "#FFFCCF",
  "#FFFFAA",
  "#FFFFF0",
  "#00009C",
  "#0000FF",
  "#003F87",
  "#006400",
  "#006B54",
  "#008080",
  "#008B45",
  "#0099CC",
  "#00B2EE",
  "#00C78C",
  "#00CD66",
  "#00E5EE",
  "#00EEEE",
  "#00FF00",
  "#00FF7F",
  "#00FFFF",
  "#0198E1",
  "#030303",
  "#05B8CC",
  "#068481",
  "#0AC92B",
  "#0D4F8B",
  "#0F0F0F",
  "#108070",
  "#138F6A",
  "#162252",
  "#1874CD",
  "#1B3F8B",
  "#1C86EE",
  "#1E90FF",
  "#20BF9F",
  "#215E21",
  "#228B22",
  "#238E68",
  "#262626",
  "#283A90",
  "#292421",
  "#2B2B2B",
  "#2C5D3F",
  "#2E37FE",
  "#2E6444",
  "#2F4F2F",
  "#2FAA96",
  "#3063A5",
  "#3232CC",
  "#325C74",
  "#3299CC",
  "#32CD99",
  "#333333",
  "#33A1C9",
  "#344152",
  "#35586C",
  "#363636",
  "#37BC61",
  "#383838",
  "#38B0DE",
  "#39B7CD",
  "#3A5FCD",
  "#3B3178",
  "#3B5323",
  "#3B8471",
  "#3D5229",
  "#3D8B37",
  "#3E766D",
  "#3EA055",
  "#3F9E4D",
  "#40E0D0",
  "#422C2F",
  "#42526C",
  "#426F42",
  "#436EEE",
  "#43D58C",
  "#458B00",
  "#46523C",
  "#474747",
  "#484D46",
  "#488214",
  "#4981CE",
  "#49E9BD",
  "#4A708B",
  "#4AC948",
  "#4C7064",
  "#4D4D4D",
  "#4D6FAC",
  "#4DBD33",
  "#4F2F4F",
  "#4F8E83",
  "#50729F",
  "#517693",
  "#525252",
  "#527F76",
  "#539DC2",
  "#548B54",
  "#551033",
  "#555555",
  "#567E3A",
  "#584E56",
  "#5959AB",
  "#597368",
  "#5B59BA",
  "#5C246E",
  "#5C5C5C",
  "#5D7B93",
  "#5E2605",
  "#5E5E5E",
  "#5F9EA0",
  "#607B8B",
  "#60AFFE",
  "#6183A6",
  "#62B1F6",
  "#63AB62",
  "#646F5E",
  "#659D32",
  "#660198",
  "#668014",
  "#66CCCC",
  "#66FF66",
  "#68228B",
  "#687E5A",
  "#691F01",
  "#696969",
  "#698B69",
  "#6A5ACD",
  "#6B4226",
  "#6C7B8B",
  "#6E6E6E",
  "#6EFF70",
  "#707070",
  "#7093DB",
  "#71637D",
  "#72587F",
  "#737373",
  "#748269",
  "#757575",
  "#76EE00",
  "#778899",
  "#787878",
  "#79973F",
  "#7A378B",
  "#7A8B8B",
  "#7B3F00",
  "#7BBF6A",
  "#7CFC00",
  "#7D7F94",
  "#7EC0EE",
  "#7F8778",
  "#7FFFD4",
  "#802A2A",
  "#808080",
  "#816687",
  "#82CFFD",
  "#838B8B",
  "#8470FF",
  "#856363",
  "#862A51",
  "#872657",
  "#87CEEB",
  "#88ACE0",
  "#8A3324",
  "#8AA37B",
  "#8B0A50",
  "#8B2252",
  "#8B3626",
  "#8B3E2F",
  "#8B4726",
  "#8B4C39",
  "#8B5A2B",
  "#8B6508",
  "#8B6969",
  "#8B7765",
  "#8B7D6B",
  "#8B814C",
  "#8B864E",
  "#8B8970",
  "#8B8B7A",
  "#8BA870",
  "#8C8C8C",
  "#8DEEEE",
  "#8E388E",
  "#8EE5EE",
  "#8F8FBC",
  "#8FD8D8",
  "#91219E",
  "#91B49C",
  "#93DB70",
  "#964514",
  "#96CDCD",
  "#98A148",
  "#990099",
  "#9932CD",
  "#99CC32",
  "#9AC0CD",
  "#9B30FF",
  "#9C661F",
  "#9CA998",
  "#9D1309",
  "#9DB68C",
  "#9F5F9F",
  "#9F9F5F",
  "#A02422",
  "#A2627A",
  "#A2C257",
  "#A39480",
  "#A4D3EE",
  "#A5435C",
  "#A68064",
  "#A74CAB",
  "#A9A9A9",
  "#A9C9A4",
  "#AA6600",
  "#AADD00",
  "#AC7F24",
  "#ADEAEA",
  "#AEEEEE",
  "#AFEEEE",
  "#B0A6A4",
  "#B0E0E6",
  "#B22222",
  "#B28647",
  "#B3432B",
  "#B3EE3A",
  "#B4D7BF",
  "#B5A642",
  "#B6316C",
  "#B6C5BE",
  "#B81324",
  "#B8B8B8",
  "#BAAF07",
  "#BBFFFF",
  "#BCD2EE",
  "#BCEE68",
  "#BDBDBD",
  "#BEBEBE",
  "#BF5FFF",
  "#C0C0C0",
  "#C0FF3E",
  "#C1F0F6",
  "#C3E4ED",
  "#C5C1AA",
  "#C67171",
  "#C71585",
  "#C76114",
  "#C7C7C7",
  "#C9AF94",
  "#CAFF70",
  "#CC1100",
  "#CC4E5C",
  "#CC99CC",
  "#CCCCFF",
  "#CD00CD",
  "#CD2990",
  "#CD3700",
  "#CD5B45",
  "#CD6600",
  "#CD6889",
  "#CD7F32",
  "#CD853F",
  "#CD950C",
  "#CD9B9B",
  "#CDAD00",
  "#CDB5CD",
  "#CDBA96",
  "#CDC1C5",
  "#CDC8B1",
  "#CDCD00",
  "#CDCDCD",
  "#CECC15",
  "#CFD784",
  "#D0A9AA",
  "#D15FEE",
  "#D1E231",
  "#D2B48C",
  "#D43D1A",
  "#D4ED91",
  "#D6C537",
  "#D8D8BF",
  "#D9D9D9",
  "#DAA520",
  "#DB2929",
  "#DB9370",
  "#DBDBDB",
  "#DC143C",
  "#DCDCDC",
  "#DE85B1",
  "#DFAE74",
  "#E0427F",
  "#E0DFDB",
  "#E0EEEE",
  "#E2DDB5",
  "#E32636",
  "#E35152",
  "#E3A869",
  "#E47833",
  "#E6B426",
  "#E79EA9",
  "#E8E8E8",
  "#E9C2A6",
  "#EAEAAE",
  "#EBCEAC",
  "#ECC8EC",
  "#EDCB62",
  "#EE00EE",
  "#EE30A7",
  "#EE4000",
  "#EE6A50",
  "#EE7621",
  "#EE7AE9",
  "#EE8833",
  "#EE9A49",
  "#EEAD0E",
  "#EEB4B4",
  "#EECBAD",
  "#EED5B7",
  "#EED8AE",
  "#EEDFCC",
  "#EEE685",
  "#EEE9BF",
  "#EEEE00",
  "#F08080",
  "#F0F0F0",
  "#F0FFFF",
  "#F3E88E",
  "#F54D70",
  "#F5F5DC",
  "#F64D54",
  "#F6C9CC",
  "#F7F7F7",
  "#FA1D2F",
  "#FAEBD7",
  "#FAFAFA",
  "#FBEC5D",
  "#FCD116",
  "#FCE6C9",
  "#FDF5E6",
  "#FEE8D6",
  "#FF0000",
  "#FF007F",
  "#FF00FF",
  "#FF1493",
  "#FF3030",
  "#FF34B3",
  "#FF4040",
  "#FF5721",
  "#FF6600",
  "#FF6A6A",
  "#FF7216",
  "#FF7D40",
  "#FF7F50",
  "#FF82AB",
  "#FF8C00",
  "#FF9912",
  "#FFA500",
  "#FFA824",
  "#FFAEB9",
  "#FFB6C1",
  "#FFC0CB",
  "#FFC469",
  "#FFCCCC",
  "#FFDAB9",
  "#FFE303",
  "#FFE4E1",
  "#FFEBCD",
  "#FFEFDB",
  "#FFF68F",
  "#FFFAF0",
  "#FFFF00",
  "#FFFFCC",
  "#FFFFFF",
  "#0000CD",
  "#003300",
  "#004F00",
  "#006633",
  "#007FFF",
  "#00868B",
  "#008B8B",
  "#009ACD",
  "#00BFFF",
  "#00C957",
  "#00CDCD",
  "#00EE00",
  "#00F5FF",
  "#00FF33",
  "#00FFAA",
  "#01C5BB",
  "#03A89E",
  "#05E9FF",
  "#080808",
  "#0BB5FF",
  "#0E8C3A",
  "#0FDDAF",
  "#120A8F",
  "#141414",
  "#171717",
  "#191970",
  "#1B6453",
  "#1D7CF2",
  "#1F1F1F",
  "#212121",
  "#218868",
  "#23238E",
  "#242424",
  "#26466D",
  "#284942",
  "#292929",
  "#2B4F81",
  "#2E0854",
  "#2E473B",
  "#2E8B57",
  "#302B54",
  "#308014",
  "#3232CD",
  "#327556",
  "#32CC99",
  "#330000",
  "#3333FF",
  "#33A1DE",
  "#34925E",
  "#3579DC",
  "#36648B",
  "#37FDFC",
  "#385E0F",
  "#395D33",
  "#3A3A38",
  "#3A6629",
  "#3B3B3B",
  "#3B5E2B",
  "#3CB371",
  "#3D59AB",
  "#3D9140",
  "#3F602B",
  "#404040",
  "#414F12",
  "#424242",
  "#426352",
  "#42C0FB",
  "#4372AA",
  "#454545",
  "#458B74",
  "#4682B4",
  "#476A34",
  "#487153",
  "#48D1CC",
  "#499DF5",
  "#4A4A4A",
  "#4A766E",
  "#4B0082",
  "#4CB7A5",
  "#4D4DFF",
  "#4D71A3",
  "#4E78A0",
  "#4F4F2F",
  "#4F94CD",
  "#507786",
  "#517B58",
  "#525C65",
  "#528B8B",
  "#543948",
  "#54FF9F",
  "#55141C",
  "#556B2F",
  "#575757",
  "#586949",
  "#596C56",
  "#5993E5",
  "#5B90F6",
  "#5C3317",
  "#5CACEE",
  "#5D92B1",
  "#5E2612",
  "#5EDA9E",
  "#5F9F9F",
  "#607C6E",
  "#615E3F",
  "#61B329",
  "#636363",
  "#63B8FF",
  "#6495ED",
  "#660000",
  "#666666",
  "#668B8B",
  "#66CD00",
  "#67C8FF",
  "#683A5E",
  "#68838B",
  "#694489",
  "#6996AD",
  "#6A8455",
  "#6B6B6B",
  "#6CA6CD",
  "#6E7B8B",
  "#6F4242",
  "#70DB93",
  "#7171C6",
  "#733D1A",
  "#739AC5",
  "#74BBFB",
  "#759B84",
  "#76EEC6",
  "#78A489",
  "#79A888",
  "#7A67EE",
  "#7AA9DD",
  "#7B68EE",
  "#7BCC70",
  "#7D26CD",
  "#7D9EC0",
  "#7F00FF",
  "#7F9A65",
  "#800000",
  "#808000",
  "#820BBB",
  "#836FFF",
  "#838EDE",
  "#84BE6A",
  "#858585",
  "#86C67C",
  "#87421F",
  "#87CEFA",
  "#8968CD",
  "#8A360F",
  "#8B0000",
  "#8B1A1A",
  "#8B2323",
  "#8B3A3A",
  "#8B4500",
  "#8B475D",
  "#8B5742",
  "#8B5F65",
  "#8B668B",
  "#8B7355",
  "#8B795E",
  "#8B7D7B",
  "#8B8378",
  "#8B8682",
  "#8B8989",
  "#8B8B83",
  "#8C1717",
  "#8CDD81",
  "#8E2323",
  "#8E6B23",
  "#8F5E99",
  "#8FA880",
  "#90EE90",
  "#912CEE",
  "#92CCA6",
  "#9400D3",
  "#969696",
  "#97694F",
  "#98F5FF",
  "#99182C",
  "#993300",
  "#99CDC9",
  "#9ACD32",
  "#9BC4E2",
  "#9C6B98",
  "#9CBA7F",
  "#9D6B84",
  "#9E0508",
  "#9F703A",
  "#9FB6CD",
  "#A0522D",
  "#A2B5CD",
  "#A2C93A",
  "#A3A3A3",
  "#A4DCD1",
  "#A62A2A",
  "#A6A6A6",
  "#A78D84",
  "#AA00FF",
  "#AAAAAA",
  "#AB82FF",
  "#ADADAD",
  "#ADFF2F",
  "#AF1E2D",
  "#B0171F",
  "#B0B0B0",
  "#B0E2FF",
  "#B23AEE",
  "#B2D0B4",
  "#B3B3B3",
  "#B452CD",
  "#B4EEB4",
  "#B5B5B5",
  "#B67C3D",
  "#B7C3D0",
  "#B87333",
  "#B9D3EE",
  "#BABABA",
  "#BC7642",
  "#BCE937",
  "#BDA0CB",
  "#BDFCC9",
  "#BEE554",
  "#BFBFBF",
  "#C0D9AF",
  "#C1CDC1",
  "#C1FFC1",
  "#C48E48",
  "#C5E3BF",
  "#C6C3B5",
  "#C73F17",
  "#C76E06",
  "#C82536",
  "#C9C9C9",
  "#CBCAB6",
  "#CC3232",
  "#CC7722",
  "#CCCC00",
  "#CCFFCC",
  "#CD1076",
  "#CD3278",
  "#CD4F39",
  "#CD5C5C",
  "#CD661D",
  "#CD69C9",
  "#CD8162",
  "#CD8C95",
  "#CD96CD",
  "#CDAA7D",
  "#CDAF95",
  "#CDB79E",
  "#CDBE70",
  "#CDC5BF",
  "#CDC9A5",
  "#CDCDB4",
  "#CDD704",
  "#CFB53B",
  "#CFDBC5",
  "#D0D2C4",
  "#D19275",
  "#D1EEEE",
  "#D3BECF",
  "#D41A1F",
  "#D44942",
  "#D5B77A",
  "#D6D6D6",
  "#D98719",
  "#D9D9F3",
  "#DAF4F0",
  "#DB7093",
  "#DB9EA6",
  "#DBE6E0",
  "#DC8909",
  "#DD7500",
  "#DEB887",
  "#DFFFA5",
  "#E066FF",
  "#E0E0E0",
  "#E0FFFF",
  "#E31230",
  "#E32E30",
  "#E3701A",
  "#E3CF57",
  "#E5BC3B",
  "#E6E6FA",
  "#E7C6A5",
  "#E8F1D4",
  "#EAADEA",
  "#EB5E66",
  "#EBEBEB",
  "#ED9121",
  "#EDEDED",
  "#EE1289",
  "#EE3A8C",
  "#EE5C42",
  "#EE6AA7",
  "#EE7942",
  "#EE8262",
  "#EE9572",
  "#EEA2AD",
  "#EEAEEE",
  "#EEC591",
  "#EECFA1",
  "#EED5D2",
  "#EEDC82",
  "#EEE0E5",
  "#EEE8AA",
  "#EEE9E9",
  "#EEEED1",
  "#F0A804",
  "#F0F8FF",
  "#F2473F",
  "#F4A460",
  "#F5785A",
  "#F5F5F5",
  "#F6A4D5",
  "#F6CCDA",
  "#F87531",
  "#FA8072",
  "#FAF0E6",
  "#FBA16C",
  "#FC1501",
  "#FCD59C",
  "#FDF8FF",
  "#FEF0DB",
  "#FF0033",
  "#FF00AA",
  "#FF1CAE",
  "#FF3300",
  "#FF3D0D",
  "#FF4500",
  "#FF6103",
  "#FF6666",
  "#FF6EB4",
  "#FF7256",
  "#FF7F00",
  "#FF8000",
  "#FF83FA",
  "#FF8C69",
  "#FF9955",
  "#FFA54F",
  "#FFAA00",
  "#FFB00F",
  "#FFB90F",
  "#FFC125",
  "#FFCC11",
  "#FFD39B",
  "#FFDEAD",
  "#FFE4B5",
  "#FFE600",
  "#FFEC8B",
  "#FFF0F5",
  "#FFF8DC",
  "#FFFAFA",
  "#FFFF7E",
  "#FFFFE0",
  "#EA899A",
  "#231A24",
];
export const STAGE_COLORS = [
  "#00857C",
  "#6ECEB2",
  "#69B8F7",
  "#688CE8",
  "#5450E4",
  "#0C2340",
  "#BFED33",
  "#FFF063",
];
export const THERAPY_COLORS = [
  "#00857C",
  "#6ECEB2",
  "#69B8F7",
  "#688CE8",
  "#5450E4",
  "#0C2340",
  "#BFED33",
  "#FFF063",
  "#BBCCCF",
  "#FBCDBC",
  "#BBDCBC",
  "#CCCDDF",
  "#BDBFDC",
  "#FFDCFD",
  "#CECEFF",
  "#EBEDDE",
  "#EEDBCF",
  "#EFBCDD",
  "#EDEDEC",
  "#DECBBC",
  "#FBDEDF",
  "#BEBFBD",
  "#BBFBCD",
  "#DFECDB",
  "#EDCCDE",
  "#FCEBFC",
  "#CEEEBB",
  "#BEFEEB",
  "#BECECB",
  "#FCBFEF",
  "#DCFEFB",
  "#DBBCCC",
  "#CBDDEF",
  "#CBCEBD",
  "#DEDEEC",
  "#EFDEBB",
  "#BBDEEF",
  "#EFECBB",
  "#EEBBFD",
  "#FFFCCD",
  "#CBBBBC",
  "#EEDBBE",
  "#CCCFDB",
  "#EECDCE",
  "#DDDFDD",
  "#FCCEBC",
  "#FCCDBD",
  "#DEFCCF",
  "#DCDCDF",
  "#EFDEDD",
  "#EDFCBC",
  "#DEDEEE",
  "#DFCDBB",
  "#EBCCBC",
  "#CEDBCF",
  "#BCCDDE",
  "#BFBEFE",
  "#CEEDDC",
  "#EDFFDB",
  "#CCEFFF",
  "#CEDFBE",
  "#BCCEDC",
  "#DEEDCE",
  "#EEBBCD",
  "#FFEBFE",
  "#EFECFB",
  "#FFEBEE",
  "#EBEEFF",
  "#EBFEEE",
  "#BEFDDB",
  "#ECDBDC",
  "#BDBCDD",
  "#EECEED",
  "#BECDDF",
  "#DEFEBE",
  "#CCDEEF",
  "#DCECEC",
  "#FDEBDB",
  "#BCDDCF",
  "#EDBBCE",
  "#ECFDBC",
  "#DFECBF",
  "#DBDBED",
  "#FDEEDD",
  "#FCFFCD",
  "#DDCCEB",
  "#BCDBCF",
  "#FEBFBE",
  "#EFBBFD",
  "#FBFDBE",
  "#CBDDCB",
  "#DBFFCB",
  "#BDBCFD",
  "#DEEBDE",
  "#DBEFCF",
  "#BEEFFE",
  "#FDDBFB",
  "#EDFFFC",
  "#FCCEEC",
  "#BBECFD",
  "#FDCBDB",
  "#DCBECC",
  "#DCFBDC",
  "#ECBDBC",
  "#DDCBEF",
  "#FBDEEB",
  "#BEBBFD",
  "#FCCBDD",
  "#BFEBEC",
  "#FEFFDB",
  "#CDCFFD",
  "#FFCDFC",
  "#FCFCFC",
  "#FBFBDF",
  "#EEBECF",
  "#FEEFCB",
  "#BCECFE",
  "#FEBCFE",
  "#DBDCFB",
  "#EFFBFD",
  "#CECDED",
  "#BBDEFF",
  "#EDDFFE",
  "#CCFDBF",
  "#BBBDDF",
  "#BBCCCE",
  "#EECEBF",
  "#BEBCEC",
  "#EBFEBB",
  "#DBEEBB",
  "#FEBCBD",
  "#DEDDFD",
  "#EEBCFD",
  "#EDCCCD",
  "#FFBDFB",
  "#FFFFCB",
  "#EFDCBC",
  "#BFEBCD",
  "#FCFFFC",
  "#CEDCCB",
  "#DCBBDD",
  "#DFFFEB",
  "#CEDBDE",
  "#BBEEFD",
  "#FBDDED",
  "#CBFDBE",
  "#EDFFBF",
  "#EBCCEF",
  "#CDEEBB",
  "#BFEBFF",
  "#EBDBFC",
  "#ECDBCD",
  "#BEEFCD",
  "#BECFEB",
  "#CCFEEE",
  "#FEFECD",
  "#DDEEBF",
  "#FDFBDC",
  "#DFDBBF",
  "#DDCBDB",
  "#DEEEBE",
  "#FBCEBB",
  "#FBCBFE",
  "#BDEECC",
  "#BCCFDE",
  "#BBCEFB",
  "#BDBBEF",
  "#CEBBEB",
  "#CFFDBD",
  "#EBDBBF",
  "#BDCCDD",
  "#EBBFED",
  "#CEBBDF",
  "#BBBDCF",
  "#ECBDFD",
  "#BBBCEB",
  "#BFFEED",
  "#CFEDCB",
  "#BCECDC",
  "#ECEDFF",
  "#CFCCBB",
  "#DFDEFC",
  "#EEEEBD",
  "#DEFDBC",
  "#CBFFCD",
  "#FDBEEC",
  "#BFFFCF",
  "#BFFBCD",
  "#DBCBDF",
  "#CBDEDE",
  "#DCEEEE",
  "#CFECED",
  "#CFBFDB",
  "#DFFDFE",
  "#DEBCBD",
  "#DCBBFC",
  "#DCCFBE",
  "#CFEFBD",
  "#DEFBBF",
  "#BDDECE",
  "#BDBECD",
  "#DDBCEE",
  "#FFFBED",
  "#FEBDDC",
  "#CDDFBB",
  "#FFECDB",
  "#CBCDED",
  "#EEEDFC",
  "#EEFCBC",
  "#CDCBCC",
  "#DCBCEC",
  "#EFBEEB",
  "#ECBEFB",
  "#DECFFB",
  "#FBECEC",
  "#EDDEFC",
  "#DFCCCF",
  "#DFCDCD",
  "#BECCCF",
  "#DEDCDE",
  "#CDDFEC",
  "#BFDFDF",
  "#ECDDBE",
  "#ECFBEF",
  "#CEFFDB",
  "#BFDFCE",
  "#CFCFEC",
  "#CFDCEF",
  "#BFBDEF",
  "#DEFBCE",
  "#FFCEFF",
  "#FCDCBB",
  "#FEFDCF",
  "#ECEFDE",
  "#ECCCBE",
  "#DDBDFB",
  "#FFECFD",
  "#EEFCCD",
  "#FDFDBC",
  "#BDEFBF",
  "#BEDCFF",
  "#DEEEDF",
  "#FDBFBF",
  "#FBEEDD",
  "#CDDBDB",
  "#FDCCCE",
  "#ECEEEE",
  "#CDCEBF",
  "#DFCBFB",
  "#CBCDFC",
  "#BDBEBE",
  "#FFBEBE",
  "#DBBDFE",
  "#DCEECE",
  "#CFBDDE",
  "#BEFCDF",
  "#BCEEEC",
  "#CECFBC",
  "#DEFEDC",
  "#FDFCCB",
  "#EDFDCF",
  "#EBFEDB",
  "#EFCCCF",
  "#BCEEBB",
  "#CDFCEE",
  "#FCCFFC",
  "#FEECEF",
  "#FFCFED",
  "#BEDBFD",
  "#ECFBFF",
  "#CDDEDF",
  "#ECFBFB",
  "#BEFFEC",
  "#EDDFBB",
  "#EFDEBD",
  "#CCCBEC",
  "#EEBCBD",
  "#EDCCFD",
  "#BBEDEF",
  "#ECDEBE",
  "#EEFDBF",
  "#EFFEBE",
  "#FDDBDC",
  "#FFFCFE",
  "#DDFFEB",
  "#EEEFBC",
  "#DCEFFB",
  "#CCEEDE",
  "#EFEDDC",
  "#CBFCFF",
  "#FDEECB",
  "#DDFEDE",
  "#DBFFFD",
  "#CECFDF",
  "#FFBFBC",
  "#EFDFFE",
  "#FDBBEC",
  "#DBBCBE",
  "#BBBCCD",
  "#CEBEEC",
  "#BBDEEB",
  "#BFEFEB",
  "#EFCECB",
  "#BBBBFB",
  "#EFFEFF",
  "#DCFFFE",
  "#EEECBC",
  "#ECECDF",
  "#BE8C9B",
  "#9CE813",
  "#E8A6BE",
  "#4129D3",
  "#1104DD",
  "#492243",
  "#58F36F",
  "#DCE2DE",
  "#5E2D79",
  "#5F755E",
  "#603311",
  "#608341",
  "#616161",
  "#629632",
  "#636F57",
  "#63D1F4",
  "#65909A",
  "#6600FF",
  "#6666FF",
  "#668E86",
  "#66CDAA",
  "#67E6EC",
  "#687C97",
  "#688571",
  "#6959CD",
  "#698B22",
  "#699864",
  "#6B238E",
  "#6B8E23",
  "#6D9BF1",
  "#6E8B3D",
  "#6F7285",
  "#708090",
  "#70DBDB",
  "#71C671",
  "#734A12",
  "#73B1B7",
  "#754C78",
  "#75A1D0",
  "#777733",
  "#77896C",
  "#78AB46",
  "#79CDCD",
  "#7A7A7A",
  "#7AC5CD",
  "#7B7922",
  "#7CCD7C",
  "#7D7D7D",
  "#7EB6FF",
  "#7F7F7F",
  "#7FFF00",
  "#800080",
  "#808069",
  "#808A87",
  "#828282",
  "#838B83",
  "#83F52C",
  "#855E42",
  "#859C27",
  "#871F78",
  "#878787",
  "#87CEFF",
  "#8A2BE2",
  "#8A8A8A",
  "#8B008B",
  "#8B1C62",
  "#8B2500",
  "#8B3A62",
  "#8B4513",
  "#8B4789",
  "#8B5A00",
  "#8B636C",
  "#8B6914",
  "#8B7500",
  "#8B7B8B",
  "#8B7E66",
  "#8B8386",
  "#8B8878",
  "#8B8B00",
  "#8BA446",
  "#8C7853",
  "#8DB6CD",
  "#8E236B",
  "#8E8E38",
  "#8FBC8F",
  "#90FEFB",
  "#919191",
  "#9370DB",
  "#949494",
  "#96C8A2",
  "#97FFFF",
  "#98FB98",
  "#9932CC",
  "#999999",
  "#9A32CD",
  "#9AFF9A",
  "#9BCD9B",
  "#9C9C9C",
  "#9CCB19",
  "#9D8851",
  "#9E9E9E",
  "#9F79EE",
  "#A020F0",
  "#A1A1A1",
  "#A2BC13",
  "#A2CD5A",
  "#A46582",
  "#A52A2A",
  "#A67D3D",
  "#A6D785",
  "#A8A8A8",
  "#A9ACB6",
  "#AA5303",
  "#AAAAFF",
  "#ABABAB",
  "#ADD8E6",
  "#AEBB51",
  "#AF4035",
  "#B03060",
  "#B0C4DE",
  "#B13E0F",
  "#B272A6",
  "#B2DFEE",
  "#B3C95A",
  "#B4CDCD",
  "#B5509C",
  "#B62084",
  "#B6AFA9",
  "#B7C8B6",
  "#B8860B",
  "#BA55D3",
  "#BB2A3C",
  "#BC8F8F",
  "#BCED91",
  "#BDB76B",
  "#BE2625",
  "#BF3EFF",
  "#BFEFFF",
  "#C0D9D9",
  "#C1CDCD",
  "#C2C2C2",
  "#C4C4C4",
  "#C65D57",
  "#C6E2FF",
  "#C75D4D",
  "#C77826",
  "#C8F526",
  "#CAE1FF",
  "#CC00FF",
  "#CC3299",
  "#CC7F32",
  "#CCCCCC",
  "#CD0000",
  "#CD2626",
  "#CD3333",
  "#CD5555",
  "#CD6090",
  "#CD6839",
  "#CD7054",
  "#CD8500",
  "#CD919E",
  "#CD9B1D",
  "#CDAB2D",
  "#CDB38B",
  "#CDB7B5",
  "#CDC0B0",
  "#CDC673",
  "#CDC9C9",
  "#CDCDC1",
  "#CDE472",
  "#CFCFCF",
  "#D02090",
  "#D0FAEE",
  "#D1D1D1",
  "#D2691E",
  "#D3D3D3",
  "#D4318C",
  "#D4D4D4",
  "#D66F62",
  "#D8BFD8",
  "#D9D919",
  "#DA70D6",
  "#DB2645",
  "#DB70DB",
  "#DBDB70",
  "#DBFEF8",
  "#DCA2CD",
  "#DDA0DD",
  "#DEDEDE",
  "#E04006",
  "#E0D873",
  "#E0EEE0",
  "#E18E2E",
  "#E3170D",
  "#E33638",
  "#E38217",
  "#E3E3E3",
  "#E5E5E5",
  "#E6E8FA",
  "#E8C782",
  "#E9967A",
  "#EAB5C5",
  "#EBC79E",
  "#ECC3BF",
  "#EDC393",
  "#EE0000",
  "#EE2C2C",
  "#EE3B3B",
  "#EE6363",
  "#EE7600",
  "#EE799F",
  "#EE82EE",
  "#EE9A00",
  "#EEA9B8",
  "#EEB422",
  "#EEC900",
  "#EED2EE",
  "#EED6AF",
  "#EEDD82",
  "#EEE5DE",
  "#EEE8CD",
  "#EEEB8D",
  "#EEEEE0",
  "#F0E68C",
  "#F0FFF0",
  "#F2F2F2",
  "#F4F776",
  "#F5DEB3",
  "#F5FFFA",
  "#F6A8B6",
  "#F7B3DA",
  "#F8F8FF",
  "#FA9A50",
  "#FAFAD2",
  "#FBDB0C",
  "#FCB514",
  "#FCDC3B",
  "#FCFFF0",
  "#FEE5AC",
  "#FEF1B5",
  "#FF0066",
  "#FF00CC",
  "#FF030D",
  "#FF2400",
  "#FF3333",
  "#FF3E96",
  "#FF5333",
  "#FF6347",
  "#FF69B4",
  "#FF6EC7",
  "#FF7722",
  "#FF7F24",
  "#FF8247",
  "#FF8600",
  "#FF92BB",
  "#FFA07A",
  "#FFA812",
  "#FFADB9",
  "#FFB5C5",
  "#FFBBFF",
  "#FFC1C1",
  "#FFCC99",
  "#FFD700",
  "#FFE1FF",
  "#FFE4C4",
  "#FFE7BA",
  "#FFEFD5",
  "#FFF5EE",
  "#FFFACD",
  "#FFFCCF",
  "#FFFFAA",
  "#FFFFF0",
  "#00009C",
  "#0000FF",
  "#003F87",
  "#006400",
  "#006B54",
  "#008080",
  "#008B45",
  "#0099CC",
  "#00B2EE",
  "#00C78C",
  "#00CD66",
  "#00E5EE",
  "#00EEEE",
  "#00FF00",
  "#00FF7F",
  "#00FFFF",
  "#0198E1",
  "#030303",
  "#05B8CC",
  "#068481",
  "#0AC92B",
  "#0D4F8B",
  "#0F0F0F",
  "#108070",
  "#138F6A",
  "#162252",
  "#1874CD",
  "#1B3F8B",
  "#1C86EE",
  "#1E90FF",
  "#20BF9F",
  "#215E21",
  "#228B22",
  "#238E68",
  "#262626",
  "#283A90",
  "#292421",
  "#2B2B2B",
  "#2C5D3F",
  "#2E37FE",
  "#2E6444",
  "#2F4F2F",
  "#2FAA96",
  "#3063A5",
  "#3232CC",
  "#325C74",
  "#3299CC",
  "#32CD99",
  "#333333",
  "#33A1C9",
  "#344152",
  "#35586C",
  "#363636",
  "#37BC61",
  "#383838",
  "#38B0DE",
  "#39B7CD",
  "#3A5FCD",
  "#3B3178",
  "#3B5323",
  "#3B8471",
  "#3D5229",
  "#3D8B37",
  "#3E766D",
  "#3EA055",
  "#3F9E4D",
  "#40E0D0",
  "#422C2F",
  "#42526C",
  "#426F42",
  "#436EEE",
  "#43D58C",
  "#458B00",
  "#46523C",
  "#474747",
  "#484D46",
  "#488214",
  "#4981CE",
  "#49E9BD",
  "#4A708B",
  "#4AC948",
  "#4C7064",
  "#4D4D4D",
  "#4D6FAC",
  "#4DBD33",
  "#4F2F4F",
  "#4F8E83",
  "#50729F",
  "#517693",
  "#525252",
  "#527F76",
  "#539DC2",
  "#548B54",
  "#551033",
  "#555555",
  "#567E3A",
  "#584E56",
  "#5959AB",
  "#597368",
  "#5B59BA",
  "#5C246E",
  "#5C5C5C",
  "#5D7B93",
  "#5E2605",
  "#5E5E5E",
  "#5F9EA0",
  "#607B8B",
  "#60AFFE",
  "#6183A6",
  "#62B1F6",
  "#63AB62",
  "#646F5E",
  "#659D32",
  "#660198",
  "#668014",
  "#66CCCC",
  "#66FF66",
  "#68228B",
  "#687E5A",
  "#691F01",
  "#696969",
  "#698B69",
  "#6A5ACD",
  "#6B4226",
  "#6C7B8B",
  "#6E6E6E",
  "#6EFF70",
  "#707070",
  "#7093DB",
  "#71637D",
  "#72587F",
  "#737373",
  "#748269",
  "#757575",
  "#76EE00",
  "#778899",
  "#787878",
  "#79973F",
  "#7A378B",
  "#7A8B8B",
  "#7B3F00",
  "#7BBF6A",
  "#7CFC00",
  "#7D7F94",
  "#7EC0EE",
  "#7F8778",
  "#7FFFD4",
  "#802A2A",
  "#808080",
  "#816687",
  "#82CFFD",
  "#838B8B",
  "#8470FF",
  "#856363",
  "#862A51",
  "#872657",
  "#87CEEB",
  "#88ACE0",
  "#8A3324",
  "#8AA37B",
  "#8B0A50",
  "#8B2252",
  "#8B3626",
  "#8B3E2F",
  "#8B4726",
  "#8B4C39",
  "#8B5A2B",
  "#8B6508",
  "#8B6969",
  "#8B7765",
  "#8B7D6B",
  "#8B814C",
  "#8B864E",
  "#8B8970",
  "#8B8B7A",
  "#8BA870",
  "#8C8C8C",
  "#8DEEEE",
  "#8E388E",
  "#8EE5EE",
  "#8F8FBC",
  "#8FD8D8",
  "#91219E",
  "#91B49C",
  "#93DB70",
  "#964514",
  "#96CDCD",
  "#98A148",
  "#990099",
  "#9932CD",
  "#99CC32",
  "#9AC0CD",
  "#9B30FF",
  "#9C661F",
  "#9CA998",
  "#9D1309",
  "#9DB68C",
  "#9F5F9F",
  "#9F9F5F",
  "#A02422",
  "#A2627A",
  "#A2C257",
  "#A39480",
  "#A4D3EE",
  "#A5435C",
  "#A68064",
  "#A74CAB",
  "#A9A9A9",
  "#A9C9A4",
  "#AA6600",
  "#AADD00",
  "#AC7F24",
  "#ADEAEA",
  "#AEEEEE",
  "#AFEEEE",
  "#B0A6A4",
  "#B0E0E6",
  "#B22222",
  "#B28647",
  "#B3432B",
  "#B3EE3A",
  "#B4D7BF",
  "#B5A642",
  "#B6316C",
  "#B6C5BE",
  "#B81324",
  "#B8B8B8",
  "#BAAF07",
  "#BBFFFF",
  "#BCD2EE",
  "#BCEE68",
  "#BDBDBD",
  "#BEBEBE",
  "#BF5FFF",
  "#C0C0C0",
  "#C0FF3E",
  "#C1F0F6",
  "#C3E4ED",
  "#C5C1AA",
  "#C67171",
  "#C71585",
  "#C76114",
  "#C7C7C7",
  "#C9AF94",
  "#CAFF70",
  "#CC1100",
  "#CC4E5C",
  "#CC99CC",
  "#CCCCFF",
  "#CD00CD",
  "#CD2990",
  "#CD3700",
  "#CD5B45",
  "#CD6600",
  "#CD6889",
  "#CD7F32",
  "#CD853F",
  "#CD950C",
  "#CD9B9B",
  "#CDAD00",
  "#CDB5CD",
  "#CDBA96",
  "#CDC1C5",
  "#CDC8B1",
  "#CDCD00",
  "#CDCDCD",
  "#CECC15",
  "#CFD784",
  "#D0A9AA",
  "#D15FEE",
  "#D1E231",
  "#D2B48C",
  "#D43D1A",
  "#D4ED91",
  "#D6C537",
  "#D8D8BF",
  "#D9D9D9",
  "#DAA520",
  "#DB2929",
  "#DB9370",
  "#DBDBDB",
  "#DC143C",
  "#DCDCDC",
  "#DE85B1",
  "#DFAE74",
  "#E0427F",
  "#E0DFDB",
  "#E0EEEE",
  "#E2DDB5",
  "#E32636",
  "#E35152",
  "#E3A869",
  "#E47833",
  "#E6B426",
  "#E79EA9",
  "#E8E8E8",
  "#E9C2A6",
  "#EAEAAE",
  "#EBCEAC",
  "#ECC8EC",
  "#EDCB62",
  "#EE00EE",
  "#EE30A7",
  "#EE4000",
  "#EE6A50",
  "#EE7621",
  "#EE7AE9",
  "#EE8833",
  "#EE9A49",
  "#EEAD0E",
  "#EEB4B4",
  "#EECBAD",
  "#EED5B7",
  "#EED8AE",
  "#EEDFCC",
  "#EEE685",
  "#EEE9BF",
  "#EEEE00",
  "#F08080",
  "#F0F0F0",
  "#F0FFFF",
  "#F3E88E",
  "#F54D70",
  "#F5F5DC",
  "#F64D54",
  "#F6C9CC",
  "#F7F7F7",
  "#FA1D2F",
  "#FAEBD7",
  "#FAFAFA",
  "#FBEC5D",
  "#FCD116",
  "#FCE6C9",
  "#FDF5E6",
  "#FEE8D6",
  "#FF0000",
  "#FF007F",
  "#FF00FF",
  "#FF1493",
  "#FF3030",
  "#FF34B3",
  "#FF4040",
  "#FF5721",
  "#FF6600",
  "#FF6A6A",
  "#FF7216",
  "#FF7D40",
  "#FF7F50",
  "#FF82AB",
  "#FF8C00",
  "#FF9912",
  "#FFA500",
  "#FFA824",
  "#FFAEB9",
  "#FFB6C1",
  "#FFC0CB",
  "#FFC469",
  "#FFCCCC",
  "#FFDAB9",
  "#FFE303",
  "#FFE4E1",
  "#FFEBCD",
  "#FFEFDB",
  "#FFF68F",
  "#FFFAF0",
  "#FFFF00",
  "#FFFFCC",
  "#FFFFFF",
  "#0000CD",
  "#003300",
  "#004F00",
  "#006633",
  "#007FFF",
  "#00868B",
  "#008B8B",
  "#009ACD",
  "#00BFFF",
  "#00C957",
  "#00CDCD",
  "#00EE00",
  "#00F5FF",
  "#00FF33",
  "#00FFAA",
  "#01C5BB",
  "#03A89E",
  "#05E9FF",
  "#080808",
  "#0BB5FF",
  "#0E8C3A",
  "#0FDDAF",
  "#120A8F",
  "#141414",
  "#171717",
  "#191970",
  "#1B6453",
  "#1D7CF2",
  "#1F1F1F",
  "#212121",
  "#218868",
  "#23238E",
  "#242424",
  "#26466D",
  "#284942",
  "#292929",
  "#2B4F81",
  "#2E0854",
  "#2E473B",
  "#2E8B57",
  "#302B54",
  "#308014",
  "#3232CD",
  "#327556",
  "#32CC99",
  "#330000",
  "#3333FF",
  "#33A1DE",
  "#34925E",
  "#3579DC",
  "#36648B",
  "#37FDFC",
  "#385E0F",
  "#395D33",
  "#3A3A38",
  "#3A6629",
  "#3B3B3B",
  "#3B5E2B",
  "#3CB371",
  "#3D59AB",
  "#3D9140",
  "#3F602B",
  "#404040",
  "#414F12",
  "#424242",
  "#426352",
  "#42C0FB",
  "#4372AA",
  "#454545",
  "#458B74",
  "#4682B4",
  "#476A34",
  "#487153",
  "#48D1CC",
  "#499DF5",
  "#4A4A4A",
  "#4A766E",
  "#4B0082",
  "#4CB7A5",
  "#4D4DFF",
  "#4D71A3",
  "#4E78A0",
  "#4F4F2F",
  "#4F94CD",
  "#507786",
  "#517B58",
  "#525C65",
  "#528B8B",
  "#543948",
  "#54FF9F",
  "#55141C",
  "#556B2F",
  "#575757",
  "#586949",
  "#596C56",
  "#5993E5",
  "#5B90F6",
  "#5C3317",
  "#5CACEE",
  "#5D92B1",
  "#5E2612",
  "#5EDA9E",
  "#5F9F9F",
  "#607C6E",
  "#615E3F",
  "#61B329",
  "#636363",
  "#63B8FF",
  "#6495ED",
  "#660000",
  "#666666",
  "#668B8B",
  "#66CD00",
  "#67C8FF",
  "#683A5E",
  "#68838B",
  "#694489",
  "#6996AD",
  "#6A8455",
  "#6B6B6B",
  "#6CA6CD",
  "#6E7B8B",
  "#6F4242",
  "#70DB93",
  "#7171C6",
  "#733D1A",
  "#739AC5",
  "#74BBFB",
  "#759B84",
  "#76EEC6",
  "#78A489",
  "#79A888",
  "#7A67EE",
  "#7AA9DD",
  "#7B68EE",
  "#7BCC70",
  "#7D26CD",
  "#7D9EC0",
  "#7F00FF",
  "#7F9A65",
  "#800000",
  "#808000",
  "#820BBB",
  "#836FFF",
  "#838EDE",
  "#84BE6A",
  "#858585",
  "#86C67C",
  "#87421F",
  "#87CEFA",
  "#8968CD",
  "#8A360F",
  "#8B0000",
  "#8B1A1A",
  "#8B2323",
  "#8B3A3A",
  "#8B4500",
  "#8B475D",
  "#8B5742",
  "#8B5F65",
  "#8B668B",
  "#8B7355",
  "#8B795E",
  "#8B7D7B",
  "#8B8378",
  "#8B8682",
  "#8B8989",
  "#8B8B83",
  "#8C1717",
  "#8CDD81",
  "#8E2323",
  "#8E6B23",
  "#8F5E99",
  "#8FA880",
  "#90EE90",
  "#912CEE",
  "#92CCA6",
  "#9400D3",
  "#969696",
  "#97694F",
  "#98F5FF",
  "#99182C",
  "#993300",
  "#99CDC9",
  "#9ACD32",
  "#9BC4E2",
  "#9C6B98",
  "#9CBA7F",
  "#9D6B84",
  "#9E0508",
  "#9F703A",
  "#9FB6CD",
  "#A0522D",
  "#A2B5CD",
  "#A2C93A",
  "#A3A3A3",
  "#A4DCD1",
  "#A62A2A",
  "#A6A6A6",
  "#A78D84",
  "#AA00FF",
  "#AAAAAA",
  "#AB82FF",
  "#ADADAD",
  "#ADFF2F",
  "#AF1E2D",
  "#B0171F",
  "#B0B0B0",
  "#B0E2FF",
  "#B23AEE",
  "#B2D0B4",
  "#B3B3B3",
  "#B452CD",
  "#B4EEB4",
  "#B5B5B5",
  "#B67C3D",
  "#B7C3D0",
  "#B87333",
  "#B9D3EE",
  "#BABABA",
  "#BC7642",
  "#BCE937",
  "#BDA0CB",
  "#BDFCC9",
  "#BEE554",
  "#BFBFBF",
  "#C0D9AF",
  "#C1CDC1",
  "#C1FFC1",
  "#C48E48",
  "#C5E3BF",
  "#C6C3B5",
  "#C73F17",
  "#C76E06",
  "#C82536",
  "#C9C9C9",
  "#CBCAB6",
  "#CC3232",
  "#CC7722",
  "#CCCC00",
  "#CCFFCC",
  "#CD1076",
  "#CD3278",
  "#CD4F39",
  "#CD5C5C",
  "#CD661D",
  "#CD69C9",
  "#CD8162",
  "#CD8C95",
  "#CD96CD",
  "#CDAA7D",
  "#CDAF95",
  "#CDB79E",
  "#CDBE70",
  "#CDC5BF",
  "#CDC9A5",
  "#CDCDB4",
  "#CDD704",
  "#CFB53B",
  "#CFDBC5",
  "#D0D2C4",
  "#D19275",
  "#D1EEEE",
  "#D3BECF",
  "#D41A1F",
  "#D44942",
  "#D5B77A",
  "#D6D6D6",
  "#D98719",
  "#D9D9F3",
  "#DAF4F0",
  "#DB7093",
  "#DB9EA6",
  "#DBE6E0",
  "#DC8909",
  "#DD7500",
  "#DEB887",
  "#DFFFA5",
  "#E066FF",
  "#E0E0E0",
  "#E0FFFF",
  "#E31230",
  "#E32E30",
  "#E3701A",
  "#E3CF57",
  "#E5BC3B",
  "#E6E6FA",
  "#E7C6A5",
  "#E8F1D4",
  "#EAADEA",
  "#EB5E66",
  "#EBEBEB",
  "#ED9121",
  "#EDEDED",
  "#EE1289",
  "#EE3A8C",
  "#EE5C42",
  "#EE6AA7",
  "#EE7942",
  "#EE8262",
  "#EE9572",
  "#EEA2AD",
  "#EEAEEE",
  "#EEC591",
  "#EECFA1",
  "#EED5D2",
  "#EEDC82",
  "#EEE0E5",
  "#EEE8AA",
  "#EEE9E9",
  "#EEEED1",
  "#F0A804",
  "#F0F8FF",
  "#F2473F",
  "#F4A460",
  "#F5785A",
  "#F5F5F5",
  "#F6A4D5",
  "#F6CCDA",
  "#F87531",
  "#FA8072",
  "#FAF0E6",
  "#FBA16C",
  "#FC1501",
  "#FCD59C",
  "#FDF8FF",
  "#FEF0DB",
  "#FF0033",
  "#FF00AA",
  "#FF1CAE",
  "#FF3300",
  "#FF3D0D",
  "#FF4500",
  "#FF6103",
  "#FF6666",
  "#FF6EB4",
  "#FF7256",
  "#FF7F00",
  "#FF8000",
  "#FF83FA",
  "#FF8C69",
  "#FF9955",
  "#FFA54F",
  "#FFAA00",
  "#FFB00F",
  "#FFB90F",
  "#FFC125",
  "#FFCC11",
  "#FFD39B",
  "#FFDEAD",
  "#FFE4B5",
  "#FFE600",
  "#FFEC8B",
  "#FFF0F5",
  "#FFF8DC",
  "#FFFAFA",
  "#FFFF7E",
  "#FFFFE0",
  "#EA899A",
  "#231A24",
];
