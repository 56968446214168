import React, { useEffect, useState } from "react";
import Routers from "./routes/index";
import { message } from "antd";
import "antd/dist/antd.css";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { getLocalStorageData } from "utils/utils";

const App = () => {

  useEffect(() => {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let headerData = urlParams.get("header");

    if (headerData && headerData === "false") {
      localStorage.setItem("appHeaderVisibility", false); // hide header if query params are header=false
    } else {
      localStorage.setItem("appHeaderVisibility", true);
    }
  }, []);
  // const initiateWebsockets = (userData) => {
  //   let socket = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}${userData}/`);
  //   socket.onopen = () => {
  //     socket.onmessage = event => {
  //       var data = JSON.parse(event.data);
  //     };
  //   };
  //   socket.onclose = () => {
  //     socket = null;
  //     setTimeout(this.initiateWebsockets(userData), 5000);
  //   };
  //   socket.onerror = () => {
  //     socket = null;
  //     setTimeout(this.initiateWebsockets(userData), 5000);
  //   };
  // }
    // const data = JSON.parse(window.localStorage.getItem("auth"))
    // if (data) {
    //   let id = data.username
    //   let index = id.indexOf("@")
    //   id = id.substr(0, index)
    //   console.log("id", id)
    //   initiateWebsockets(id)
    // }
  // }, []);

  return (
    <div>
      <AuthenticatedTemplate>
        <div className={getLocalStorageData("appHeaderVisibility") ? "app-margin-top" : "no-app-margin-top"}>
          <Routers />
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {/* <p>You are not signed in! Please sign in.</p> */}
      </UnauthenticatedTemplate>
    </div>
  );
};

export default App;
