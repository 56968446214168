import Axios from "axios";

export default (state, action) => {
  if (state == null) {
    const details = localStorage.getItem("user_data");
    state =
      details && details !== "undefined"
        ? JSON.parse(details)
        : { is_authenticated: false };
    state.username = state.mail;
  }
  switch (action.type) {
    case "login":
      state = { ...state, ...action.payload, is_authenticated: true };
      state.username = state.mail;
      localStorage.setItem("auth", JSON.stringify(state));
      Axios.defaults.headers.common.Authorization = `Bearer ${state.token}`;
      break;
    case "logout":
      state = { is_authenticated: false };
      localStorage.clear();
      delete Axios.defaults.headers.common.Authorization;
      break;
    default:
      break;
  }
  return state;
};
