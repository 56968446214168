import { PublicClientApplication, InteractionRequiredAuthError  } from "@azure/msal-browser";
import { callMsGraph } from "../component/graph";
import axios from "axios";
import { getUserData } from '../utils/utils'
import state from '../store'

export const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_CLIENT_ID,
      authority: "https://login.microsoftonline.com/c88fe011-1411-4e3a-a2cf-3859b305642c",
      redirectUri: process.env.REACT_APP_REDIRECT_URL,
      postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_URL
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored // sessionStorage
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  export const msalInstance = new PublicClientApplication(msalConfig);

  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    profilePhoto: "https://graph.microsoft.com/v1.0/me/photo/$value"
  };

  msalInstance.handleRedirectPromise().then(handleResponse);
  var accountId = "";
  function handleResponse(response) {
    if (response !== null) {
      accountId = response.account.homeAccountId;
      callMsGraph(response.accessToken)
      // getToken()
      // Display signed-in user content, call API, etc.
    } else {
      // In case multiple accounts exist, you can select
      const currentAccounts = msalInstance.getAllAccounts();

      if (currentAccounts.length === 0) {
        // no accounts signed-in, attempt to sign a user in
        msalInstance.loginRedirect(loginRequest);
      } else if (currentAccounts.length > 1) {
        // Add choose account code here
      } else if (currentAccounts.length === 1) {
        accountId = currentAccounts[0].homeAccountId;
      }
    }
  }
    
  export function getToken () {
    const accounts = msalInstance.getAllAccounts()
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }
    msalInstance.setActiveAccount(accounts[0])
    const tokenRequest = {
      scopes: ["User.Read", "Mail.Read"],
      account: accounts[0],
      forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
    };
    msalInstance
      .acquireTokenSilent(tokenRequest)
      .then(function (accessTokenResponse) {
        // Acquire token silent success
        // Call API with token
        let accessToken = accessTokenResponse.accessToken;
        callMsGraph(accessToken)
      })
      .catch(function (error) {
        //Acquire token silent failure, and send an interactive request
        console.log("error in access token", error)
        if (error instanceof InteractionRequiredAuthError) {
          msalInstance.acquireTokenRedirect(tokenRequest);
        }
      });
  }

  // <----------------------SIGN_IN--------------------------------------->
  export function signIn() {
    // localStorage.clear();
    return msalInstance.loginRedirect(loginRequest);
  }
  // <----------------------SIGN_OUT--------------------------------------->
  export function signOut () {
    msalInstance.logoutRedirect({
      postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_URL,
    });
    localStorage.clear();
  }
