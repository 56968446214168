import { Modal, Icon, Popover } from "antd";
import React from "react";
import { getAttibuteArray } from "component/createProject/AnalysisDetail/sheet/utils";
import { DarkColorPalette } from "component/createProject/AnalysisDetail/sheet/Analysis/AnalysisTree/NewDeepdiveCharts/colors";

export const isTooDark = (hexcolor) => {
  const r = parseInt(hexcolor.substr(1, 2), 16);
  const g = parseInt(hexcolor.substr(3, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq < 140 ? true : false;
};

export const rgbToHex = (r, g, b) => {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

export const FormatAS = (n) => {
  if (!n) return 0;
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(2) + "T";
};

export const Gid = () => {
  return Math.random().toString(36).substr(2);
};

const toTimestampVal = (val) => {
  // var ts = new Date(val).getTime() / 1000
  // var ts_ms = ts * 1000;
  // var date_ob = new Date(ts_ms);
  // var year = date_ob.getFullYear();
  // var month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
  // var date = ("0" + date_ob.getDate()).slice(-2);
  // var hours = ("0" + date_ob.getHours()).slice(-2);
  // var minutes = ("0" + date_ob.getMinutes()).slice(-2);
  // var seconds = ("0" + date_ob.getSeconds()).slice(-2);
  // return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`
  return `${val} 00:00:00`;
};

export const timeStampDateRanges = (value) => {
  let newInput = {
    analysis_period: {
      start_date: toTimestampVal(value.analysis_period.start_date),
      end_date: toTimestampVal(value.analysis_period.end_date),
    },
    look_back: {
      start_date: toTimestampVal(value.look_back.start_date),
      end_date: toTimestampVal(value.look_back.end_date),
    },
    look_forward: {
      start_date: toTimestampVal(value.look_forward.start_date),
      end_date: toTimestampVal(value.look_forward.end_date),
    },
  };
  return newInput;
};

export const NumberFormatter = (n) => {
  if (!n) return 0;
  let num = Number(parseFloat(n).toFixed(2)).toLocaleString("en", {
    minimumFractionDigits: 2,
  });
  if (num.indexOf(".00") > -1) {
    num = num.replace(".00", "");
  }
  return num;
};

export const RoundNumber = (n) => {
  if (!n) return 0;
  return Math.round(n);
};

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

export const converTimestampToDate = (timeStamp) => {
  const d = new Date(timeStamp);
  const year = d.getFullYear();
  const month = `${d.getMonth() + 1}`.padStart(2, "0");
  const day = `${d.getDate()}`.padStart(2, "0");
  return [year, month, day].join("-")
 
};

export const DateFormatter = (n) => {
  let d = new Date(n);
  return (
    [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join(
      "-"
    ) +
    " " +
    [
      d.getHours().padLeft(),
      d.getMinutes().padLeft(),
      d.getSeconds().padLeft(),
    ].join(":")
  );
};
export const isNum = (num) => {
  const regExp = /^[0-9]+$/;
  const value = num || 0;
  if (regExp.test(value)) {
    return true;
  }
  return false;
};
export const checkForDuplocateName = (name, attributes, type, data_val) => {
  const flags = attributes.map((attr) => {
    if (attr.type === type && attr.name === name && attr.id !== data_val.id) {
      return true;
    }
    return false;
  });
  return flags.includes(true);
};

export const infoModal = (message) => {
  Modal.info({
    title: message,
    onOk() {},
  });
};

export const blockInvalidChar = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

export const renderLabel = (label) => {
  if (label.length > 25) {
    return `${label.slice(0, 25)}...`;
  }
  return label;
};

export const formatSliderValue = (ranges) => {
  let newRange = {
    look_back: {
      start_date: ranges["analysis_period"]["start_date"].split(" ")[0],
      end_date: ranges["analysis_period"]["start_date"].split(" ")[0],
    },
    analysis_period: {
      start_date: ranges["analysis_period"]["start_date"].split(" ")[0],
      end_date: ranges["analysis_period"]["end_date"].split(" ")[0],
    },
    look_forward: {
      start_date: ranges["analysis_period"]["end_date"].split(" ")[0],
      end_date: ranges["analysis_period"]["end_date"].split(" ")[0],
    },
  };
  return newRange;
};

export const statusMessage = (state) => {
  if (state?.batch_process_details) {
    let status = state.batch_process_details.status;
    if (status === "FAILED") {
      return {
        text: "Attribute creation has been failed. Please reach out to PIE UI team for resolution of the issues",
        color: "#ff4d4f",
      };
    }

    if (status === "YET_TO_SUBMIT") {
      return {
        text: "Project has been updated with new cohort. Please review and run the attributes for patient insights summary",
        color: "#172b3c",
      };
    }

    if (["READY", "PENDING", "RUNNING"].includes(status)) {
      return {
        text: "Attribute creation is in progress",
        color: "#172b3c",
      };
    }
  }
};

export const isBatchProcessSuccess = (state) => {
  if (state?.batch_process_details) {
    let status = state.batch_process_details.status;
    return ["COMPLETED", "FINISHED"].includes(status) ? true : false;
  }
  return false;
};

export const isNotSubmittedYet = (state) => {
  if (state?.batch_process_details) {
    let status = state.batch_process_details.status;
    return status !== "YET_TO_SUBMIT";
  }
  return false;
};

export const getDrugDimension = (drugClass) => {
  let drugAttr = {
    name: drugClass.name,
    id: drugClass.id,
    drugLists: [],
  };

  if (
    drugClass.data.As_Is_Flag === "group" &&
    drugClass.data.Group_Details.length > 0
  ) {
    let names = drugClass.data.Group_Details.map((drug) => drug.Name);
    drugAttr.drugLists = [...drugAttr.drugLists, ...names];
  }

  if (drugClass.data.As_Is_Flag === "asIs" && drugClass.data.Details) {
    let finalNames = [];
    drugClass.data.Details.forEach((drug) => {
      if (
        drugClass.data.Selected_Column in drug &&
        !drugAttr.drugLists.includes(drug[drugClass.data.Selected_Column])
      ) {
        finalNames.push(drug[drugClass.data.Selected_Column]);
      }
    });
    let uniqueValues = [...new Set(finalNames)];
    if (uniqueValues.length) {
      drugAttr.drugLists = uniqueValues.filter((el) => el !== "");
    }
  }

  return drugAttr;
};

export const trucateText = (text, size) => {
  if (text.length > size) {
    return `${text.slice(0, size)}...`;
  }
  return text;
};

export const getInitial = (name) => {
  return name[0];
};

export const checkJobStatus = (job) => {
  if (["READY", "RUNNING", "PENDING", "REFRESH_PENDING"].includes(job.status)) {
    return true;
  }
  return false;
};

export const getCardActions = (tooltip) => (
  <span>
    <Popover
      overlayClassName="card-tooltip"
      placement="left"
      overlayStyle={{ whiteSpace: "pre-line", maxwidth: "300px" }}
      content={tooltip}
    >
      <Icon type="info-circle" />
    </Popover>
  </span>
);
export const getConstructedAttributeMapping = (attribute, data) => {
  if (attribute.type === "lot") {
    const { lot_json } = getAttibuteArray(attribute);
    data = {
      ...data,
      lot_json,
      column_attribute: attribute.data.header.attribute,
    };
  } else if (attribute.type === "compliance") {
    data = {
      ...data,
      compliance_mappings_json: { ...attribute.data },
    };
  } else if (attribute.type === "provider_name") {
    data = {
      ...data,
      provider_mappings_json: { ...attribute.data },
    };
  } else if (attribute.type === "specialty") {
    data = {
      ...data,
      speciality_mappings_json: { ...attribute.data },
    };
  } else if (attribute.type === "specialty_pattern") {
    data = {
      ...data,
      specialty_pattern_mappings_json: { ...attribute.data },
    };
  } else if (attribute.type === "earliest/latest") {
    data = {
      ...data,
      earliest_latest_mappings_json: { ...attribute.data },
    };
  } else if (attribute.type === "site_of_care") {
    data = {
      ...data,
      siteOfCare_mappings_json: { ...attribute.data },
    };
  } else if (attribute.type === "custom") {
    data = {
      ...data,
      drugClass_mappings_json: { ...attribute.data },
    };
  } else if (attribute.type === "surgery_group") {
    data = {
      ...data,
      surgery_mappings_json: { ...attribute.data },
    };
  } else if (attribute.type === "place_of_service") {
    data = {
      ...data,
      placeOfService_mappings_json: { ...attribute.data },
    };
  } else if (attribute.type === "persistence") {
    data = {
      ...data,
      persistence_mappings_json: { ...attribute.data },
    };
  } else if (attribute.type === "sob") {
    data = {
      ...data,
      sob_mappings_json: { ...attribute.data },
    };
  } else if (attribute.type === "adjuvant") {
    data = {
      ...data,
      adjuvant_mappings_json: { ...attribute.data },
    };
  } else if (attribute.type === "surgery") {
    data = {
      ...data,
      surgery_mappings_json: { ...attribute.data },
    };
  } else if (attribute.type === "therapy") {
    data = {
      ...data,
      therapy_mappings_json: { ...attribute.data },
    };
  } else {
    const { dimensions, mappings } = getAttibuteArray(attribute);
    data = { ...data, dimensions, mappings };
  }
  return data;
};

export const checkLotattributre = (state, type) => {
  let attr_id = "";
  state.analyses.forEach((analysis) => {
    if (analysis.id === state.activeAnalysis) {
      analysis.attributes.forEach((attribute) => {
        if (attribute.type === type) {
          attr_id = attribute.id;
          return;
        }
      });
    }
  });
  return attr_id;
};

export const getAttributeColor = (attribute) => {
  return attribute.action === "create"
    ? "grey"
    : ["green", "orange"].includes(attribute.colorFlag)
    ? "orange"
    : "grey";
};

export const graphParams = (highChartData) => {
  const categories = [];
  const series = [];
  highChartData.forEach((elem, index) => {
    categories.push(elem.name);
    series.push({
      name: elem.name,
      color: DarkColorPalette[index],
      data: [
        {
          y: elem.percentage_patients,
          total: elem.total_patients,
          patients: elem.patients,
        },
      ],
    });
  });
  return {
    categories,
    series,
  };
};

export const convertToYYYYMMDD = (todate) => {
  let date = new Date(todate);
  let dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0];
  return dateString;
};

export const GetEllipsis = (value, range) => {
  if (value && value.length > range) {
    return value.slice(0, range) + "...";
  } else {
    return value;
  }
};

export const FilterTheInput = (input, option) => {
  const val = option.props.value;
  if (val) {
    return val.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }
  return false
};


export const giveRegimeLine = {
  patients_by_regimen_1_l:"1",
  patients_by_regimen_2_l:"2",
  patients_by_regimen_3plus_l:"3+"
};

export const getXcoOrdinate=(key)=>{
 return giveRegimeLine[key]
};

export const giveBrandLine = {
  patients_by_brand_1_l:"1",
  patients_by_brand_2_l:"2",
  patients_by_brand_3plus_l:"3+"
};

export const getBrandXcoOrdinate=(key)=>{
 return giveBrandLine[key]
}

export const getPatientsTooltip = (totalPatients, percentagePatients) => {
  let value = "Patients : " + NumberFormatter(totalPatients) +
              "<br/>% Patients : " + percentagePatients + "%"
  return value;
};

export const getStageTooltip = (patients, percentageObservations, totalObservations, legendName, legendValue) => {
  let value = "<br/>Observations : " + NumberFormatter(patients) +
              "<br/>" +
              "% Observations : " + percentageObservations + "%" +
              "<br/>" +
              "Total Observations : " + NumberFormatter(totalObservations) +
              "<br/>" +
              legendName + " : " + legendValue
  return value;
};

export const getTreatmentTooltip = (patients, percentagePatients, totalPatients, legendName, legendValue) => {
  let value = "Patients : " + NumberFormatter(patients) +
              "<br/>" +
              "% Patients : " + percentagePatients + "%" +
              "<br/>" +
              "Total patients : " + NumberFormatter(totalPatients) +
              "<br/>" +
              legendName + " : " + legendValue
  return value;
};
const linevalue = 3;
export const formatL = (value)=>{
  if (value === linevalue) {
    return `Line ${value}+`;
  }
  return `Line ${value}`;
}

export const getUserData = (localStorageKeyName) => {
  let data = localStorage.getItem(localStorageKeyName);
  if (data) {
    data = JSON.parse(data);
  }
  return data
}

export const getLocalStorageData = (localStorageKeyName) => {
  let data = localStorage.getItem(localStorageKeyName);
  if (data) {
    data = JSON.parse(data);
  }
  return data;
};